import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Col, Button, Row } from "shards-react";
import { Stepper, Step, StepButton } from "@material-ui/core";
import { getState, dispatch } from "../store";
import { formSetValue } from "../actions/form";
import Congratulations from "../components/Congratulations";
import UserInfo from "../components/UserInfo";
import Finish from "../components/Finish";
import Address from "../components/Address";
import ZipCode from "../components/ZipCode";
import Business from "../components/Business";
import ESign from "../components/ESign";
import Bank from "../components/Bank";
import Thankyou from "../components/Thankyou";
import {
  updateValues,
  sendEmail,
  updateValuesGoogleSheet,
  createUploadPdfESign,
  uploadApplicationToSalesforce,
  uploadApplicationToLendSaas,
} from "../api";
import { IS_CAPITALIZED, IS_NORTH } from "../config";
import { loadInit } from "./handle";
import "./pages.css";
import { getGTMId } from "../utils";
import TagManager from "react-gtm-module";

const subtitles = [
  "User information",
  "Address information",
  "Zip code",
  "Business information",
  "E-sign",
  "Bank statement",
  "Finish",
];

function ThirdPage({ location, plaidState, formState, request_months }) {
  const history = useHistory();

  const [step, setStep] = useState(-1);
  const [isFinished, setFinish] = useState(false);
  const [enableNext, setEnableNext] = useState(false);
  const [enableNext1, setEnableNext1] = useState(false);
  const [enableNext2, setEnableNext2] = useState(false);
  const [values, setValues] = useState({
    ...getState().form,
    current_page: "third",
  });

  const steps = [
    { label: "Owners", hide: false, step: 0 },
    { label: "Address", hide: true, step: 0 },
    { label: "Business Info", hide: false, step: 1 },
    { label: "E-Sign", hide: false, step: 2 },
    { label: "Verification", hide: false, step: 3 },
    { label: "Finish", hide: true, step: 3 },
    { label: "Thank you", hide: true, step: 3 },
  ];

  const userinfoRef = useRef(null);
  const addressRef = useRef(null);
  const businessRef = useRef(null);
  const esignRef = useRef(null);
  const bankRef = useRef(null);

  const handleStep = (index) => {
    if (index >= 0 && index < 3) {
      let result = 0;
      for (let i = 0; i < steps.length; i++) {
        result = i;
        if (steps[i].step >= index) break;
      }
      setStep(result);
    }
  };

  const getActiveStep = () => {
    let result = -1;
    if (step >= 0 && step < steps.length) result = steps[step].step;
    return result;
  };

  const handleBack = (e) => {
    e.target.blur();
    if (step >= 0) {
      setStep(step - 1);
      setFinish(false);
    }
  };

  const handleContinue = async (e) => {
    e.target.blur();
    let validationSuccess = false;
    if (step === -1) validationSuccess = true;
    else if (step === 0) {
      validationSuccess =
        userinfoRef.current && (await userinfoRef.current.validate());
      // setEnableNext(false);
    } else if (step === 1)
      validationSuccess = addressRef.current && addressRef.current.validate();
    // else if (step === 2) validationSuccess = (zipcodeRef.current && zipcodeRef.current.validate());
    else if (step === 2)
      validationSuccess = businessRef.current && businessRef.current.validate();
    else if (step === 3)
      validationSuccess =
        esignRef.current && (await esignRef.current.validate());
    else if (step === 4)
      validationSuccess = bankRef.current && (await bankRef.current.validate());
    else if (step === 5) validationSuccess = true;

    if (validationSuccess) {
      if (step === 5) {
        // upload to server ===== Thank you page
        updateValues({ ...values, current_step: step + 1 }, (id) => {
          setStep(step + 1);
          dispatch(formSetValue({ ...values, _id: id }));
          // updateValuesGoogleSheet(id, (result) => {
          //   sendEmail(id, (result) => {
          //     if (result) {
          //       setFinish(true);
          //       dispatch(formSetValue({...values, _id: id}));
          //       // gotoBizboost(values);
          //       setStep(step + 1);
          //     }
          //   });
          // });
        });
      } else {
        const payload =
          step === 4 && validationSuccess.mode === "manual"
            ? {
                ...values,
                current_step: step + 1,
                bankfilepath: validationSuccess.data,
              }
            : { ...values, current_step: step + 1 };
        if (step === 4 && validationSuccess.mode === "plaid" && !IS_NORTH) {
          setStep(step + 1);
          return;
        }
        // upload to server
        updateValues(payload, (id) => {
          if (step === 3) {
            createUploadPdfESign(id, (link) => {
              console.log(link, "===== e-sign pdf link");
            });
          }
          setStep(step + 1);
          const newValues =
            step === 4
              ? { ...values, _id: id, bankfilepath: validationSuccess }
              : { ...values, _id: id };
          dispatch(formSetValue(newValues));
          setValues(newValues);
          if (step === 4 && validationSuccess.mode === "manual") {
            uploadApplicationToSalesforce(id, (result) => {
              if (result) {
                console.log("successfully uploaded salesforce data");
              }
            });
            uploadApplicationToLendSaas(id, (res) => {
              console.log("successfully uploaded application to lendsaas");
            });

            updateValuesGoogleSheet(id, (result) => {
              sendEmail(id, (result) => {
                if (result) {
                  setFinish(true);
                  dispatch(formSetValue({ ...values, _id: id }));
                  // gotoBizboost(values);
                  setStep(step + 1);
                }
              });
            });
          }
        });
      }
    }
  };

  const handleChangeValue = (key, val) => {
    setValues((values) => ({ ...values, [key]: val }));
  };

  const handleChangeMultipleValues = (pairs) => {
    console.log(values, step);
    let result = { ...values };
    for (let key in pairs) {
      let val = pairs[key];
      result[key] = val;
    }
    console.log("[address changed] ", pairs, result);
    setValues(result);
  };

  const handleChangeUploadedFilePath = (value) => {
    setValues((values) => ({ ...values, uploadedFilePath: value }));
  };

  const handleSelectOtherService = (key) => {
    setValues((values) => ({
      ...values,
      otherservice: values.otherservice
        ? { ...values.otherservice, [key]: !values.otherservice[key] }
        : { [key]: true },
    }));
  };

  useEffect(() => {
    loadInit({ location, history, setStep, page: "third", setValues, values });
  }, []);

  useEffect(() => {
    if (getGTMId() && step > -1) {
      console.log(
        "**** Track Third Page ******\n",
        `${document.location.origin}${document.location.pathname}${document.location.hash}${document.location.search}`,
      );
      window.dataLayer.push({
        event: "pageview",
        page: {
          url: `${document.location.origin}${document.location.pathname}${document.location.hash}${document.location.search}`,
          title: `${document.title} - Third Step - ${subtitles[step]}`,
        },
      });
    }
  }, [step]);

  useEffect(() => {
    if (Object.keys(values).length === 1) return;
    if (
      !values.admin_create &&
      !values.credit &&
      values.domain !== "cashably"
    ) {
      history.push("/first");
    }
  }, [values]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  return (
    <Col className="custom-white-panel pt-1 pb-5">
      <Col>
        {step >= 0 && step < 6 && (
          <Stepper activeStep={getActiveStep()}>
            {steps.map(({ label, hide, step: tmpStep }, index) =>
              !hide ? (
                <Step key={label}>
                  <StepButton
                    onClick={() => handleStep(tmpStep)}
                    completed={tmpStep <= getActiveStep() - 1}
                  >
                    {label}
                  </StepButton>
                </Step>
              ) : null,
            )}
          </Stepper>
        )}
      </Col>
      <Col className="mt-5">
        {step === -1 && <Congratulations values={values} isSecond={false} />}
        {step === 0 && (
          <UserInfo
            ref={userinfoRef}
            valueFirst={values.firstname}
            setValueFirst={(v) => handleChangeValue("firstname", v)}
            valueLast={values.lastname}
            setValueLast={(v) => handleChangeValue("lastname", v)}
            valueEmail={values.email}
            setValueEmail={(v) => handleChangeValue("email", v)}
            valuePhone={values.phone}
            setValuePhone={(v) => handleChangeValue("phone", v)}
            enableNext={enableNext}
            setEnableNext={setEnableNext}
          />
        )}
        {step === 1 && (
          <Address
            ref={addressRef}
            valueDOB={values.dob}
            setValueDOB={(v) => handleChangeValue("dob", v)}
            valueSSN={values.ssn}
            setValueSSN={(v) => handleChangeValue("ssn", v)}
            valueHomeAddress={values.homeaddress}
            setValueHomeAddress={(v) => handleChangeValue("homeaddress", v)}
            valueHomeCity={values.homecity}
            setValueHomeCity={(v) => handleChangeValue("homecity", v)}
            valueHomeState={values.homestate}
            setValueHomeState={(v) => handleChangeValue("homestate", v)}
            setValueHomeCityState={(c, s, a, p) =>
              handleChangeMultipleValues({
                homecity: c,
                homestate: s,
                homeaddress: a,
                homezipcode: p,
              })
            }
            valueHomeZipCode={values.homezipcode}
            setValueHomeZipCode={(v) => handleChangeValue("homezipcode", v)}
            valueOwnership={values.ownership}
            setValueOwnership={(v) => handleChangeValue("ownership", v)}
            valueJobTitle={values.jobtitle}
            setValueJobTitle={(v) => {
              handleChangeValue("jobtitle", v);
              handleChangeValue("title", v);
            }}
          />
        )}
        {step === 2 && (
          <Business
            ref={businessRef}
            valueBusinessName={values.businessname}
            setValueBusinessName={(v) => {
              handleChangeValue("businessname", v);
            }}
            valueDBA={values.dba}
            setValueDBA={(v) => handleChangeValue("dba", v)}
            valueTaxId={values.taxid}
            setValueTaxId={(v) => handleChangeValue("taxid", v)}
            valueBusinessAddress={values.businessaddress}
            setValueBusinessAddress={(v) =>
              handleChangeValue("businessaddress", v)
            }
            valueBusinessCity={values.businesscity}
            setValueBusinessCity={(v) => handleChangeValue("businesscity", v)}
            valueBusinessState={values.businessstate}
            setValueBusinessState={(v) => handleChangeValue("businessstate", v)}
            setValueBusinessCityState={(c, s, a, p) =>
              handleChangeMultipleValues({
                businesscity: c,
                businessstate: s,
                businessaddress: a,
                businesszipcode: p,
              })
            }
            valueBusinessZipCode={values.businesszipcode}
            setValueBusinessZipCode={(v) =>
              handleChangeValue("businesszipcode", v)
            }
            valueIncorporationType={values.incorporationtype}
            setValueIncorporationType={(v) =>
              handleChangeValue("incorporationtype", v)
            }
            valueIndustryType={values.type}
            setValueIndustryType={(v) => handleChangeValue("type", v)}
            valueOwnLease={values.ownlease}
            setValueOwnLease={(v) => handleChangeValue("ownlease", v)}
          />
        )}
        {step === 3 && (
          <ESign
            ref={esignRef}
            values={values}
            valueFullName={values.fullname}
            setValueFullName={(v) => handleChangeValue("fullname", v)}
            valueTitle={values.title}
            setValueTitle={(v) => handleChangeValue("title", v)}
            valueSignature={values.signature}
            setValueSignature={(v) => handleChangeValue("signature", v)}
            enableNext={enableNext1}
            setEnableNext={setEnableNext1}
            goOwners={() => setStep(0)}
            goBusiness={() => setStep(2)}
          />
        )}
        {step === 4 && (
          <Bank
            ref={bankRef}
            values={values}
            value={values.bankfile}
            setValue={(v) => handleChangeValue("bankfile", v)}
            setValueFilePath={(v) => handleChangeValue("bankfilepath", v)}
            setUploadedFilePath={(v) => handleChangeUploadedFilePath(v)}
            enableNext={enableNext2}
            setEnableNext={setEnableNext2}
            plaidState={plaidState}
            setStep={setStep}
            request_months={request_months}
          />
        )}
        {step === 5 && (
          <Finish
            isSecond={false}
            otherservice={values.otherservice ? values.otherservice : {}}
            setOtherService={handleSelectOtherService}
          />
        )}
        {step === 6 && <Thankyou values={values} />}
      </Col>
      <Row className="mt-5">
        <Col className="text-center">
          {step >= 0 && step < 5 && (
            <Button
              outline
              theme="success"
              onClick={handleBack}
              className="custom-btn-back1"
            >
              Back
            </Button>
          )}
          {!isFinished && step < 5 && (
            <Button
              theme="success"
              onClick={handleContinue}
              className="custom-btn-back2 ml-3"
              disabled={
                (step === 0 && !enableNext) ||
                (step === 3 && !enableNext1) ||
                (step === 4 && !enableNext2)
              }
            >
              {step === -1
                ? "Get My Funding Offer"
                : step === 5
                ? "Finish"
                : "Continue"}
            </Button>
          )}
        </Col>
      </Row>
    </Col>
  );
}

function mapStateToProps(state) {
  const { form, plaid } = state;
  const { request_months } = state.load;

  return {
    formState: form,
    plaidState: plaid,
    request_months,
  };
}

export default connect(mapStateToProps, undefined)(ThirdPage);
