import {
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  FETCH_APPLICATIONS_SUCCESS,
  FETCH_APPLICATIONS_FAILURE,
  UPDATE_APPLICATION,
  FETCH_LATEST_DOC_REQUEST,
  FETCH_LATEST_DOC_SUCCESS,
  FETCH_LATEST_DOC_FAILURE,
  CLEAR_LATEST_DOC_STATUS,
  FETCH_SPECIAL_APPLICATIONS_FAILURE,
  FETCH_SPECIAL_APPLICATIONS_SUCCESS,
} from "../actions/admin";

const initialState = {
  users: [],
  applications: [],
  specialApplications: [],
  refreshDocStatus: "initial",
};

// Reducers for Admin
export const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USERS_SUCCESS:
      return { ...state, users: action.users };
    case FETCH_USERS_FAILURE:
      return { ...state, users: [] };
    case FETCH_APPLICATIONS_SUCCESS:
      return { ...state, applications: action.applications };
    case FETCH_APPLICATIONS_FAILURE:
      return { ...state, applications: [] };
    case FETCH_SPECIAL_APPLICATIONS_SUCCESS:
      return { ...state, specialApplications: action.applications };
    case FETCH_SPECIAL_APPLICATIONS_FAILURE:
      return { ...state, specialApplications: [] };
    case UPDATE_APPLICATION:
      return {
        ...state,
        applications: state.applications.map((app) =>
          app.id === action.data.id
            ? {
                ...app,
                request_bank: {
                  ...app.request_bank,
                  status: 1,
                  months: action.data.request_months,
                },
              }
            : app,
        ),
      };
    case FETCH_LATEST_DOC_REQUEST:
      return { ...state, refreshDocStatus: "request" };
    case FETCH_LATEST_DOC_SUCCESS:
      return { ...state, refreshDocStatus: "success" };
    case FETCH_LATEST_DOC_FAILURE:
      return { ...state, refreshDocStatus: "failure" };
    case CLEAR_LATEST_DOC_STATUS:
      return { ...state, refreshDocStatus: "initial" };
    default:
      return { ...state };
  }
};
