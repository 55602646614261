import { getState, dispatch } from "../store";
import { formSetValue } from "../actions/form";
import { acceptRequestSuccess, loadedInitState } from "../actions/load";
import { acceptRequest, getValues, updateValues } from "../api";

/**
 * load init
 * @param {Location} location
 * @param {History} history
 * @param {Function} setStep
 * @param {String} page
 * @param {Function} setValues
 * @param {Object} value
 */
export const loadInit = ({
  location,
  history,
  setStep,
  page,
  setValues,
  values,
}) => {
  console.log("[loadInit] start...");

  const { loaded } = getState().load;
  console.log("[loadInit] loaded", loaded);
  if (!!loaded) {
    const { current_step } = getState().form;
    console.log("[loadInit] current_step", getState().form, current_step);
    if (typeof current_step !== "undefined") setStep(current_step);
    return;
  }

  if (!location) return;
  const { search } = location;
  if (!search) return;
  const _id = new URLSearchParams(location.search).get("_id");
  const request_bank = new URLSearchParams(location.search).get("request_bank");

  const partnerClientId = new URLSearchParams(location.search).get(
    "partner_client_id",
  );
  const subId = new URLSearchParams(location.search).get("subid");
  const utmMedium = new URLSearchParams(location.search).get("utm_medium");
  const utmSource = new URLSearchParams(location.search).get("utm_source");
  const utmCampaign = new URLSearchParams(location.search).get("utm_campaign");
  const utmID = new URLSearchParams(location.search).get("utm_id");

  console.log("[loadInit] _id", _id);
  console.log(
    "[loadInit] nav info",
    partnerClientId,
    subId,
    utmMedium,
    utmSource,
  );

  const utmInfo = {
    utmMedium, utmSource, utmCampaign, utmID
  }

  const isUtm = utmMedium || utmSource || utmCampaign || utmID

  if (partnerClientId) {
    updateValues(
      { ...values, navInfo: { partnerClientId, subId, utmMedium, utmSource } },
      (id) => {
        dispatch(
          formSetValue({
            ...values,
            navInfo: { partnerClientId, subId, utmMedium, utmSource },
            _id: id,
          }),
        );
        setValues((values) => ({
          ...values,
          navInfo: { partnerClientId, subId, utmMedium, utmSource },
          _id: id,
        }));
      },
    );
    return partnerClientId;
  }

  if(isUtm) {
    updateValues(
      { ...values, utmInfo },
      (id) => {
        dispatch(
          formSetValue({
            ...values,
            utmInfo,
            _id: id,
          }),
        );
        setValues((values) => ({
          ...values,
          utmInfo,
          _id: id,
        }));
      },
    );
    return null;
  }

  if (!_id) {
    return;
  }

  if (request_bank) {
    acceptRequest(_id, (data) => {
      if (data) {
        dispatch(acceptRequestSuccess(data));
      }
    });
  }

  getValues(_id, (values) => {
    try {
      console.log("[loadInit] values", values);
      const { current_page, current_step } = values;
      dispatch(formSetValue({ ...values, _id }));
      dispatch(loadedInitState({ loaded: true }));
      if (current_page === page) {
        setValues({ ...values, _id });
        setStep(current_step);
      } else {
        if (values.admin_create) {
          history.push(`/${current_page}?_id=${_id}`);
        } else if (!!values.type && current_page === "third") {
          history.push(`/${current_page}?_id=${_id}`);
        } else {
          setValues({ ...values, current_page: "first", current_step: 0, _id });
          setStep(0);
        }
      }
    } catch (e) {
      console.log("[loadInit] err", e);
    }
  });
};
