import React, { useState, forwardRef, useImperativeHandle } from "react";
import { FormGroup, Col, Row, FormInput } from "shards-react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { IndustryTypes } from "../data/industry-types";
import { validateEmail } from "../utils";
import { asyncValidateEmailAddress } from "../api";

import "./components.css";

const IndustryType = forwardRef(
  (
    {
      value,
      setValue,
      btnRow,
      valueFirst,
      setValueFirst,
      valueLast,
      setValueLast,
      valueEmail,
      setValueEmail,
    },
    ref,
  ) => {
    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [error, handleError] = useState(false);

    const [successFirst, setSuccessFirst] = useState(false);
    const [errorFirst, handleErrorFirst] = useState(false);
    const [successLast, setSuccessLast] = useState(false);
    const [errorLast, handleErrorLast] = useState(false);
    const [successEmail, setSuccessEmail] = useState(false);
    const [errorEmail, handleErrorEmail] = useState(false);

    const options = IndustryTypes;

    const handleChange = (val) => {
      setInputValue(val);
    };

    const handleChangeFirst = (e) => {
      const val = e.target.value;
      if (typeof setValueFirst === "function") setValueFirst(val);
    };

    const handleChangeLast = (e) => {
      const val = e.target.value;
      if (typeof setValueLast === "function") setValueLast(val);
    };

    const handleChangeEmail = (e) => {
      const val = e.target.value;
      if (typeof setValueEmail === "function") setValueEmail(val);
    };

    const handleSearch = (val) => {
      setValue(val);
      if (typeof setValue === "function") setValue(val);
    };

    const validate = async () => {
      if (!!valueFirst && valueFirst !== "") {
        setSuccessFirst(true);
        handleErrorFirst(false);
      } else {
        setSuccessFirst(false);
        handleErrorFirst(true);
        return false;
      }
      if (!!valueLast && valueLast !== "") {
        setSuccessLast(true);
        handleErrorLast(false);
      } else {
        setSuccessLast(false);
        handleErrorLast(true);
        return false;
      }
      if (validateEmail(valueEmail)) {
        const result = await asyncValidateEmailAddress(valueEmail);
        if (result) {
          setSuccessEmail(true);
          handleErrorEmail(false);
        } else {
          setSuccessEmail(false);
          handleErrorEmail(true);
          return false;
        }
      } else {
        setSuccessEmail(false);
        handleErrorEmail(true);
        return false;
      }
      if (!!value && options.find((o) => o === value)) {
        handleError(false);
        return true;
      } else {
        handleError(true);
        return false;
      }
    };

    useImperativeHandle(ref, () => ({
      validate,
    }));

    return (
      <>
        <Row className="mt-4">
          <Col sm={6}>
            <FormGroup className="form-group-center">
              <Col>
                <label
                  htmlFor="#first-name"
                  className="custom-lbl-size display-5 text-primary text-left text-shadow--large-1"
                >
                  First Name
                </label>
              </Col>
              <Col>
                <FormInput
                  id="#first-name"
                  placeholder="e.g. John"
                  value={valueFirst || ""}
                  onChange={handleChangeFirst}
                  valid={successFirst}
                  invalid={errorFirst}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup className="form-group-center">
              <Col>
                <label
                  htmlFor="#last-name"
                  className="custom-lbl-size display-5 text-primary text-left text-shadow--large-1"
                >
                  Last Name
                </label>
              </Col>
              <Col>
                <FormInput
                  id="#last-name"
                  placeholder="e.g. Smith"
                  value={valueLast || ""}
                  onChange={handleChangeLast}
                  valid={successLast}
                  invalid={errorLast}
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <FormGroup className="form-group-center">
              <Col>
                <label
                  htmlFor="#email-address"
                  className="custom-lbl-size display-5 text-primary text-left text-shadow--large-1"
                >
                  Email Address
                </label>
              </Col>
              <Col>
                <FormInput
                  id="#email-address"
                  placeholder="e.g. user@email.com"
                  value={valueEmail || ""}
                  onChange={handleChangeEmail}
                  valid={successEmail}
                  invalid={errorEmail}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup className="form-group-center">
              <Col>
                <label
                  htmlFor="#avarage-monthly"
                  className="custom-lbl-size display-5 text-primary text-left text-shadow--large-1"
                >
                  What is your industry type?
                </label>
              </Col>
              <Col>
                <Autocomplete
                  open={open}
                  onOpen={() => setOpen(true)}
                  onClose={() => setOpen(false)}
                  value={value || ""}
                  onChange={(_, newValue) => handleSearch(newValue)}
                  inputValue={inputValue}
                  onInputChange={(_, newInputValue) =>
                    handleChange(newInputValue)
                  }
                  options={options}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      error={error}
                    />
                  )}
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        {btnRow}
      </>
    );
  },
);

export default IndustryType;
