import React, { useState, forwardRef, useImperativeHandle } from "react";
import { FormInput, FormGroup, Col, Row } from "shards-react";
import { validateEmail, validatePhone } from "../utils";
import { InputPhone } from "./CustomInputs";
import { asyncValidateEmailAddress, asyncValidatePhoneNumber } from "../api";
import "./components.css";

const EmailPhone = forwardRef(
  ({ valueEmail, setValueEmail, valuePhone, setValuePhone }, ref) => {
    const [successEmail, setSuccessEmail] = useState(false);
    const [errorEmail, handleErrorEmail] = useState(false);
    const [successPhone, setSuccessPhone] = useState(false);
    const [errorPhone, handleErrorPhone] = useState(false);

    const handleChangeEmail = (e) => {
      const val = e.target.value;
      if (typeof setValueEmail === "function") setValueEmail(val);
    };

    const handleChangePhone = (e) => {
      const val = e.target.value;
      if (typeof setValuePhone === "function") setValuePhone(val);
    };

    const validate = async () => {
      if (validateEmail(valueEmail)) {
        const result = await asyncValidateEmailAddress(valueEmail);
        if (result) {
          setSuccessEmail(true);
          handleErrorEmail(false);
        } else {
          setSuccessEmail(false);
          handleErrorEmail(true);
          return false;
        }
      } else {
        setSuccessEmail(false);
        handleErrorEmail(true);
        return false;
      }
      if (validatePhone(valuePhone)) {
        const result = await asyncValidatePhoneNumber(valuePhone);
        if (result) {
          setSuccessPhone(true);
          handleErrorPhone(false);
        } else {
          setSuccessPhone(false);
          handleErrorPhone(true);
          return false;
        }
      } else {
        setSuccessPhone(false);
        handleErrorPhone(true);
        return false;
      }
      return true;
    };

    useImperativeHandle(ref, () => ({
      validate,
    }));

    return (
      <>
        <Row>
          <Col sm={6}>
            <FormGroup className="form-group-center">
              <Col>
                <label
                  htmlFor="#email-address"
                  className="custom-lbl-size display-5 text-primary text-left text-shadow--large-1"
                >
                  Email Address
                </label>
              </Col>
              <Col>
                <FormInput
                  id="#email-address"
                  placeholder="john@doe.com"
                  value={valueEmail || ""}
                  onChange={handleChangeEmail}
                  valid={successEmail}
                  invalid={errorEmail}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup className="form-group-center">
              <Col>
                <label
                  htmlFor="#phone-number"
                  className="custom-lbl-size display-5 text-primary text-left text-shadow--large-1"
                >
                  Phone Number
                </label>
              </Col>
              <Col>
                <InputPhone
                  id="#phone-number"
                  value={valuePhone || ""}
                  onChange={handleChangePhone}
                  placeholder="(999) 999-9999"
                  valid={successPhone}
                  invalid={errorPhone}
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          {/* <Col>
                    Terms of Service
                </Col> */}
        </Row>
      </>
    );
  },
);

export default EmailPhone;
