import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import {
  Col,
  Row,
  FormGroup,
  FormInput,
  FormCheckbox,
  Alert,
  Button,
} from "shards-react";
import { CheckCircleOutline, Edit } from "@material-ui/icons";
import moment from "moment";
import { getCurrentTime } from "../utils";
import Popup from "reactjs-popup";
import SignaturePad from "react-signature-canvas";
import "../pages/pages.css";
import "../containers/layout.css";

const html2canvas = require("html2canvas");

const ESign = forwardRef(
  (
    {
      values,
      valueFullName,
      setValueFullName,
      valueTitle,
      setValueTitle,
      valueSignature,
      setValueSignature,
      enableNext,
      setEnableNext,
      goOwners,
      goBusiness,
    },
    ref,
  ) => {
    const [successFullName, setSuccessFullName] = useState(false);
    const [errorFullName, handleErrorFullName] = useState(false);
    const [successTitle, setSuccessTitle] = useState(false);
    const [errorTitle, handleErrorTitle] = useState(false);
    const [successSign, setSuccessSign] = useState(false);
    const [errorSign, handleErrorSign] = useState(false);

    const sigCanvas = useRef();

    const handleClear = () => sigCanvas.current.clear();

    const handleSave = () =>
      setValueSignature(
        sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"),
      );

    const handleSaveSignature = () => {
      html2canvas(document.getElementById("signature"), {
        logging: false,
      }).then((canvas) => {
        try {
          const dataURL = canvas.toDataURL("image/png");
          setValueSignature(dataURL);
        } catch (error) {
          console.error(error);
        }
      });
    };

    const handleChangeFullName = (e) => {
      const val = e.target.value;
      if (typeof setValueFullName === "function") setValueFullName(val);
    };

    const handleChangeTitle = (e) => {
      const val = e.target.value;
      if (typeof setValueTitle === "function") setValueTitle(val);
    };

    const validate = () => {
      if (!!valueFullName && valueFullName !== "") {
        setSuccessFullName(true);
        handleErrorFullName(false);
      } else {
        setSuccessFullName(false);
        handleErrorFullName(true);
        return false;
      }
      if (!!valueTitle && valueTitle !== "") {
        setSuccessTitle(true);
        handleErrorTitle(false);
      } else {
        setSuccessTitle(false);
        handleErrorTitle(true);
        return false;
      }
      if (!!valueSignature && valueSignature !== "") {
        setSuccessSign(true);
        handleErrorSign(false);
      } else {
        setSuccessSign(false);
        handleErrorSign(true);
        return false;
      }
      return true;
    };

    useImperativeHandle(ref, () => ({
      validate,
    }));

    return (
      <>
        <h2 className="text-primary text-shadow--large-1 mb-5">E-Sign</h2>
        <Col className="border esign">
          <Row>
            <h4 className="pl-2 pt-3">Business</h4>
            <Button
              outline
              pill
              size="sm"
              className="custom-btn-edit"
              onClick={goBusiness}
            >
              <Edit />
            </Button>
          </Row>
          <Row className="text-left">
            <Col sm={6} className="shards-feature mb-5 mb-lg-3">
              <small className="mb-2">Business Legal Name</small>
              <h6 className="d-inline-block mb-2">{values.businessname}</h6>
            </Col>
            <Col sm={3} className="shards-feature mb-5 mb-lg-3">
              <small className="mb-2">Amount Requested</small>
              <h6 className="d-inline-block mb-2">{values.howmuch}</h6>
            </Col>
            <Col sm={3} className="shards-feature mb-5 mb-lg-3">
              <small className="mb-2">Industry Type</small>
              <h6 className="d-inline-block mb-2">{values.type}</h6>
            </Col>
          </Row>
          <Row className="text-left">
            <Col sm={6} className="shards-feature mb-5 mb-lg-3">
              <small className="mb-2">Business D/B/A Name</small>
              <h6 className="d-inline-block mb-2">{values.dba}</h6>
            </Col>
            <Col sm={6} className="shards-feature mb-5 mb-lg-3">
              <small className="mb-2">Contact Number</small>
              <h6 className="d-inline-block mb-2">{values.phone}</h6>
            </Col>
            {/*<Col sm={3} className="shards-feature mb-5 mb-lg-3">*/}
            {/*  <small className="mb-2">Own/Lease Property</small>*/}
            {/*  <h6 className="d-inline-block mb-2">{values.ownlease}</h6>*/}
            {/*</Col>*/}
          </Row>
          <Row className="text-left">
            <Col sm={6} className="shards-feature mb-5 mb-lg-3">
              <small className="mb-2">Business Address</small>
              <h6 className="d-inline-block mb-2">
                {values.businessaddress}, {values.businesscity},{" "}
                {values.businessstate}, {values.businesszipcode}
              </h6>
            </Col>
            <Col sm={3} className="shards-feature mb-5 mb-lg-3">
              <small className="mb-2">Tax ID</small>
              <h6 className="d-inline-block mb-2">{values.taxid}</h6>
            </Col>
            <Col sm={3} className="shards-feature mb-5 mb-lg-3">
              <small className="mb-2">Business Start</small>
              <h6 className="d-inline-block mb-2">
                {values.startmonth}/01/{values.startyear}
              </h6>
            </Col>
          </Row>
          <Row className="text-left">
            {/*<Col sm={6} className="shards-feature mb-5 mb-lg-3">*/}
            {/*  <small className="mb-2">Type of Business</small>*/}
            {/*  <h6 className="d-inline-block mb-2">{values.type}</h6>*/}
            {/*</Col>*/}
            <Col sm={6} className="shards-feature mb-5 mb-lg-3">
              <small className="mb-2">Average Monthly Revenue</small>
              <h6 className="d-inline-block mb-2">{values.avarage}</h6>
            </Col>
            <Col sm={6} className="shards-feature mb-5 mb-lg-3">
              <small className="mb-2">Incorporation Type</small>
              <h6 className="d-inline-block mb-2">
                {values.incorporationtype}
              </h6>
            </Col>
          </Row>
        </Col>
        <Col className="border mt-4 esign">
          <Row>
            <h4 className="pl-2 pt-3">Owner Information</h4>
            <Button
              outline
              pill
              size="sm"
              className="custom-btn-edit"
              onClick={goOwners}
            >
              <Edit />
            </Button>
          </Row>
          <Row className="text-left">
            <Col sm={6} className="shards-feature mb-5 mb-lg-3">
              <small className="mb-2">Owner Name</small>
              {/*<h6 className="d-inline-block mb-2">{values.firstname}</h6>*/}
              <h6 className="d-inline-block mb-2">
                {values.firstname} {values.lastname}
              </h6>
            </Col>
            <Col sm={3} className="shards-feature mb-5 mb-lg-3">
              <small className="mb-2">Business Title</small>
              <h6 className="d-inline-block mb-2">{values.jobtitle}</h6>
            </Col>
            <Col sm={3} className="shards-feature mb-5 mb-lg-3">
              <small className="mb-2">Ownership Percent</small>
              <h6 className="d-inline-block mb-2">{values.ownership} %</h6>
            </Col>
          </Row>
          <Row className="text-left">
            <Col sm={6} className="shards-feature mb-5 mb-lg-3">
              <small className="mb-2">Home Address</small>
              <h6 className="d-inline-block mb-2">
                {values.homeaddress}, {values.homecity}, {values.homestate},{" "}
                {values.homezipcode}
              </h6>
            </Col>
            <Col sm={3} className="shards-feature mb-5 mb-lg-3">
              <small className="mb-2">Mobile Phone</small>
              <h6 className="d-inline-block mb-2">{values.phone}</h6>
            </Col>
            <Col sm={3} className="shards-feature mb-5 mb-lg-3">
              <small className="mb-2">Social Security Number</small>
              <h6 className="d-inline-block mb-2">{values.ssn}</h6>
            </Col>
          </Row>
          <Row className="text-left">
            <Col sm={6} className="shards-feature mb-5 mb-lg-3">
              <small className="mb-2">Date Of Birthday</small>
              <h6 className="d-inline-block mb-2">
                {moment(values.dob).format("MM-DD-YYYY")}
              </h6>
            </Col>
          </Row>
        </Col>
        <Col className="border mt-4">
          <Row>
            <h4 className="pl-2 pt-3">Applicant E-Signature</h4>
          </Row>
          <Row>
            <Col md={7}>
              <FormGroup className="form-group-center">
                <Col>
                  <label
                    htmlFor="#full-name"
                    className="custom-lbl-size display-5 text-primary text-left text-shadow--large-1"
                  >
                    Full Name
                  </label>
                </Col>
                <Col>
                  <FormInput
                    id="full-name"
                    placeholder=""
                    value={valueFullName || ""}
                    onChange={(e) => {
                      handleChangeFullName(e);
                      handleSaveSignature();
                    }}
                    valid={successFullName}
                    invalid={errorFullName}
                  />
                </Col>
              </FormGroup>
            </Col>
            <Col md={5}>
              <FormGroup className="form-group-center">
                <Col>
                  <label
                    htmlFor="#title"
                    className="custom-lbl-size display-5 text-primary text-left text-shadow--large-1"
                  >
                    Title
                  </label>
                </Col>
                <Col>
                  <FormInput
                    id="title"
                    placeholder=""
                    value={valueTitle || ""}
                    onChange={handleChangeTitle}
                    valid={successTitle}
                    invalid={errorTitle}
                    disabled={valueFullName && valueFullName.length > 0}
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup className="form-group-center">
                <Col className="d-flex pl-0" />
                <Col
                  className="my-3"
                  id="signature"
                  style={{
                    width: 500,
                    height: 100,
                    fontSize: 50,
                    fontFamily: "Font2",
                  }}
                >
                  {valueFullName}
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Alert theme="success rounded text-left font-weight-700">
                <CheckCircleOutline /> {getCurrentTime()}
              </Alert>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col sm={12}>
              <FormCheckbox
                className="text-left font-size-sm-8"
                checked={enableNext || false}
                onChange={(e) => {
                  setEnableNext(!enableNext);
                  handleSaveSignature();
                }}
              >
                By checking this box, I agree that the electronic digitized
                signatures I apply on the following document are representations
                of my signature and legally valid and binding as if I had signed
                the document with ink on paper in accordance with the Uniform
                Electronic Transactions Act (UETA) and the Electronic Signatures
                in Global and National Commerce Act (E-SIGN) of 2000. Each of
                the above listed business and business owner/officer
                (individually and collectively, "you") authorize Capitalized and
                each of its representatives, successors, assigns and designees
                ("Recipients") that may be involved with or acquire commercial
                loans having daily repayment features or purchases of future
                receivables including Merchant Cash Advance transactions,
                including without limitation the application therefore
                (collectively, "Transactions") to obtain consumer or personal,
                business and investigative reports and other information about
                you, including credit card processor statements and bank
                statements, from one or more consumer reporting agencies, such
                as TransUnion, Experian and Equifax, and from other credit
                bureaus, banks, creditors and other third parties. You also
                authorize Capitalized to transmit this application form, along
                with any of the foregoing information obtained in connection
                with this application, to any or all of the Recipients for the
                foregoing purposes. You also consent to the release, by any
                creditor or financial institution, of any information relating
                to any of you, to Capitalized and to each of the Recipients, on
                its own behalf. You agree that by signing you consent and
                request that Capitalized its affiliates, and those acting on
                its/their behalf, may call you or text you using an automated
                telephone dialing system and/or a prerecorded message. The types
                of calls you may receive include calls to encourage the purchase
                of goods or services, as well as calls to provide information or
                to service your account. Calls can be made to any residential or
                wireless numbers you provide to the Company, including those
                provided here, even if listed on a national or state Do Not Call
                registry. By providing your email address, you are permitting us
                to email you information about our financing and funding plans
                as well as other related products and services. We will keep
                your information secure and you can unsubscribe at any time.
              </FormCheckbox>
            </Col>
          </Row>
        </Col>
      </>
    );
  },
);

export default ESign;
