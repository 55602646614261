import React, { useState, forwardRef, useImperativeHandle } from "react";
import { FormInput, FormGroup, Col } from "shards-react";
import "./components.css";

const BusinessName = forwardRef(({ value, setValue }, ref) => {
  const [success, setSuccess] = useState(false);
  const [error, handleError] = useState(false);

  const handleChange = (e) => {
    const val = e.target.value;
    if (typeof setValue === "function") setValue(val);
  };

  const validate = () => {
    if (!!value && value !== "") {
      setSuccess(true);
      handleError(false);
      return true;
    } else {
      setSuccess(false);
      handleError(true);
      return false;
    }
  };

  useImperativeHandle(ref, () => ({
    validate,
  }));

  return (
    <FormGroup className="form-group-center">
      <Col>
        <label
          htmlFor="#business-name"
          className="custom-lbl-size display-5 text-primary text-left text-shadow--large-1"
        >
          What is the legal name of your business?
        </label>
      </Col>
      <Col className="col-lg-6 offset-lg-3">
        <FormInput
          id="#business-name"
          placeholder=""
          value={value || ""}
          onChange={handleChange}
          valid={success}
          invalid={error}
        />
      </Col>
    </FormGroup>
  );
});

export default BusinessName;
