import React, { useState, forwardRef, useImperativeHandle } from "react";
import { FormInput, FormGroup, Col } from "shards-react";
import { Select, FormControl, MenuItem } from "@material-ui/core";
import { getMoney } from "../utils";
import {
  dark_logo,
  category,
  IS_CAPITALIZED,
  link_logo,
} from "../config";
import "./components.css";
import useStyles from "./styles";

const HowMuch = forwardRef(({ value, setValue, btnRow, partnerId }, ref) => {
  const fundTypeOptions = [
    "TERM LOAN",
    "LINE OF CREDIT",
    "MERCHANT CASH ADVANCE",
    "SBA 504",
    "SBA 7A",
    "COMMERCIAL MORTGAGE",
  ];
  
  const classes = useStyles();
  
  const [success, setSuccess] = useState(false);
  const [error, handleError] = useState(false);

  const handleChange = (e, key) => {
    let val = e.target.value;
    if(key === 'howmuch') {
      val = getMoney(val);
    }
    if (typeof setValue === "function") setValue(key, val);
  };

  const validate = () => {
    // console.log("[HowMuch] validate", value);
    const keyList = ['howmuch', 'fundtype'];
    let validKeys = []
    keyList.forEach(key => {
      const isValid = value?.[key] && value?.[key]?.length; 
      setSuccess(val => ({
        ...(val || {}),
        [key]: isValid,
      }));
      handleError(val => ({
        ...(val || {}),
        [key]: !isValid,
      }))
      if(isValid) {
        validKeys.push(key)
      }
    })
    return validKeys.length === keyList.length
  };

  useImperativeHandle(ref, () => ({
    validate,
  }));

  return (
    <>
      <Col className="mt-5">
        <div className="d-flex justify-content-center">
          <img
            className="navbar-brand__image logo-image-wrapper"
            src={dark_logo}
            alt="main-logo"
            style={{
              height: "100%",
              marginRight: IS_CAPITALIZED && !partnerId ? 40 : 0,
              marginTop: -10,
            }}
          />
        </div>
        {!!partnerId && <h3>Welcome Nav customers!</h3>}

        <h1 className="text-success">Welcome to Horizon Funding Group</h1>
        {/* <h5>Immediate approval & same day funding from $2,500 - $1,000,000.</h5> */}
        {/* <div className="divider mt-5">
                     <div className="divider-text">Get a decision in minutes</div>
                 </div> */}
        <h5 className="text-center mt-4 pt-4">
          The next few pages will help us understand your business and what kind of funding you are looking for. Please complete this process to the end, it will allow us to customize a financing plan for your business.
        </h5>
        {/* <p className="text-center">This will not affect your credit.</p> */}
      </Col>
      <FormGroup className="form-group-center mt-5">
        <Col>
          <label
            htmlFor="#fund-type"
            className="custom-lbl-size display-5 text-primary text-left text-shadow--large-1"
          >
            Type of Funding
          </label>
        </Col>

        <Col className="col-lg-6 offset-lg-3">
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              className={classes.customFullWidth}
              value={value?.['fundtype'] || ""}
              onChange={e => handleChange(e, 'fundtype')}
              error={error?.['fundtype']}
            >
              {fundTypeOptions.map((m, i) => (
                <MenuItem key={i} value={m}>
                  {m}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Col>
      </FormGroup>
      <FormGroup className="form-group-center mt-4">
        <Col>
          <label
            htmlFor="#how-much"
            className="custom-lbl-size display-5 text-primary text-left text-shadow--large-1"
          >
            How much money are you looking for?
          </label>
        </Col>
        <Col className="col-lg-6 offset-lg-3">
          <FormInput
            id="#how-much"
            placeholder="$ 25,000"
            value={value?.['howmuch'] || ""}
            onChange={e => handleChange(e, 'howmuch')}
            valid={success?.['howmuch']}
            invalid={error?.['howmuch']}
          />
        </Col>
      </FormGroup>
      <div className="mt-5 start-cta">
        {btnRow}
      </div>
      <Col className="mt-4">
        <hr />
      </Col>
      <p className="text-center mt-2 p-3" >
        <a href={`${link_logo}terms-conditions`} className="mr-3">Terms of Service</a>
        <a href={`${link_logo}privacy-policy`} className="ml-3">Privacy Policy</a>
      </p>
    </>
  );
});

export default HowMuch;
