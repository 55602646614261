export const IndustryTypes = [
  "Agriculture and Landscaping",
  "Apparel and Accessories",
  "Automotive/Bicycle",
  "Beauty Salon / Spa",
  "Building Materials",
  "Business Services",
  "Construction",
  "Electronics",
  "Garden Store / Retail Nursery",
  "General Merchandise",
  "Grocery and Baked Goods",
  "Health Services",
  "Home Furnishing",
  "Hotel, Motel, and Lodging",
  "Laundry and Garment Services",
  "Manufacturing",
  "Recreation",
  "Restaurant/Bar",
  "Retail Store",
  "Travel and Transportation",
  "Veterinary Clinic",
  "Wholesale",
  "Other",
  // "Accommodation, Hotels, Motels, Inns",
  // "Accounting, Tax Preparation, Bookkeeping and Payroll Services",
  // "Activities Related to Credit Intermediation",
  // "Advertising and Related Services",
  // "Agricultural Production, Farmers",
  // "Air Transportation",
  // "Amusement, Gambling and Recreation Industries",
  // "Architectural, Engineering and Related Services",
  // "Automotive Equipment Rental and Leasing",
  // "Automobile Finance Leasing Companies",
  // "Automotive Repair and Maintenance",
  // "Beauty Salons/Tanning/Nail Salon",
  // "Book Publishers",
  // "Broadcasting (except Internet)",
  // "Catering",
  // "Coal Mining",
  // "Combination Gas and Electric",
  // "Commercial and Industrial Machinery and Equipment Rentals",
  // "Commercial Banking",
  // "Commodity Contracts Dealing and Brokerage",
  // "Computer Systems Design and Related Services",
  // "Computer/IT/Software",
  // "Construction",
  // "Credit Card Issuing and Other Consumer Credit",
  // "Credit Intermediation",
  // "Data Processing, Hosting and Related Services",
  // "Database, Directory and Other Publishers",
  // "Day Care Services",
  // "Dry Cleaning",
  // "Educational Services",
  // "Electric Power Generation, Transmission and Distribution",
  // "Electrical Contractors, Electricians",
  // "Employment Services",
  // "Food Services and Drinking Places, Restaurants",
  // "Forestry and Logging",
  // "General Business Services",
  // "Grocery/Convenience Stores",
  // "Health & Fitness Facilities",
  // "Heavy and Civil Engineering Construction",
  // "Hospital, Nursing and Residential Care Facilities",
  // "Insurance Agencies and Brokerages",
  // "International Trade, Secondary Financing, Other Nondepository Credit",
  // "Investment Banking and Securities Dealing",
  // "Janitorial/Cleaning Services",
  // "Land Subdivision",
  // "Landscaping/Lawn Maintenance Services",
  // "Legal Services",
  // "Lessors of Buildings",
  // "Lessors of Nonfinancial Intangible Assets (ex. Copyrighted works)",
  // "Life Insurance",
  // "Life Insurance, Mutual Companies (Form L)",
  // "Life Insurance, Stock Companies (Form L)",
  // "Liquor & Tobacco Stores",
  // "Management, Scientific and Technical Consulting Services",
  // "Manufacturing  Aerospace Product and Parts",
  // "Manufacturing  Agriculture, Construction and Mining Machinery",
  // "Manufacturing  Animal Food and Grain and Oilseed Milling",
  // "Manufacturing  Apparel Accessories and Other Apparel",
  // "Manufacturing  Apparel Knitting Mills",
  // "Manufacturing  Architectural and Structural Metals",
  // "Manufacturing  Asphalt Paving, Roofing, Other Petroleum and Coal Products",
  // "Manufacturing  Audio and Video Equipment, Reproducing Magnetic & Optical Media",
  // "Manufacturing  Bakeries and Tortilla",
  // "Manufacturing  Basic Chemical",
  // "Manufacturing  Boiler, Tank and Shipping Container",
  // "Manufacturing  Breweries",
  // "Manufacturing  Cement, Concrete, Lime and Gypsum Product",
  // "Manufacturing  Chemical Product and Preparation",
  // "Manufacturing  Clay, Refractory and Other Nonmetallic Mineral Products",
  // "Manufacturing  Coating, Engraving, Heat Treating and Allied Activities",
  // "Manufacturing  Commercial and Service Industry Machinery",
  // "Manufacturing  Communications Equipment",
  // "Manufacturing  Computer and Peripheral Equipment",
  // "Manufacturing  Converted Paper Products",
  // "Manufacturing  Cut and Sew Apparel Contractors and Manufacturers",
  // "Manufacturing  Cutlery, Hardware, Spring and Wire Machine Shops, Nuts, Bolts",
  // "Manufacturing  Dairy Product",
  // "Manufacturing  Electrical Equipment",
  // "Manufacturing  Electrical Lighting Equipment and Household Appliance",
  // "Manufacturing  Engine, Turbine and Power Transmission Equipment",
  // "Manufacturing  Forging sand Stamping",
  // "Manufacturing  Foundries",
  // "Manufacturing  Fruit and Vegetable Preserving and Specialty Food",
  // "Manufacturing  Furniture and Related Product Manufacturing",
  // "Manufacturing  Glass and Glass Product",
  // "Manufacturing  Industrial Machinery",
  // "Manufacturing  Iron, Steel Mills and Steel Product",
  // "Manufacturing  Leather and Allied Product Manufacturing",
  // "Manufacturing  Meat and Seafood Processing",
  // "Manufacturing  Medical Equipment and Supplies",
  // "Manufacturing  Metalworking Machinery",
  // "Manufacturing  Motor Vehicle and Parts",
  // "Manufacturing  Navigational, Measuring, Electromedical and Control",
  // "Manufacturing  Nonferrous Metal Production and Processing",
  // "Manufacturing  Other Electrical Equipment and Component",
  // "Manufacturing  Other Fabricated Metal Product",
  // "Manufacturing  Other Food",
  // "Manufacturing  Other General Purpose Machinery",
  // "Manufacturing  Other Miscellaneous Manufacturing",
  // "Manufacturing  Other Transportation Equipment and Railroad Rolling Stock",
  // "Manufacturing  Paint, Coating and Adhesive",
  // "Manufacturing  Petroleum Refineries (Including Integrated)",
  // "Manufacturing  Pharmaceutical and Medicine",
  // "Manufacturing  Plastics Product",
  // "Manufacturing  Printing and Related Support Activities",
  // "Manufacturing  Pulp, Paper and Paperboard Mills",
  // "Manufacturing  Resin, Synthetic Rubber and Fibers and Filaments",
  // "Manufacturing  Rubber Product",
  // "Manufacturing  Semiconductor and Other Electronic Component",
  // "Manufacturing  Ship and Boat Building",
  // "Manufacturing  Soap, Cleaning Compound and Toilet Preparation",
  // "Manufacturing  Soft Drink and Ice",
  // "Manufacturing  Sugar and Confectionery Products",
  // "Manufacturing  Textile Mills",
  // "Manufacturing  Textile Product Mills",
  // "Manufacturing  Tobacco Manufacturing",
  // "Manufacturing  Ventilation, Heating, AC and Commercial Refrigeration Equipment",
  // "Manufacturing  Wineries and Distilleries",
  // "Manufacturing  Wood Product Manufacturing",
  // "Metal Ore Mining",
  // "Miscellaneous Health Care and Social Assistance",
  // "Motion Picture and Video Industries (except Video Rental)",
  // "Mutual Property and Casualty Companies (Form PC)",
  // "Natural Gas Distribution",
  // "Newspaper Publishers",
  // "Night Clubs",
  // "Nonmetallic Mineral Mining and Quarrying",
  // "Offices of Bank Holding Companies",
  // "Offices of Dentists",
  // "Offices of Other Health Practitioners",
  // "Offices of Other Holding Companies",
  // "Offices of Physicians",
  // "Offices of Real Estate Agents and Brokers",
  // "Oil and Gas Extraction",
  // "OpenEnd Investment Funds (Form RIC)",
  // "Other Administrative and Support Services",
  // "Other Arts, Entertainment and Recreation",
  // "Other Consumer Goods and General Rental Centers",
  // "Other Financial Vehicles",
  // "Other Information Services, Internet Publishing, Web Portals",
  // "Other Insurance Related Activities",
  // "Other Professional, Scientific and Technical Services",
  // "Other Real Estate Activities",
  // "Other Repair and Maintenance",
  // "Other Transportation and Support Activities",
  // "Outpatient Care Centers",
  // "Periodical Publishers",
  // "Personal and Laundry Services",
  // "Personal Training",
  // "Pharmacies",
  // "Pipeline Transportation",
  // "Plumbing, Heating and Air Conditioning Contractors",
  // "Rail Transportation",
  // "Real Estate Including Mortgage Bankers and Originators",
  // "Religious, Grantmaking, Civic and Professional Organizations",
  // "Retail  Beer, Wine and Liquor Stores",
  // "Retail  Clothing and Clothing Accessories Stores",
  // "Retail  Electronics and Appliance Stores",
  // "Retail  Food and Beverage Stores",
  // "Retail  Furniture and Home Furnishing Stores",
  // "Retail  Gasoline Stations",
  // "Retail  Gasoline Stations with Convenience Store",
  // "Retail  General Merchandise Stores",
  // "Retail  Hardware Stores",
  // "Retail  Health and Personal Care Stores",
  // "Retail  Home Centers, Paint and Wallpaper Stores",
  // "Retail  Lawn and Garden Equipment and Supplies Stores",
  // "Retail  Miscellaneous Store Retailers",
  // "Retail  New and Used Car Dealers",
  // "Retail  Nonstore Retailers",
  // "Retail  Other Building Materials Dealers",
  // "Retail  Other Motor Vehicle and Parts Dealers",
  // "Retail  Sporting Goods, Hobby, Books and Music Stores",
  // "Savings Institutions and Other Depository Credit",
  // "Scientific Research and Development Services",
  // "Securities Brokerage",
  // "Security and Commodity Exchanges, Other Financial Investment",
  // "Software Publishers",
  // "Sound Recording Industries",
  // "Specialty Trade Contractors",
  // "Specialized Design Services",
  // "Sporting Goods",
  // "Stock Property and Casualty Companies (Form PC)",
  // "Support Activities and Fishing, Hunting and Trapping",
  // "Support Activity for Mining",
  // "Telecommunications (wired, wireless, satellite, internet providers)",
  // "Telemarketing",
  // "Transit and Ground Passenger Transportation",
  // "Travel Arrangement and Reservation Services",
  // "Truck Transportation",
  // "Uncategorized",
  // "Warehousing and Storage",
  // "Waste Management and Remediation Services",
  // "Water Transportation",
  // "Water, Sewage and Other Systems",
  // "Wholesale  Apparel, Piece Goods and and Notions",
  // "Wholesale  Beer, Wine and Distilled Alcoholic Beverages",
  // "Wholesale  Chemical and Allied Products",
  // "Wholesale  Drugs and Druggists' Sundries",
  // "Wholesale  Electrical Goods",
  // "Wholesale  Electronic Markets and Agents and Brokers",
  // "Wholesale  Farm Product Raw Material",
  // "Wholesale  Furniture, Sports, Toys, Jewelry and Other Durable Goods",
  // "Wholesale  Grocery and Related Product",
  // "Wholesale  Lumber and Other Construction Materials",
  // "Wholesale  Machinery, Equipment and Supplies",
  // "Wholesale  Metal and Mineral (except Petroleum)",
  // "Wholesale  Miscellaneous Nondurable Goods",
  // "Wholesale  Motor Vehicle and Motor Vehicle Parts and Supplies",
  // "Wholesale  Paper and Paper Product",
  // "Wholesale  Petroleum and Petroleum Products",
  // "Wholesale  Professional and Commercial Equipment and Supplies",
  // "Lessors of Miniwarehouses, SelfStorage, Other Real Estate",
  // "Wholesale  Hardware, Plumbing, Heating Equipment and Supplies",
];
