import React, { useState, forwardRef, useImperativeHandle } from "react";
import { FormGroup, Col, Row, FormInput } from "shards-react";
import {
  Switch,
  FormControlLabel,
  Select,
  FormControl,
  MenuItem,
} from "@material-ui/core";
import { Months } from "../data/months";
import { validYear } from "../utils";
import "./components.css";
import useStyles from "./styles";
import moment from "moment";

const IOSSwitch = ({ ...props }) => {
  const classes = useStyles();

  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
};

const BusinessStart = forwardRef(
  (
    {
      valueMonth,
      setValueMonth,
      valueYear,
      setValueYear,
      // valueDay, setValueDay,
      valueStartUp,
      setValueStartUp,
      btnRow,
    },
    ref,
  ) => {
    // console.log("[BusinessStart]", valueMonth);

    const classes = useStyles();

    const [errorMonth, handleErrorMonth] = useState(false);
    const [errorYear, handleErrorYear] = useState(false);
    const [successYear, setSuccessYear] = useState(false);

    const years = [];
    for (let y = parseInt(moment().format("YYYY")); y > 1974; y--)
      years.push(y.toString());

    const handleChangeMonth = async (e) => {
      const val = e.target.value;
      if (typeof setValueMonth === "function") await setValueMonth(val);
    };

    const handleChangeYear = (e) => {
      const val = e.target.value;
      if (typeof setValueYear === "function") setValueYear(val);
    };

    // const handleChangeDay = (e) => {
    //     const val = e.target.value;
    //     if (typeof setValueDay === 'function') setValueDay(val);
    // };

    const handleChecked = (e) => {
      if (typeof setValueStartUp === "function")
        setValueStartUp(e.target.checked);
    };

    const validate = () => {
      if (!!valueMonth && Months.find((m) => m === valueMonth)) {
        handleErrorMonth(false);
      } else {
        handleErrorMonth(true);
        return false;
      }
      if (!!valueYear && validYear(valueYear)) {
        setSuccessYear(true);
        handleErrorYear(false);
      } else {
        setSuccessYear(false);
        handleErrorYear(true);
        return false;
      }
      return true;
    };

    useImperativeHandle(ref, () => ({
      validate,
    }));

    return (
      <>
        <FormGroup row={true}>
          <FormGroup className="col-md-6 mb-3 mb-md-0">
            <label
              htmlFor="#start-month"
              className="custom-lbl-size display-5 text-primary text-left text-shadow--large-1"
            >
              Business Start Month
            </label>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                displayEmpty
                className={classes.customFullWidth}
                value={valueMonth || ""}
                onChange={handleChangeMonth}
                error={errorMonth}
              >
                <MenuItem value="" disabled>
                  Month
                </MenuItem>
                {Months.map((m, i) => (
                  <MenuItem key={i} value={m}>
                    {m}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FormGroup>
          <FormGroup className="col-md-6 mb-3 mb-md-0">
            <label
              htmlFor="#start-year"
              className="custom-lbl-size display-5 text-primary text-left text-shadow--large-1"
            >
              Start Year
            </label>
            <FormInput
              id="#start-year"
              placeholder="2020"
              value={valueYear || ""}
              onChange={handleChangeYear}
              valid={successYear}
              invalid={errorYear}
            />
          </FormGroup>
        </FormGroup>

        <FormGroup>
          <Row className="col-lg-10 offset-lg-1 mt-3">
            <FormControlLabel
              control={
                <IOSSwitch
                  checked={valueStartUp || false}
                  onChange={handleChecked}
                  name="startup"
                />
              }
              label="This is a start up"
              className="ml-3"
            />
          </Row>
        </FormGroup>
        {btnRow}
      </>
    );
  },
);

export default BusinessStart;
