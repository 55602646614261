import React, { useEffect, useRef, useState } from "react";
import { BACKEND_ENDPOINT } from "../config";

import { connect } from "react-redux";

import { getState, dispatch } from "../store";
import {
  fetchAssetReportIdFailure,
  fetchTransactionRequest,
  fetchTransactionSuccess,
  uploadAssetReportFailure,
  uploadAssetReportRequest,
  uploadAssetReportSuccess,
} from "../actions/plaid";

const io = require("socket.io-client");

const Sockets = (props) => {
  const socket = useRef();

  // useEffect(() => {
  //   socket.current = io(`${BACKEND_ENDPOINT}`, { transports : ['websocket'] });
  //
  //   socket.current.on('UPLOAD_ASSET_REPORT_SUCCESS', ({ id, msg }) => {
  //     dispatch(uploadAssetReportSuccess({id, msg}))
  //   });
  //
  //   socket.current.on('UPLOAD_ASSET_REPORT_REQUEST', ({ id }) => {
  //     dispatch(uploadAssetReportRequest({id}))
  //   });
  //
  //   socket.current.on('UPLOAD_ASSET_REPORT_FAILURE', ({ id, msg }) => {
  //     dispatch(uploadAssetReportFailure({msg}))
  //   });
  //
  //   socket.current.on('FETCH_TRANSACTION_SUCCESS', ({ id, result }) => {
  //     dispatch(fetchTransactionSuccess({id, result}))
  //   });
  //
  //   socket.current.on('FETCH_TRANSACTION_REQUEST', ({ id }) => {
  //     dispatch(fetchTransactionRequest({id}))
  //   });
  //
  //   socket.current.on('FETCH_TRANSACTION_FAILURE', ({ id, result }) => {
  //     dispatch(fetchAssetReportIdFailure({id, result}))
  //   });
  //
  //   return () => {
  //     socket.current.removeAllListeners();
  //     socket.current.close();
  //   };
  // }, []);

  return <div />;
};

function mapStateToProps(state) {
  const { form, plaid } = state;

  return {
    formState: form,
    plaidState: plaid,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Sockets);
