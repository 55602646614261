import axios from "axios";
import { API_ENDPOINT } from "../config";

/**
 * update values
 * @param {Object} values
 * @param {Function} next
 */
export const updateValues = (values, next) => {
  const url = API_ENDPOINT + "/customers/";

  axios
    .post(url, {
      ...values,
      domain: process.env.REACT_APP_DOMAIN,
    })
    .then((res) => {
      console.log("[updateValues]", res.status, res.data);
      const { data } = res;
      if (data && data._id) {
        if (typeof next === "function") next(data._id);
      }
    })
    .catch((err) => {
      console.warn("[updateValues] error: ", err);
    });
};

/**
 * get values
 * @param {String} _id
 * @param {Function} next
 */
export const getValues = (_id, next) => {
  const url = API_ENDPOINT + "/customers/" + _id;

  axios
    .get(url)
    .then((res) => {
      console.log("[getValues]", res.status, res.data);
      const { data } = res;
      if (data && data.field_data) {
        if (typeof next === "function") next(data.field_data);
      }
    })
    .catch((err) => {
      console.warn("[getValues] error: ", err);
    });
};

/**
 * validate Email Address
 * @param {String} email
 * @param {Function} next
 */
export const validateEmailAddress = (email, next) => {
  const url = API_ENDPOINT + "/validate-email?email=" + email;

  axios
    .get(url)
    .then((res) => {
      console.log("[validateEmailAddress]", res.status, res.data);
      const { data } = res;
      if (data && data.valid) {
        if (typeof next === "function") next(true);
      } else {
        if (typeof next === "function") next(false);
      }
    })
    .catch((err) => {
      console.warn("[validateEmailAddress] error: ", err);
    });
};

/**
 * async validate Email Address
 * @param {*} email
 * @returns {Boolean}
 */
export const asyncValidateEmailAddress = (email) => {
  return new Promise((resolve, reject) => {
    validateEmailAddress(email, (result) => {
      resolve(result);
    });
  });
};

/**
 * validate Phone Number
 * @param {String} phone
 * @param {Function} next
 */
export const validatePhoneNumber = (phone, next) => {
  const url = API_ENDPOINT + "/validate-phone?phone=" + phone;

  axios
    .get(url)
    .then((res) => {
      console.log("[validatePhoneNumber]", res.status, res.data);
      const { data } = res;
      if (data && data.valid) {
        if (typeof next === "function") next(true);
      } else {
        if (typeof next === "function") next(false);
      }
    })
    .catch((err) => {
      console.warn("[validatePhoneNumber] error: ", err);
    });
};

/**
 * async validate Phone Number
 * @param {*} phone
 * @returns {Boolean}
 */
export const asyncValidatePhoneNumber = (phone) => {
  return new Promise((resolve, reject) => {
    validatePhoneNumber(phone, (result) => {
      resolve(result);
    });
  });
};

/**
 * upload File
 * @param {Object} values
 * @param {File} file
 * @param {Function} next
 * @param {Function} onUploadProgress
 */
export const uploadFile = (values, file, next, onUploadProgress) => {
  const url = `${API_ENDPOINT}/upload-dropbox?_id=${values._id}&firstname=${values.firstname}&lastname=${values.lastname}&domain=${process.env.REACT_APP_DOMAIN}`;
  // Create an object of formData
  const formData = new FormData();
  // Update the formData object
  formData.append("file", file, file.name);

  // Details of the uploaded file
  console.log("[uploadFile] file", file);

  // Request made to the backend api
  // Send formData object
  axios
    .post(url, formData, {
      onUploadProgress:
        typeof onUploadProgress === "function" ? onUploadProgress : () => {},
    })
    .then((res) => {
      console.log("[uploadFile]", res.status, res.data);
      const { data } = res;
      // if (data && data.file_path) {
      //     if (typeof next === 'function') next(data.file_path);
      // }
      // if (data && data.result && data.result.path_display) {
      //     if (typeof next === 'function') next(data.result.path_display);
      // }
      if (data) {
        if (typeof next === "function") next(data);
      }
    })
    .catch((err) => {
      console.warn("[uploadFile] error: ", err);
    });
};

export const saveBankStatement = (_id, sharedList, next) => {
  const url = API_ENDPOINT + "/save-bankstatement?_id=" + _id;
  axios
    .post(url, { sharedList })
    .then((res) => {
      console.log("[save bankstatement]", res.status, res.data);
      const { data } = res;
      if (data) {
        if (typeof next === "function") next(data);
      }
    })
    .catch((err) => {
      console.warn("[save bankstatement] error: ", err);
    });
};

/**
 * async upload File
 * @param {Object} values
 * @param {File} file
 * @param {Function} onUploadProgress
 * @returns {String}
 */
export const asyncUploadFile = (values, file, onUploadProgress) => {
  return new Promise((resolve, reject) => {
    uploadFile(
      values,
      file,
      (result) => {
        resolve(result);
      },
      onUploadProgress,
    );
  });
};

export const asyncSaveBankStatement = (_id, sharedList) => {
  return new Promise((resolve, reject) => {
    saveBankStatement(_id, sharedList, (result) => {
      resolve(result);
    });
  });
};

/**
 * send email
 * @param {String} _id
 * @param {Function} next
 */
export const sendEmail = (_id, next) => {
  const url = API_ENDPOINT + "/send-email?_id=" + _id;

  axios
    .get(url)
    .then((res) => {
      console.log("[sendEmail]", res.status, res.data);
      const { data } = res;
      // if (data && data.accepted && data.accepted.length) {
      if (data && data.id) {
        if (typeof next === "function") next(true);
      } else {
        if (typeof next === "function") next(false);
      }
    })
    .catch((err) => {
      console.warn("[sendEmail] error: ", err);
    });
};

/**
 * update values to GoogleSheet
 * @param {String} _id
 * @param {Function} next
 */
export const updateValuesGoogleSheet = (_id, next) => {
  const url =
    API_ENDPOINT +
    "/google-sheet?_id=" +
    _id +
    "&domain=" +
    process.env.REACT_APP_DOMAIN;

  axios
    .get(url)
    .then((res) => {
      console.log("[updateValuesGoogleSheet]", res.status, res.data);
      const { data } = res;
      if (data && data.result) {
        if (typeof next === "function") next(data.result);
      }
    })
    .catch((err) => {
      console.warn("[updateValuesGoogleSheet] error: ", err);
    });
};

/**
 * Create pdf for E-sign and upload to dropbox and get shared link for e-sign pdf
 * @param {String} _id
 * @param {Function} next
 */
export const createUploadPdfESign = (_id, next) => {
  const url =
    API_ENDPOINT +
    "/e-sign?_id=" +
    _id +
    "&domain=" +
    process.env.REACT_APP_DOMAIN;

  axios
    .get(url)
    .then((res) => {
      console.log("[create upload pdf signature]", res.status, res.data);
      const { data } = res;
      if (data) {
        if (typeof next === "function") next(data.signatureLink);
      }
    })
    .catch((err) => {
      console.warn("[create upload pdf signature] error: ", err);
    });
};

/**
 * Get Link Token from Plaid
 * @param {String} _id
 * @param {Function} next
 * */
export const fetchLinkToken = (_id, next) => {
  const url = API_ENDPOINT + "/plaid/create_link_token?_id=" + _id;
  axios
    .post(url, {})
    .then((res) => {
      console.log("[create link token for plaid]", res.status, res.data);
      const { data } = res;
      if (data) {
        if (typeof next === "function") next(data.link_token);
      }
    })
    .catch((err) => {
      console.warn("[create link token for plaid error:", err);
    });
};

/**
 * Get PDF asset report and upload dropbox
 * @param {String} _id
 * @param {String} public_token
 * @param {Function} next
 * @param {Function} failure
 */
export const uploadAssetReport = async (_id, public_token, next, failure) => {
  let transaction_list = [];
  let url = `${API_ENDPOINT}/plaid/request-bankstatement?_id=${_id}`;
  try {
    let response = await axios.post(
      url,
      { public_token: public_token },
      { timeout: 60 * 60 * 1000 },
    );
    console.log("[Fetch Transaction]", response.status, response.data);
    next(response.data.data);
  } catch (err) {
    console.log("[Upload Asset Report to Dropbox error:]", err);
    failure(err);
  }
  // for(let i = 0; i < 1 ; i ++) {
  //     let start_date = moment().subtract(i, 'months').startOf('month').format('YYYY-MM-DD')
  //     let end_date = i === 0 ? moment().format('YYYY-MM-DD') : moment().subtract(i, 'months').endOf('month').format('YYYY-MM-DD')
  //     url = `${API_ENDPOINT}/plaid/pdf_asset_report?_id=${_id}`;
  //     try {
  //         let response = await axios.post(url, { public_token: public_token, duration: {start: start_date, end: end_date} }, {timeout: 60 * 60 * 1000})
  //         console.log("[Fetch Transaction]", response.status, response.data)
  //         transaction_list = [...transaction_list, {data: response.data.data, duration: {start: start_date, end: end_date}}]
  //     } catch (err) {
  //         console.log("[Upload Asset Report to Dropbox error:]", err)
  //     }
  // }
  // url = `${API_ENDPOINT}/plaid/upload-transaction?_id=${_id}`
  // axios.post(url, {transaction_data: transaction_list, public_token}, {timeout: 60 * 60 * 1000})
  //     .then(res => {
  //         const {data} = res
  //         console.log("[Upload Transaction to Dropbox]", res.status, res.data)
  //         if(typeof next === 'function') next(data)
  //     })
  //     .catch(err => {
  //         console.log("[Upload Transaction error:]", err)
  //         if(typeof failure === 'function') failure(err)
  //     })
};

/**
 * upload application to salesforce
 * @param {String} _id
 * @param {Function} next
 */
export const uploadApplicationToSalesforce = (_id, next) => {
  const url = API_ENDPOINT + "/salesforce?_id=" + _id;

  axios
    .get(url)
    .then((res) => {
      console.log("[uploadApplicationToSalesforce]", res.status, res.data);
      const { data } = res;
      if (data && data.result) {
        if (typeof next === "function") next(data.result);
      }
    })
    .catch((err) => {
      console.warn("[uploadApplicationToSalesforce] error: ", err);
      next("got error for upload salesforce");
    });
};

/**
 * upload application to salesforce
 * @param {String} _id
 * @param {Function} next
 */
export const uploadApplicationToLendSaas = (_id, next) => {
  const url = API_ENDPOINT + "/lendsaas?_id=" + _id;

  axios
    .get(url)
    .then((res) => {
      console.log("[uploadApplicationToLendSaas]", res.status, res.data);
      const { data } = res;
      if (data && data.result) {
        if (typeof next === "function") next(data.result);
      }
    })
    .catch((err) => {
      console.warn("[uploadApplicationToLendSaas] error: ", err);
    });
};

/**
 * accept bank statement request
 * @param {String} _id
 * @param {Function} next
 */
export const acceptRequest = (_id, next) => {
  const url = API_ENDPOINT + "/accept-request?_id=" + _id;

  axios
    .get(url)
    .then((res) => {
      console.log("[accept request]", res.status, res.data);
      const { data } = res;
      if (data) {
        if (typeof next === "function") next(data);
      }
    })
    .catch((err) => {
      console.warn("[uploadApplicationToLendSaas] error: ", err);
    });
};
