import HorizonLogo from "../images/logo/horizon_logo_small.png";
import LightHorizonLogo from "../images/logo/light_horizon_logo.png";

export const serverUrl = process.env.REACT_APP_SERVER_URL || "http://localhost";
export const serverPort = process.env.REACT_APP_SERVER_PORT || "";
export const BACKEND_ENDPOINT =
  `${serverUrl}${serverPort.length ? `:${serverPort}` : ""}` ||
  "http://localhost:5000";
export const API_ENDPOINT = BACKEND_ENDPOINT + "/api";
export const BIZBOOST_ENDPOINT = "https://app.bittyadvance.com/nav";
export const GOOGLE_APIKEY = process.env.REACT_APP_GOOGLE;
export const IS_BIZBOOST = process.env.REACT_APP_DOMAIN === "bizboost";
export const IS_CAPITALIZED = process.env.REACT_APP_DOMAIN === "capitalized";
export const IS_NORTH = process.env.REACT_APP_DOMAIN === "north";
export const IS_HORIZON = process.env.REACT_APP_DOMAIN === "horizon";
export const CAPITALIZED_GTM = process.env.REACT_APP_CAPITALIZED_GTM;
export const BIZBOOST_GTM = process.env.REACT_APP_BIZBOOST_GTM;
export const NORTHEAST_GTM = process.env.REACT_APP_NORTHEAST_GTM;
export const HORIZON_GTM = process.env.REACT_APP_HORIZON_GTM;

export const light_logo = IS_CAPITALIZED
  ? "https://uploads-ssl.webflow.com/60bb933501f4d222a939213b/60bd895b20af38681374862a_Capitalized%20Logo%20White%20Transparent%20BG%20Trimmed.png"
  : IS_HORIZON
  ? LightHorizonLogo
  : IS_NORTH
  ? "https://uploads-ssl.webflow.com/6214178c8d07ef17380e7a64/621eb6651318593266e38a59_north-logo-dark.png"
  : "https://uploads-ssl.webflow.com/5dccd80630b4b1114297e35f/60c21aa4a58a7950235871c8_Bizboost%20Logo%20-%20White.png";
export const dark_logo = IS_CAPITALIZED
  ? "https://uploads-ssl.webflow.com/60bb933501f4d222a939213b/60bd89548c41c8dee96d5c41_Capitalized%20Logo%20Blue%2BGray%20Transparent%20BG%20Trimmed.png"
  : IS_HORIZON
  ? HorizonLogo
  : IS_NORTH
  ? "https://uploads-ssl.webflow.com/6214178c8d07ef17380e7a64/6214515d33fa842835ca1123_north-logo-light.png"
  : "https://uploads-ssl.webflow.com/5dccd80630b4b1114297e35f/60eccdd246014ea2767121f4_dark-logo-bizboost.png";
export const copy_right = IS_CAPITALIZED
  ? "Capitalized.co"
  : IS_HORIZON
  ? "Horizon Funding Group"
  : "Bizboost";
export const category = IS_CAPITALIZED
  ? "Capitalized"
  : IS_HORIZON
  ? "Horizon Funding Group"
  : "Bizboost";
export const link_logo = IS_CAPITALIZED
  ? "https://www.capitalized.co/"
  : IS_NORTH
  ? "https://www.nnefunding.com/"
  : IS_HORIZON
  ? "https://www.horizonfundinggroup.com/"
  : "https://www.bizboost.com/";

export const contact_phone = IS_CAPITALIZED
  ? "(800) 919-5351"
  : IS_HORIZON
  ? "(718) 513-6633"
  : "(800) 341-7509";

export const otherServiceData = {
  insurance: "Business insurance",
  creditcards: "Business credit cards",
  loans: "Personal loans",
  bookkeeping: "Book keeping",
  support: "HR support",
  processing: "Credit card processing",
};
