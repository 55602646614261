import React, { useState, forwardRef, useImperativeHandle } from "react";
import { FormInput, FormGroup, Col, Row } from "shards-react";
import "./components.css";

const UserName = forwardRef(
  ({ valueFirst, setValueFirst, valueLast, setValueLast }, ref) => {
    const [successFirst, setSuccessFirst] = useState(false);
    const [errorFirst, handleErrorFirst] = useState(false);
    const [successLast, setSuccessLast] = useState(false);
    const [errorLast, handleErrorLast] = useState(false);

    const handleChangeFirst = (e) => {
      const val = e.target.value;
      if (typeof setValueFirst === "function") setValueFirst(val);
    };

    const handleChangeLast = (e) => {
      const val = e.target.value;
      if (typeof setValueLast === "function") setValueLast(val);
    };

    const validate = () => {
      if (!!valueFirst && valueFirst !== "") {
        setSuccessFirst(true);
        handleErrorFirst(false);
      } else {
        setSuccessFirst(false);
        handleErrorFirst(true);
        return false;
      }
      if (!!valueLast && valueLast !== "") {
        setSuccessLast(true);
        handleErrorLast(false);
      } else {
        setSuccessLast(false);
        handleErrorLast(true);
        return false;
      }
      return true;
    };

    useImperativeHandle(ref, () => ({
      validate,
    }));

    return (
      <Row>
        <Col sm={6}>
          <FormGroup className="form-group-center">
            <Col>
              <label
                htmlFor="#first-name"
                className="custom-lbl-size display-5 text-primary text-left text-shadow--large-1"
              >
                First Name
              </label>
            </Col>
            <Col>
              <FormInput
                id="#first-name"
                placeholder="e.g. John"
                value={valueFirst || ""}
                onChange={handleChangeFirst}
                valid={successFirst}
                invalid={errorFirst}
              />
            </Col>
          </FormGroup>
        </Col>
        <Col sm={6}>
          <FormGroup className="form-group-center">
            <Col>
              <label
                htmlFor="#last-name"
                className="custom-lbl-size display-5 text-primary text-left text-shadow--large-1"
              >
                Last Name
              </label>
            </Col>
            <Col>
              <FormInput
                id="#last-name"
                placeholder="e.g. Smith"
                value={valueLast || ""}
                onChange={handleChangeLast}
                valid={successLast}
                invalid={errorLast}
              />
            </Col>
          </FormGroup>
        </Col>
      </Row>
    );
  },
);

export default UserName;
