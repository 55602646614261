import { combineReducers, createStore, applyMiddleware } from "redux";
import { routerMiddleware } from "react-router-redux";
import { createBrowserHistory } from "history";
import { connectRouter } from "connected-react-router";
import { composeWithDevToolsDevelopmentOnly } from "@redux-devtools/extension";

import { formReducer } from "./reducers/form";
import { loadReducer } from "./reducers/load";
import { plaidReducer } from "./reducers/plaid";
import { adminReducer } from "./reducers/admin";

const history = createBrowserHistory();
const middleware = routerMiddleware(history);

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    form: formReducer,
    load: loadReducer,
    plaid: plaidReducer,
    admin: adminReducer,
  });

const composeEnhancers = composeWithDevToolsDevelopmentOnly({});

const store = createStore(
  createRootReducer(history), // root reducer with router state
  {},
  composeEnhancers(
    applyMiddleware(
      middleware, // for dispatching history actions
    ),
  ),
);

// getState
const getState = store.getState;

// dispatch
const dispatch = store.dispatch;

// Root Reducers
export { store, history, getState, dispatch };
