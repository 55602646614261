import React, { useState, forwardRef, useImperativeHandle } from "react";
import { FormInput, FormGroup, Col, Row } from "shards-react";
import { Select, FormControl, MenuItem } from "@material-ui/core";
import { validateTaxId, getAddressInfo } from "../utils";
import { InputTaxId, InputZipCode, InputAutoAdress } from "./CustomInputs";
import "./components.css";
import useStyles from "./styles";

const Business = forwardRef(
  (
    {
      valueBusinessName,
      setValueBusinessName,
      valueTaxId,
      setValueTaxId,
      valueBusinessAddress,
      setValueBusinessAddress,
      valueBusinessCity,
      setValueBusinessCity,
      valueBusinessState,
      setValueBusinessState,
      setValueBusinessCityState,
      valueBusinessZipCode,
      setValueBusinessZipCode,
      setValueDBA,
      valueDBA,
      valueIncorporationType,
      setValueIncorporationType,
    },
    ref,
  ) => {
    const classes = useStyles();

    const [successBusinessName, setSuccessBusinessName] = useState(false);
    const [errorBusinessName, handleErrorBusinessName] = useState(false);
    const [successTaxId, setSuccessTaxId] = useState(false);
    const [errorTaxId, handleErrorTaxId] = useState(false);
    const [successBusinessAddress, setSuccessBusinessAddress] = useState(false);
    const [errorBusinessAddress, handleErrorBusinessAddress] = useState(false);
    const [successBusinessCity, setSuccessBusinessCity] = useState(false);
    const [errorBusinessCity, handleErrorBusinessCity] = useState(false);
    const [successBusinessState, setSuccessBusinessState] = useState(false);
    const [errorBusinessState, handleErrorBusinessState] = useState(false);
    const [successBusinessZipCode, setSuccessBusinessZipCode] = useState(false);
    const [errorBusinessZipCode, handleErrorBusinessZipCode] = useState(false);
    const [, setSuccessIncorporationType] = useState(false);
    const [errorIncorporationType, handleErrorIncorporationType] =
      useState(false);
    const [successDBA, setSuccessDBA] = useState(false);
    const [errorDBA, handleErrorDBA] = useState(false);

    const [addresses, setAddr] = useState({
      city: valueBusinessCity,
      state: valueBusinessState,
      address: valueBusinessAddress,
      postcode: valueBusinessZipCode,
    });

    const incorporationTypes = [
      "Corporation",
      "LLC",
      "LLP",
      "Ltd. Partnership",
      "Partnership",
      "Sole Proprietor",
      "C Corporation",
      "S Corporation",
    ];

    const handleChangeBusinessName = (e) => {
      const val = e.target.value;
      if (typeof setValueBusinessName === "function") setValueBusinessName(val);
    };

    const handleChangeTaxId = (e) => {
      const val = e.target.value;
      if (typeof setValueTaxId === "function") setValueTaxId(val);
    };

    const handleChangeDBA = (e) => {
      const val = e.target.value;
      if (typeof setValueDBA === "function") setValueDBA(val);
    };

    const handleGetCityState = (place) => {
      const {
        state,
        locality: city,
        address1: address,
        postcode,
      } = getAddressInfo(place);
      const modifiedPostCode = postcode.split("-")[0];
      setAddr({ city, state, address, postcode: modifiedPostCode });
      console.log("[business address] ", {
        city,
        state,
        address,
        modifiedPostCode,
      });
      if (typeof setValueBusinessAddress === "function")
        setValueBusinessAddress(address);
      if (typeof setValueBusinessCity === "function")
        setValueBusinessCity(city);
      if (typeof setValueBusinessState === "function")
        setValueBusinessState(state);
      if (typeof setValueBusinessZipCode === "function")
        setValueBusinessZipCode(modifiedPostCode);
    };

    const handleChangeBusinessAddress = (e) => {
      const val = e.target.value;
      setAddr({ ...addresses, address: val });
      if (typeof setValueBusinessAddress === "function")
        setValueBusinessAddress(val);
    };

    const handleChangeBusinessCity = (e) => {
      const val = e.target.value;
      setAddr({ ...addresses, city: val });
      if (typeof setValueBusinessCity === "function") setValueBusinessCity(val);
    };

    const handleChangeBusinessState = (e) => {
      const val = e.target.value;
      setAddr({ ...addresses, state: val });
      if (typeof setValueBusinessState === "function")
        setValueBusinessState(val);
    };

    const handleChangeBusinessZipCode = (e) => {
      const val = e.target.value;
      setAddr({ ...addresses, postcode: val });
      if (typeof setValueBusinessZipCode === "function")
        setValueBusinessZipCode(val);
    };

    const handleChangeIncorporationType = (e) => {
      const val = e.target.value;
      if (typeof setValueIncorporationType === "function")
        setValueIncorporationType(val);
    };

    const validate = () => {
      const { city, state, address, postcode } = addresses;
      // if (typeof setValueBusinessCityState === 'function') {
      //     setValueBusinessCityState(city, state, address, postcode);
      // }

      if (!!valueBusinessName && valueBusinessName !== "") {
        setSuccessBusinessName(true);
        handleErrorBusinessName(false);
      } else {
        setSuccessBusinessName(false);
        handleErrorBusinessName(true);
        return false;
      }

      if (!!valueDBA && valueDBA !== "") {
        setSuccessDBA(true);
        handleErrorDBA(false);
      } else {
        setSuccessDBA(false);
        handleErrorDBA(true);
        return false;
      }

      if (validateTaxId(valueTaxId)) {
        setSuccessTaxId(true);
        handleErrorTaxId(false);
      } else {
        setSuccessTaxId(false);
        handleErrorTaxId(true);
        return false;
      }
      // if (!!valueBusinessAddress && valueBusinessAddress !== '') {
      if (!!address && address !== "") {
        setSuccessBusinessAddress(true);
        handleErrorBusinessAddress(false);
      } else {
        setSuccessBusinessAddress(false);
        handleErrorBusinessAddress(true);
        return false;
      }
      // if (!!valueBusinessCity && valueBusinessCity !== '') {
      if (!!city && city !== "") {
        setSuccessBusinessCity(true);
        handleErrorBusinessCity(false);
      } else {
        setSuccessBusinessCity(false);
        handleErrorBusinessCity(true);
        return false;
      }
      // if (!!valueBusinessState && valueBusinessState !== '') {
      if (!!state && state !== "") {
        setSuccessBusinessState(true);
        handleErrorBusinessState(false);
      } else {
        setSuccessBusinessState(false);
        handleErrorBusinessState(true);
        return false;
      }
      // if (!!valueBusinessZipCode && valueBusinessZipCode !== '') {
      if (!!postcode && postcode !== "") {
        setSuccessBusinessZipCode(true);
        handleErrorBusinessZipCode(false);
      } else {
        setSuccessBusinessZipCode(false);
        handleErrorBusinessZipCode(true);
        return false;
      }
      if (
        !!valueIncorporationType &&
        incorporationTypes.find((t) => t === valueIncorporationType)
      ) {
        setSuccessIncorporationType(true);
        handleErrorIncorporationType(false);
      } else {
        setSuccessIncorporationType(false);
        handleErrorIncorporationType(true);
        return false;
      }
      return true;
    };

    useImperativeHandle(ref, () => ({
      validate,
    }));

    return (
      <>
        <Row>
          <Col sm={6}>
            <FormGroup className="form-group-center">
              <Col>
                <label
                  htmlFor="#business-name"
                  className="custom-lbl-size display-5 text-primary text-left text-shadow--large-1"
                >
                  Business Legal Name
                </label>
              </Col>
              <Col>
                <FormInput
                  id="business-name"
                  placeholder=""
                  value={valueBusinessName || ""}
                  onChange={handleChangeBusinessName}
                  valid={successBusinessName}
                  invalid={errorBusinessName}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup className="form-group-center">
              <Col>
                <label
                  htmlFor="#dba"
                  className="custom-lbl-size display-5 text-primary text-left text-shadow--large-1"
                >
                  Business DBA
                </label>
              </Col>
              <Col>
                <FormInput
                  id="dba"
                  placeholder=""
                  value={valueDBA || ""}
                  onChange={handleChangeDBA}
                  valid={successDBA}
                  invalid={errorDBA}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup className="form-group-center">
              <Col>
                <label
                  htmlFor="#tax-id"
                  className="custom-lbl-size display-5 text-primary text-left text-shadow--large-1"
                >
                  Tax ID
                </label>
              </Col>
              <Col>
                <InputTaxId
                  id="tax-id"
                  value={valueTaxId || ""}
                  onChange={handleChangeTaxId}
                  placeholder="27-1115599"
                  valid={successTaxId}
                  invalid={errorTaxId}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup className="form-group-center">
              <Col>
                <label
                  htmlFor="#business-address"
                  className="custom-lbl-size display-5 text-primary text-left text-shadow--large-1"
                >
                  Business Address
                </label>
              </Col>
              <Col>
                <InputAutoAdress
                  id="#business-address"
                  value={addresses.address || ""}
                  onChange={handleChangeBusinessAddress}
                  valid={successBusinessAddress}
                  invalid={errorBusinessAddress}
                  onPlaceSelected={handleGetCityState}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup className="form-group-center">
              <Col>
                <label
                  htmlFor="#business-city"
                  className="custom-lbl-size display-5 text-primary text-left text-shadow--large-1"
                >
                  City
                </label>
              </Col>
              <Col>
                <FormInput
                  id="#business-city"
                  placeholder=""
                  value={addresses.city || ""}
                  onChange={handleChangeBusinessCity}
                  valid={successBusinessCity}
                  invalid={errorBusinessCity}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup className="form-group-center">
              <Col>
                <label
                  htmlFor="#business-state"
                  className="custom-lbl-size display-5 text-primary text-left text-shadow--large-1"
                >
                  State
                </label>
              </Col>
              <Col>
                <FormInput
                  id="#business-state"
                  placeholder=""
                  value={addresses.state || ""}
                  onChange={handleChangeBusinessState}
                  valid={successBusinessState}
                  invalid={errorBusinessState}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup className="form-group-center">
              <Col>
                <label
                  htmlFor="#business-zip-code"
                  className="custom-lbl-size display-5 text-primary text-left text-shadow--large-1"
                >
                  Zip Code
                </label>
              </Col>
              <Col>
                <InputZipCode
                  id="business-zip-code"
                  value={addresses.postcode || ""}
                  onChange={handleChangeBusinessZipCode}
                  placeholder="10001"
                  valid={successBusinessZipCode}
                  invalid={errorBusinessZipCode}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup className="form-group-center">
              <Col>
                <label
                  htmlFor="#incorporation-type"
                  className="custom-lbl-size display-5 text-primary text-left text-shadow--large-1"
                >
                  Incorporation Type
                </label>
              </Col>
              <Col>
                <FormControl variant="outlined" className={classes.formControl}>
                  <Select
                    displayEmpty
                    className={classes.customFullWidth}
                    value={valueIncorporationType || ""}
                    onChange={handleChangeIncorporationType}
                    error={errorIncorporationType}
                  >
                    <MenuItem value="" disabled>
                      Select One
                    </MenuItem>
                    {incorporationTypes.map((t, i) => (
                      <MenuItem key={i} value={t}>
                        {t}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>
            </FormGroup>
          </Col>
        </Row>
      </>
    );
  },
);

export default Business;
