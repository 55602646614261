import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Col, Button } from "shards-react";
import { Stepper, Step, StepButton } from "@material-ui/core";
import { getState, dispatch } from "../store";
import { formSetValue } from "../actions/form";
import Congratulations from "../components/Congratulations";
import BusinessName from "../components/BusinessName";
import UserName from "../components/UserName";
import EmailPhone from "../components/EmailPhone";
import Finish from "../components/Finish";
import { updateValues, sendEmail, updateValuesGoogleSheet } from "../api";
import { getGTMId, gotoBizboost } from "../utils";
import { loadInit } from "./handle";
import "./pages.css";
import { IS_BIZBOOST, IS_NORTH } from "../config";
import TagManager from "react-gtm-module";

const subtitles = ["Business name", "User name", "Email & Phone", "Finish"];

export default function SecondPage({ location }) {
  const history = useHistory();

  const [step, setStep] = useState(-1);
  const [isFinished, setFinish] = useState(false);
  const [values, setValues] = useState({
    ...getState().form,
    current_page: "second",
  });
  console.log("[SecondPage]", getState().form, step);

  const steps = ["Business", "Owners", "User Info", "Documents"];

  const businessRef = useRef(null);
  const usernameRef = useRef(null);
  const emailphoneRef = useRef(null);

  const handleStep = (index) => {
    if (index >= 0 && index < 4) setStep(index);
  };

  const handleContinue = async (e) => {
    e.target.blur();
    let validationSuccess = false;
    if (step === -1) validationSuccess = true;
    else if (step === 0)
      validationSuccess = businessRef.current && businessRef.current.validate();
    else if (step === 1)
      validationSuccess = usernameRef.current && usernameRef.current.validate();
    else if (step === 2)
      validationSuccess =
        emailphoneRef.current && (await emailphoneRef.current.validate());
    else if (step === 3) validationSuccess = true;

    if (validationSuccess) {
      if (step === 3) {
        // finish
        // upload to server
        updateValues({ ...values, current_step: step }, (id) => {
          updateValuesGoogleSheet(id, (result) => {
            // sendEmail(id, (result) => {
            if (result) {
              setFinish(true);
              dispatch(formSetValue({ ...values, _id: id }));
              gotoBizboost(values);
            }
            // });
          });
        });
      } else {
        // upload to server
        updateValues({ ...values, current_step: step + 1 }, (id) => {
          setStep(step + 1);
          dispatch(formSetValue({ ...values, _id: id }));
          setValues({ ...values, _id: id });
        });
      }
    }
  };

  const handleChangeValue = (key, val) => {
    setValues({ ...values, [key]: val });
  };

  useEffect(() => {
    loadInit({ location, history, setStep, page: "second", setValues, values });
  }, []);

  // useEffect(() => {
  //     if(getGTMId() && step > -1 && step < 3) {
  //         console.log("**** Track Second Page ******\n", `${document.location.origin}${document.location.pathname}${document.location.hash}${document.location.search}`)
  //         window.dataLayer.push({
  //             event: 'pageview',
  //             page: {
  //                 url: `${document.location.origin}${document.location.pathname}${document.location.hash}${document.location.search}`,
  //                 title: `${document.title} - Second Step - ${subtitles[step]}`
  //             }
  //         })
  //     }
  // }, [step])

  return (
    <Col className="custom-white-panel pt-1 pb-5">
      <Col>
        {step >= 0 && (
          <Stepper activeStep={step}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepButton
                  onClick={() => handleStep(index)}
                  completed={index <= step}
                >
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        )}
      </Col>
      <Col className="mt-5">
        {step === -1 && <Congratulations values={values} isSecond={true} />}
        {step === 0 && (
          <BusinessName
            ref={businessRef}
            value={values.businessname}
            setValue={(v) => handleChangeValue("businessname", v)}
          />
        )}
        {step === 1 && (
          <UserName
            ref={usernameRef}
            valueFirst={values.firstname}
            setValueFirst={(v) => handleChangeValue("firstname", v)}
            valueLast={values.lastname}
            setValueLast={(v) => handleChangeValue("lastname", v)}
          />
        )}
        {step === 2 && (
          <EmailPhone
            ref={emailphoneRef}
            valueEmail={values.email}
            setValueEmail={(v) => handleChangeValue("email", v)}
            valuePhone={values.phone}
            setValuePhone={(v) => handleChangeValue("phone", v)}
          />
        )}
        {step === 3 && <Finish isSecond={true} />}
      </Col>
      {/*<Col className="mt-5">*/}
      {/*    { !isFinished && !IS_NORTH && <Button*/}
      {/*        theme="success"*/}
      {/*        onClick={handleContinue}*/}
      {/*        className="w-100"*/}
      {/*    >*/}
      {/*        {(step === -1) ? 'Start Your Application' : ((step === 3) ? 'Finish' : 'Continue')}*/}
      {/*    </Button> }*/}
      {/*</Col>*/}
    </Col>
  );
}
