import { API_ENDPOINT } from "../config";
import axios from "axios";
import { authHeader } from "../utils";

/**
 * fetch applications
 * @param {String} domain
 * @param {Function} next
 */
export const fetchApplications = (domain, next) => {
  const url = `${API_ENDPOINT}/applications?domain=${domain}`;

  axios
    .get(url, { ...authHeader() })
    .then((res) => {
      console.log("[fetch applications]", res.status, res.data);
      const { data } = res;
      if (data) {
        next(null, data);
      }
    })
    .catch((err) => {
      console.warn("[fetch applications] error: ", err);
      next(err.response);
    });
};

/**
 * fetch applications
 * @param {String} domain
 * @param {Function} next
 */
export const fetchSpecialApplications = (domain, next) => {
  const url = `${API_ENDPOINT}/special?domain=${domain}`;

  axios
    .get(url, { ...authHeader() })
    .then((res) => {
      console.log("[fetchSpecialApplications]", res.status, res.data);
      const { data } = res;
      if (data) {
        next(null, data);
      }
    })
    .catch((err) => {
      console.warn("[fetchSpecialApplications] error: ", err);
      next(err.response);
    });
};

/**
 * fetch users
 * @param {Function} next
 */
export const fetchUsers = (next) => {
  const url = `${API_ENDPOINT}/users`;

  axios
    .get(url, { ...authHeader() })
    .then((res) => {
      console.log("[fetch users]", res.status, res.data);
      const { data } = res;
      if (data) {
        next(null, data);
      }
    })
    .catch((err) => {
      console.warn("[fetch users] error: ", err.response);
      next(err.response);
    });
};

/**
 * fetch users
 * @param {Object} data
 * @param {Function} next
 */
export const requestBankstatement = (data, next) => {
  const url = `${API_ENDPOINT}/request-bankstatement`;

  axios
    .post(url, data, { ...authHeader() })
    .then((res) => {
      console.log("[request bankstatement]", res.status, res.data);
      const { data } = res;
      if (data) {
        next(null, data);
      }
    })
    .catch((err) => {
      console.warn("[request bankstatement] error: ", err.response);
      next(err.response);
    });
};

/**
 * fetch users
 * @param {Object} data
 * @param {Function} next
 */
export const getRequestLink = (data, next) => {
  const url = `${API_ENDPOINT}/request-link`;

  axios
    .post(url, data, { ...authHeader() })
    .then((res) => {
      console.log("[request link]", res.status, res.data);
      const { data } = res;
      if (data) {
        next(null, data);
      }
    })
    .catch((err) => {
      console.warn("[request link] error: ", err.response);
      next(err.response);
    });
};

/**
 * fetch latest bankstatement doc
 * @param {String} id
 * @param {Function} next
 */
export const refreshBankstatement = (id, next) => {
  const url = `${API_ENDPOINT}/plaid/refresh-bankstatement?_id=${id}`;

  axios
    .post(url, {}, { ...authHeader() })
    .then((res) => {
      console.log("[refresh bankstatement]", res.status, res.data);
      const { data } = res;
      if (res.status == 200) {
        next(null, data.data);
      } else {
        next(data.data);
      }
    })
    .catch((err) => {
      console.warn("[refresh bankstatement] error: ", err.response);
      next(err.response);
    });
};

/**
 * create new customer by admin
 * @param {Object} data
 * @param {Function} next
 */
export const createNewCustomer = (data, next) => {
  const url = `${API_ENDPOINT}/admin/new-application`;

  axios
    .post(url, data, { ...authHeader() })
    .then((res) => {
      console.log("[create new customer]", res.status, res.data);
      const { data } = res;
      if (data) {
        next(null, data);
      }
    })
    .catch((err) => {
      console.warn("[request bankstatement] error: ", err.response);
      next(err.response);
    });
};

/**
 * delete new customer by admin
 * @param {String} _id
 * @param {Function} next
 */
export const deleteNewCustomer = (_id, next) => {
  const url = `${API_ENDPOINT}/admin/del-app?_id=${_id}`;

  axios
    .post(url, {}, { ...authHeader() })
    .then((res) => {
      console.log("[create new customer]", res.status, res.data);
      const { data } = res;
      if (data) {
        next(null, data);
      }
    })
    .catch((err) => {
      console.warn("[delete new customer] error: ", err.response);
      next(err.response);
    });
};
