// Actions for Load initial state
export const SET_LOADED = "SET_LOADED";
export const ACCEPT_REQUEST_SUCCESS = "ACCEPT_REQUEST_SUCCESS";

// Actions for Load
export const loadedInitState = (payload) => ({
  type: SET_LOADED,
  payload,
});

export const acceptRequestSuccess = (data) => ({
  type: ACCEPT_REQUEST_SUCCESS,
  data,
});
