import React from "react";
// import { Route, Switch, Redirect } from "react-router-dom";
import { Route, Switch, Redirect } from "react-router";
import MainLayout from "./containers/MainLayout";
import FirstPage from "./pages/First";
import SecondPage from "./pages/Second";
import ThirdPage from "./pages/Third";
import Login from "./pages/Auth/login";
import AuthMainLayout from "./containers/AuthMainLayout";
import AdminMainLayout from "./containers/AdminMainLayout";
import Register from "./pages/Auth/register";
import AdminDashboard from "./pages/Admin";
import UserDashboard from "./pages/Admin/user";
import SpecialApplication from "./pages/Admin/specialApplication";

function RouteWrapper({ component: Component, layout: Layout, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

const Router = () => (
  <Switch>
    <Redirect exact path="/" to="/first" />
    <RouteWrapper
      exact
      path="/admin"
      component={AdminDashboard}
      layout={AdminMainLayout}
    />
    <RouteWrapper
      exact
      path="/admin/special"
      component={SpecialApplication}
      layout={AdminMainLayout}
    />
    <RouteWrapper
      exact
      path="/admin/user"
      component={UserDashboard}
      layout={AdminMainLayout}
    />
    <RouteWrapper
      path="/admin/login"
      component={Login}
      layout={AuthMainLayout}
    />
    {/*<RouteWrapper path="/admin/register" component={Register} layout={AuthMainLayout} />*/}
    <RouteWrapper path="/first" component={FirstPage} layout={MainLayout} />
    <RouteWrapper path="/second" component={SecondPage} layout={MainLayout} />
    <RouteWrapper path="/third" component={ThirdPage} layout={MainLayout} />
  </Switch>
);

export default Router;
