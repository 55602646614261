import { SET_LOADED, ACCEPT_REQUEST_SUCCESS } from "../actions/load";

// Reducers for Load
export const loadReducer = (state = {}, action) => {
  if (action.type === SET_LOADED) {
    return { ...state, ...action.payload };
  }
  if (action.type === ACCEPT_REQUEST_SUCCESS) {
    return { ...state, request_months: action.data.request_bank.months };
  }

  return state;
};
