import React, { useState, forwardRef, useImperativeHandle } from "react";
import { FormInput, FormGroup, FormCheckbox, Col, Row } from "shards-react";
import { validateEmail, validatePhone } from "../utils";
import { InputPhone } from "./CustomInputs";
import { asyncValidateEmailAddress, asyncValidatePhoneNumber } from "../api";
import { category, contact_phone } from "../config";
import "./components.css";

const UserInfo = forwardRef(
  (
    {
      valueFirst,
      setValueFirst,
      valueLast,
      setValueLast,
      valueEmail,
      setValueEmail,
      valuePhone,
      setValuePhone,
      enableNext,
      setEnableNext,
    },
    ref,
  ) => {
    const [successFirst, setSuccessFirst] = useState(false);
    const [errorFirst, handleErrorFirst] = useState(false);
    const [successLast, setSuccessLast] = useState(false);
    const [errorLast, handleErrorLast] = useState(false);
    const [successEmail, setSuccessEmail] = useState(false);
    const [errorEmail, handleErrorEmail] = useState(false);
    const [successPhone, setSuccessPhone] = useState(false);
    const [errorPhone, handleErrorPhone] = useState(false);

    const handleChangeFirst = (e) => {
      const val = e.target.value;
      if (typeof setValueFirst === "function") setValueFirst(val);
    };

    const handleChangeLast = (e) => {
      const val = e.target.value;
      if (typeof setValueLast === "function") setValueLast(val);
    };

    const handleChangeEmail = (e) => {
      const val = e.target.value;
      if (typeof setValueEmail === "function") setValueEmail(val);
    };

    const handleChangePhone = (e) => {
      const val = e.target.value;
      if (typeof setValuePhone === "function") setValuePhone(val);
    };

    const validate = async () => {
      if (!enableNext) return false;
      if (!!valueFirst && valueFirst !== "") {
        setSuccessFirst(true);
        handleErrorFirst(false);
      } else {
        setSuccessFirst(false);
        handleErrorFirst(true);
        return false;
      }
      if (!!valueLast && valueLast !== "") {
        setSuccessLast(true);
        handleErrorLast(false);
      } else {
        setSuccessLast(false);
        handleErrorLast(true);
        return false;
      }
      if (validateEmail(valueEmail)) {
        const result = await asyncValidateEmailAddress(valueEmail);
        if (result) {
          setSuccessEmail(true);
          handleErrorEmail(false);
        } else {
          setSuccessEmail(false);
          handleErrorEmail(true);
          return false;
        }
      } else {
        setSuccessEmail(false);
        handleErrorEmail(true);
        return false;
      }
      if (validatePhone(valuePhone)) {
        const result = await asyncValidatePhoneNumber(valuePhone);
        if (result) {
          setSuccessPhone(true);
          handleErrorPhone(false);
        } else {
          setSuccessPhone(false);
          handleErrorPhone(true);
          return false;
        }
      } else {
        setSuccessPhone(false);
        handleErrorPhone(true);
        return false;
      }
      return true;
    };

    useImperativeHandle(ref, () => ({
      validate,
    }));

    return (
      <>
        <Row className="pl-3 pr-3" style={{ marginTop: "-40px" }}>
          <Col sm={12}>
            <h4 className="text-left">
              Lets get your specific funding terms buttoned up.
            </h4>
          </Col>
          <Col sm={12}>
            <p className="text-left">Tell us about yourself.</p>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col sm={6}>
            <FormGroup className="form-group-center">
              <Col>
                <label
                  htmlFor="#first-name"
                  className="custom-lbl-size display-5 text-primary text-left text-shadow--large-1"
                >
                  First Name
                </label>
              </Col>
              <Col>
                <FormInput
                  id="#first-name"
                  placeholder="e.g. John"
                  value={valueFirst || ""}
                  onChange={handleChangeFirst}
                  valid={successFirst}
                  invalid={errorFirst}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup className="form-group-center">
              <Col>
                <label
                  htmlFor="#last-name"
                  className="custom-lbl-size display-5 text-primary text-left text-shadow--large-1"
                >
                  Last Name
                </label>
              </Col>
              <Col>
                <FormInput
                  id="#last-name"
                  placeholder="e.g. Smith"
                  value={valueLast || ""}
                  onChange={handleChangeLast}
                  valid={successLast}
                  invalid={errorLast}
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <FormGroup className="form-group-center">
              <Col>
                <label
                  htmlFor="#email-address"
                  className="custom-lbl-size display-5 text-primary text-left text-shadow--large-1"
                >
                  Email Address
                </label>
              </Col>
              <Col>
                <FormInput
                  id="#email-address"
                  placeholder="e.g. user@email.com"
                  value={valueEmail || ""}
                  onChange={handleChangeEmail}
                  valid={successEmail}
                  invalid={errorEmail}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup className="form-group-center">
              <Col>
                <label
                  htmlFor="#phone-number"
                  className="custom-lbl-size display-5 text-primary text-left text-shadow--large-1"
                >
                  Phone Number
                </label>
              </Col>
              <Col>
                <InputPhone
                  id="#phone-number"
                  value={valuePhone || ""}
                  onChange={handleChangePhone}
                  valid={successPhone}
                  invalid={errorPhone}
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col sm={12}>
            <FormCheckbox
              className="text-left font-size-sm-8"
              checked={enableNext || false}
              onChange={(e) => setEnableNext(!enableNext)}
            >
              By checking this box and selecting Continue the Borrower agrees to
              authorize {category} to contact the Borrower at the telephone,
              cell phone, email or direct mail contact data provided in this
              form for purposes of fulfilling this inquiry about business
              financing, even if the Borrower has previously indicated a
              preference of "do not call" or "do not email" with a government
              registry or with {category}. Also, the Borrower agrees that we may
              deliver a response to the pre-approval request to the email
              address provided. By agreeing to communicate with {category} about
              this pre-approval request by email, the Borrower agrees to retain
              (whether by printing or saving electronically) the communications
              and documents that we email. If the Borrower is unable to print or
              retain the information, or wishes to revoke the agreement to be
              contacted about this qualification inquiry by email, the Borrower
              agrees to call {category} at {contact_phone}.
            </FormCheckbox>
          </Col>
        </Row>
      </>
    );
  },
);

export default UserInfo;
