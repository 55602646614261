import React from "react";
import InputMask from "react-input-mask";
import { FormInput } from "shards-react";
import AutoComplete from "react-google-autocomplete";
import { GOOGLE_APIKEY } from "../config";

/**
 * Custom Input Phone Number with Mask
 * e.g. (999) 999-9999
 * @param {Object} props
 * @returns
 */
export function InputPhone(props) {
  return (
    <InputMask
      mask="(999) 999-9999"
      value={props.value}
      onChange={props.onChange}
    >
      {(inputProps) => (
        <FormInput
          {...inputProps}
          valid={props.valid}
          invalid={props.invalid}
          placeholder={props.placeholder}
          type="tel"
        />
      )}
    </InputMask>
  );
}

/**
 * Custom Input SSN with Mask
 * e.g. 999-99-9999
 * @param {Object} props
 * @returns
 */
export function InputSSN(props) {
  return (
    <InputMask mask="999-99-9999" value={props.value} onChange={props.onChange}>
      {(inputProps) => (
        <FormInput
          {...inputProps}
          valid={props.valid}
          invalid={props.invalid}
          placeholder={props.placeholder}
          type="tel"
        />
      )}
    </InputMask>
  );
}

/**
 * Custom Input Ownership with Mask
 * e.g. 99 %
 * @param {Object} props
 * @returns
 */
export function InputOwnership(props) {
  return (
    <InputMask mask="99 %" value={props.value} onChange={props.onChange}>
      {(inputProps) => (
        <FormInput
          {...inputProps}
          valid={props.valid}
          invalid={props.invalid}
          placeholder={props.placeholder}
          type="tel"
        />
      )}
    </InputMask>
  );
}

/**
 * Custom Input Zip Code with Mask
 * e.g. 99999
 * @param {Object} props
 * @returns
 */
export function InputZipCode(props) {
  return (
    <InputMask mask="99999" value={props.value} onChange={props.onChange}>
      {(inputProps) => (
        <FormInput
          {...inputProps}
          valid={props.valid}
          invalid={props.invalid}
          placeholder={props.placeholder}
          type="tel"
        />
      )}
    </InputMask>
  );
}

/**
 * Custom Input Tax ID with Mask
 * e.g. 99-9999999
 * @param {Object} props
 * @returns
 */
export function InputTaxId(props) {
  return (
    <InputMask mask="99-9999999" value={props.value} onChange={props.onChange}>
      {(inputProps) => (
        <FormInput
          {...inputProps}
          valid={props.valid}
          invalid={props.invalid}
          placeholder={props.placeholder}
          type="tel"
        />
      )}
    </InputMask>
  );
}

/**
 * Custom Input for Auto Address
 * @param {Object} props
 * @returns
 */
export function InputAutoAdress(props) {
  return (
    <AutoComplete
      apiKey={GOOGLE_APIKEY}
      onPlaceSelected={(place) => {
        if (typeof props.onPlaceSelected === "function")
          props.onPlaceSelected(place);
      }}
      className={`form-control ${
        props.invalid ? "is-invalid" : props.valid ? "is-valid" : ""
      }`}
      options={{
        types: ["address"],
        componentRestrictions: { country: ["us"] },
      }}
      value={props.value}
      onChange={props.onChange}
    />
  );
}

/**
 * Custom Input Tax ID with Mask
 * e.g. 99-9999999
 * @param {Object} props
 * @returns
 */
export function InputDateComp(props) {
  return (
    <InputMask mask="99/99/9999" value={props.value} onChange={props.onChange}>
      {(inputProps) => (
        <FormInput
          {...inputProps}
          valid={props.valid}
          invalid={props.invalid}
          placeholder={props.placeholder}
          type="tel"
        />
      )}
    </InputMask>
  );
}
