import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  FormInput,
  Alert,
  FormTextarea,
  Badge,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "shards-react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  fetchSpecialApplications,
  createNewCustomer,
  deleteNewCustomer,
} from "../../api/admin";
import {
  clearApplications,
  setApplications,
  setSpecialApplications,
  clearSpecialApplications,
  updateApplication,
  refreshBankstatementRequest,
  refreshBankstatementSuccess,
  refreshBankstatementFailure,
  clearRefreshBankstatementStatus,
} from "../../actions/admin";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { Search, RefreshOutlined, DeleteOutlined } from "@material-ui/icons";

import Popup from "reactjs-popup";
import { getGTMId } from "../../utils";
// import TagManager from "react-gtm-module";

// getGTMId() && TagManager.initialize({ gtmId: getGTMId() });

const SpecialApplication = (props) => {
  const {
    setSpecialApplications,
    clearSpecialApplications,
    specialApplications,
    refreshDocStatus,
    clearRefreshBankstatementStatus,
  } = props;

  const [apps, setApps] = useState([]);
  const [filteredApp, setFilteredApp] = useState([]);

  const history = useHistory();
  const [createModal, setCreateModal] = useState(false);

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [searchText, setSearchText] = useState("");

  const [valid, setValid] = useState({});
  const [resAlert, setResAlert] = useState({ visible: false });

  const [disabledBtn, setDisabledBtn] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [openPopup, setOpenPopup] = useState(false);
  const itemPerPage = 10;

  useEffect(() => {
    const filteredData = specialApplications.sort((a, b) =>
      a.created_at > b.created_at ? -1 : 1,
    );
    setApps([...filteredData]);
    setFilteredApp([...filteredData]);
  }, [specialApplications]);

  useEffect(() => {
    console.log(refreshDocStatus, "==== refresh doc status");
    if (refreshDocStatus === "success" || refreshDocStatus === "failure") {
      setOpenPopup(true);
      // clearRefreshBankstatementStatus()
    }
  }, [refreshDocStatus]);

  useEffect(() => {
    fetchSpecialApplications(process.env.REACT_APP_DOMAIN, (err, data) => {
      console.log(data, "===== fetch data for special");
      if (err) {
        clearApplications();
        if (err.status === 401) {
          history.push("/admin/login");
        }
      }
      if (data) {
        setSpecialApplications(data);
      }
    });
    if (getGTMId()) {
      console.log(
        "**** Track Second Page ******\n",
        `${document.location.origin}${document.location.pathname}${document.location.hash}${document.location.search}`,
      );
      window.dataLayer.push({
        event: "pageview",
        page: {
          url: `${document.location.origin}${document.location.pathname}${document.location.hash}${document.location.search}`,
          title: `${document.title} - Special Customer Page`,
        },
      });
    }
  }, []);

  useEffect(() => {
    let filteredData = [...apps];
    if (searchText && searchText.length) {
      filteredData = [
        ...filteredData.filter(
          (item) =>
            `${item.firstname} ${item.lastname}`
              .toLowerCase()
              .includes(searchText.toLowerCase()) ||
            item.email.toLowerCase().includes(searchText.toLowerCase()),
        ),
      ];
    }
    setFilteredApp(filteredData);
  }, [searchText, apps]);

  const handleDeleteApplication = (_id) => {
    deleteNewCustomer(_id, (err, data) => {
      if (data) {
        handleRefresh();
      }
    });
  };

  const validCheck = (mode = true) => {
    let valid = true;
    if (firstname.length === 0) {
      setValid((valid) => ({
        ...valid,
        firstname: {
          status: false,
          msg: "Please fill First name",
          visible: true,
        },
      }));
      valid = false;
    } else {
      setValid((valid) => ({ ...valid, firstname: { status: true } }));
    }
    if (lastname.length === 0) {
      setValid((valid) => ({
        ...valid,
        lastname: {
          status: false,
          msg: "Please fill Last name",
          visible: true,
        },
      }));
      valid = false;
    } else {
      setValid((valid) => ({ ...valid, lastname: { status: true } }));
    }

    return valid;
  };

  const handleCreateNewCustomer = () => {
    if (validCheck()) {
      const data = {
        firstname,
        lastname,
        domain: process.env.REACT_APP_DOMAIN,
      };
      setDisabledBtn(true);
      createNewCustomer(data, (err, data) => {
        setDisabledBtn(false);
        if (err) {
          setResAlert({ msg: err.data, visible: true, status: false });
        }
        if (data) {
          setResAlert({
            msg: "Successfully sent request",
            visible: true,
            status: true,
          });
          setCreateModal((requestModal) => !requestModal);
          handleRefresh();
        }
      });
    }
  };

  const handleRefresh = () => {
    fetchSpecialApplications(process.env.REACT_APP_DOMAIN, (err, data) => {
      if (err) {
        clearSpecialApplications();
        if (err.status === 401) {
          history.push("/admin/login");
        }
      }
      if (data) {
        setSpecialApplications(data);
      }
    });
  };

  const handlePageChange = (e) => {
    setCurrentPage(e.selected + 1);
  };

  return (
    <Col>
      <Card>
        <CardHeader className="d-flex justify-content-between">
          <h5 className="text-dark mb-n1">Create New Customer</h5>
          <Button
            size="sm"
            theme="success"
            onClick={() => setCreateModal(true)}
          >
            Create new customer
          </Button>
        </CardHeader>
        <CardBody className="pt-1">
          <div className="ml-auto d-flex justify-content-end my-3">
            <Button
              size="sm"
              theme="success"
              outline
              className="mr-3"
              onClick={handleRefresh}
            >
              <RefreshOutlined />
            </Button>
            <InputGroup size="md" className="w-25">
              <InputGroupAddon type="prepend">
                <InputGroupText>
                  <Search />
                </InputGroupText>
              </InputGroupAddon>
              <FormInput
                placeholder="Search..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </InputGroup>
          </div>
          <table className="table table-striped app-table">
            <thead>
              <tr>
                <th>Username</th>
                <th>Created At</th>
                <th>Link</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredApp.length ? (
                filteredApp
                  .filter(
                    (item, idx) =>
                      idx >= itemPerPage * (currentPage - 1) &&
                      idx < itemPerPage * currentPage,
                  )
                  .map((application, idx) => (
                    <tr key={idx}>
                      <td width="20%">{`${application.firstname} ${application.lastname}`}</td>
                      <td width="20%">{`${moment(application.created_at).format(
                        "MM/DD/YY",
                      )}`}</td>
                      <td width="45%">{`${application.link}`}</td>
                      <td width="15%">
                        <Button
                          theme="danger"
                          onClick={() => {
                            handleDeleteApplication(application.id);
                          }}
                          outline
                          size="sm"
                          className="mb-2"
                          disabled={refreshDocStatus === "request"}
                        >
                          <DeleteOutlined />
                        </Button>
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colspan={7}>
                    <div className="d-flex justify-content-center py-5">
                      {apps.length === 0
                        ? "Loading applications..."
                        : "No application"}
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="d-flex justify-content-between mt-5">
            <div>
              {`Showing ${
                itemPerPage * (currentPage - 1) + 1 > filteredApp.length
                  ? filteredApp.length
                  : itemPerPage * (currentPage - 1) + 1
              } - ${
                itemPerPage * currentPage > filteredApp.length
                  ? filteredApp.length
                  : itemPerPage * currentPage
              } from ${filteredApp.length} Results`}
            </div>
            <ReactPaginate
              pageCount={(filteredApp?.length ?? 0) / itemPerPage}
              renderOnZeroPageCount={null}
              containerClassName="pagination"
              previousLabel="< Prev"
              nextLabel="Next >"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageChange}
              activeClassName="active"
              style={{ fontFamily: "Roboto" }}
            />
          </div>
        </CardBody>
      </Card>
      <Popup
        open={openPopup}
        closeOnDocumentClick
        onClose={() => {
          setOpenPopup(false);
          clearRefreshBankstatementStatus();
        }}
        position="right center"
      >
        <div className="mx-3 my-4">
          <a
            className="close"
            onClick={() => {
              setOpenPopup(false);
              clearRefreshBankstatementStatus();
            }}
          >
            &times;
          </a>
          <div className="text-center">
            {refreshDocStatus === "success"
              ? "Successfully requested to get latest bank statement document. It may take time to fetch."
              : "Failed to request to get latest bank statement document. Please retry."}
          </div>
        </div>
      </Popup>
      <Modal
        open={createModal}
        toggle={() => setCreateModal((requestModal) => !requestModal)}
      >
        <ModalHeader>Create New Customer</ModalHeader>
        <ModalBody>
          {resAlert && resAlert.visible && (
            <Alert
              className="mb-3"
              dismissible={() =>
                setResAlert((resAlert) => ({
                  ...resAlert,
                  visible: false,
                }))
              }
              theme={resAlert.status ? "success" : "danger"}
            >
              {resAlert.msg}
            </Alert>
          )}
          {Object.keys(valid)
            .filter((key) => !valid[key].status && valid[key].visible)
            .filter((key, idx) => idx === 0)
            .map((key) => (
              <Alert
                className="mb-3"
                dismissible={() =>
                  setValid((valid) => ({
                    ...valid,
                    [key]: { ...valid[key], visible: false },
                  }))
                }
                theme="warning"
              >
                {valid[key].msg}
              </Alert>
            ))}
          <Form>
            <FormGroup className="mb-3">
              <label htmlFor="firstname">First Name</label>
              <FormInput
                id="firstname"
                placeholder="First Name"
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
                valid={valid.firstname && valid.firstname.status}
                invalid={valid.firstname && !valid.firstname.status}
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <label htmlFor="request_months">Last Name</label>
              <FormInput
                id="lastname"
                placeholder="Last Name"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
                valid={valid.lastname && valid.lastname.status}
                invalid={valid.lastname && !valid.lastname.status}
              />
            </FormGroup>
            <div className="d-flex justify-content-around">
              <Button
                theme="success"
                onClick={handleCreateNewCustomer}
                disabled={disabledBtn}
              >
                {disabledBtn ? "Creating..." : "Create"}
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </Col>
  );
};

function mapStateToProps(state) {
  const { applications, refreshDocStatus, specialApplications } = state.admin;

  return {
    applications,
    refreshDocStatus,
    specialApplications,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setSpecialApplications: (applications) =>
      dispatch(setSpecialApplications(applications)),
    clearSpecialApplications: () => dispatch(clearSpecialApplications()),
    // updateApplication: (data) => dispatch(updateApplication(data)),
    refreshBankstatementRequest: () => dispatch(refreshBankstatementRequest()),
    refreshBankstatementSuccess: () => dispatch(refreshBankstatementSuccess()),
    refreshBankstatementFailure: () => dispatch(refreshBankstatementFailure()),
    clearRefreshBankstatementStatus: () =>
      dispatch(clearRefreshBankstatementStatus()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SpecialApplication);
