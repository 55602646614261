import React, { useState, forwardRef, useImperativeHandle } from "react";
import { FormInput, FormGroup, Col, Row } from "shards-react";
import { Select, FormControl, MenuItem } from "@material-ui/core";

import useStyles from "./styles";
import "./components.css";

const AvarageMonthly = forwardRef(
  (
    {
      value,
      setValue,
      btnRow,
      valueFirst,
      setValueFirst,
      valueLast,
      setValueLast,
      valueEmail,
      setValueEmail,
    },
    ref,
  ) => {
    const avgMoney = [
      "Under $10,000",
      "$10,000-$25,000",
      "$25,000-$50,000",
      "$50,000-$100,000",
      "$100,000-$250,000",
      "$250,000+",
    ];

    const classes = useStyles();

    const [error, handleError] = useState(false);

    const handleChange = (e) => {
      const val = e.target.value;
      if (typeof setValue === "function") setValue(val);
    };

    const validate = async () => {
      if (!!value) {
        handleError(false);
        return true;
      } else {
        handleError(true);
        return false;
      }
    };

    useImperativeHandle(ref, () => ({
      validate,
    }));

    return (
      <>
        <FormGroup className="form-group-center">
          <Col>
            <label
              htmlFor="#avarage-monthly"
              className="custom-lbl-size display-5 text-primary text-left text-shadow--large-1"
            >
              What is your average monthly revenue?
            </label>
          </Col>

          <Col className="col-lg-6 offset-lg-3">
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                className={classes.customFullWidth}
                value={value || ""}
                onChange={handleChange}
                error={error}
              >
                {avgMoney.map((m, i) => (
                  <MenuItem key={i} value={m}>
                    {m}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Col>
        </FormGroup>
        {btnRow}
      </>
    );
  },
);

export default AvarageMonthly;
