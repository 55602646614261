import React from "react";
import { Container, Row, Navbar, NavbarBrand, Nav, Col } from "shards-react";
import { Phone } from "@material-ui/icons";
import {
  IS_CAPITALIZED,
  light_logo,
  copy_right,
  link_logo,
  contact_phone,
  IS_NORTH,
  IS_BIZBOOST,
  IS_HORIZON,
  dark_logo
} from "../config";
import "./shards-pro.css";
import "./layout.css";
import moment from "moment";
import { connect } from "react-redux";

import Review01 from "../images/review/review_01.png";
import Review02 from "../images/review/review_02.png";
import Review03 from "../images/review/review_03.png";
import Review04 from "../images/review/review_04.png";
import ReviewTitle from "../images/review/review_title.png";

const MainLayout = ({ children, domain, partner }) => {
  return (
    <>
      <div
        className={`shards-hero shards-hero-s5--5 shards-overlay shards-overlay--black shards-overlay--opacity-7 
            custom-main-layout background-${process.env.REACT_APP_DOMAIN}`}
      >
        <div className="shards-hero__header main-header">
          <Container>
            <Row>
              <Navbar
                type="dark"
                expand="md"
                className={`navbar navbar-expand-lg shards-navbar shards-navbar--slide 
                                navbar-dark shards-navbar--slide-visible`}
              >
                <NavbarBrand
                  className={`navbar-brand ${
                    IS_HORIZON ? "" : "mb-4"
                  } header-logo-wrapper d-flex`}
                  href={link_logo}
                >
                  <img
                    className="navbar-brand__image"
                    style={{ maxHeight: IS_HORIZON ? "4rem" : "2rem" }}
                    src={dark_logo}
                    alt="logo"
                  />
                </NavbarBrand>
                <Nav
                  navbar
                  className={`phone-wrapper ${IS_HORIZON ? "" : "mb-4"}`}
                >
                  <a className="telephone" href={`tel:${contact_phone}`}>
                    <Phone /> {contact_phone}
                  </a>
                </Nav>
              </Navbar>
            </Row>
          </Container>
        </div>
        <div className="shards-hero__content custom-main-content">
          <Container>
            <Row className="justify-content-center">
              <div className="content-wrapper col-lg-10 text-center ">
                {children}
              </div>
            </Row>
          </Container>
        </div>

        <div className="container">
          <Row className="px-0">
            <Col md={12} className="text-center mt-5">
              <img src={ReviewTitle} alt="customer title" width="200px" />
            </Col>
            <Col md={6} className="text-center mt-4">
              <img src={Review01} alt="review 01" className="img-fluid" />
            </Col>
            <Col md={6} className="text-center mt-4">
              <img src={Review02} alt="review 02" className="img-fluid" />
            </Col>
            <Col md={6} className="text-center mt-4">
              <img src={Review04} alt="review 04" className="img-fluid" />
            </Col>
            <Col md={6} className="text-center mt-4">
              <img src={Review03} alt="review 03" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <nav
          className="shards-footer shards-footer--2 navbar navbar-expand-md shards-navbar shards-navbar--responsive
                 navbar-dark py-5 custom-main-bottom"
        >
          <div className="container">
            <div className="navbar__content-left mb-4 mb-md-0 text-center">
              <div className="text-white opacity-6">
                <small>
                  &copy; {moment().format("YYYY")} {copy_right}, All rights
                  Reserved
                </small>
              </div>
              <div className="ml-auto">
                <small>
                  <a
                    href={`${link_logo}privacy-policy`}
                    className="text-white opacity-6"
                  >
                    Privacy Policy
                  </a>
                  <a
                    href={`${link_logo}${
                      IS_BIZBOOST ? "terms-conditions" : "terms-of-service"
                    }`}
                    className="ml-3 text-white opacity-6"
                  >
                    Terms & Conditions
                  </a>
                </small>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  const { domain, partner } = state.form;

  return {
    domain,
    partner,
  };
}

export default connect(mapStateToProps, undefined)(MainLayout);
