// Actions for Plaid tokens
export const SET_PUBLIC_TOKEN = "SET_PUBLIC_TOKEN";
export const SET_LINK_TOKEN = "SET_LINK_TOKEN";

export const FETCH_ASSET_REPORT_ID_REQUEST = "FETCH_ASSET_REPORT_ID_REQUEST";
export const FETCH_ASSET_REPORT_ID_SUCCESS = "FETCH_ASSET_REPORT_ID_SUCCESS";
export const FETCH_ASSET_REPORT_ID_FAILURE = "FETCH_ASSET_REPORT_ID_FAILURE";

export const UPLOAD_ASSET_REPORT_REQUEST = "UPLOAD_ASSET_REPORT_REQUEST";
export const UPLOAD_ASSET_REPORT_SUCCESS = "UPLOAD_ASSET_REPORT_SUCCESS";
export const UPLOAD_ASSET_REPORT_FAILURE = "UPLOAD_ASSET_REPORT_FAILURE";

export const FETCH_TRANSACTION_REQUEST = "FETCH_TRANSACTION_REQUEST";
export const FETCH_TRANSACTION_SUCCESS = "FETCH_TRANSACTION_SUCCESS";
export const FETCH_TRANSACTION_FAILURE = "FETCH_TRANSACTION_FAILURE";

export const SET_MANUAL_OPTION = "SET_MANUAL_OPTION";

export const setLinkTokenAction = (linkToken) => ({
  type: SET_LINK_TOKEN,
  payload: { linkToken },
});

export const setPublicTokenAction = (publicToken) => ({
  type: SET_PUBLIC_TOKEN,
  payload: { publicToken },
});

export const fetchAssetReportIdRequest = () => ({
  type: FETCH_ASSET_REPORT_ID_REQUEST,
});

export const fetchAssetReportIdSuccess = (payload) => ({
  type: FETCH_ASSET_REPORT_ID_SUCCESS,
  payload,
});

export const fetchAssetReportIdFailure = (payload) => ({
  type: FETCH_ASSET_REPORT_ID_FAILURE,
  payload,
});

export const uploadAssetReportRequest = (payload) => ({
  type: UPLOAD_ASSET_REPORT_REQUEST,
  payload,
});

export const uploadAssetReportSuccess = (payload) => ({
  type: UPLOAD_ASSET_REPORT_SUCCESS,
  payload,
});

export const uploadAssetReportFailure = (payload) => ({
  type: UPLOAD_ASSET_REPORT_FAILURE,
  payload,
});

export const setManualOption = () => ({
  type: SET_MANUAL_OPTION,
});

export const fetchTransactionSuccess = (payload) => ({
  type: FETCH_TRANSACTION_SUCCESS,
  payload,
});

export const fetchTransactionFailure = (payload) => ({
  type: FETCH_TRANSACTION_FAILURE,
  payload,
});

export const fetchTransactionRequest = (payload) => ({
  type: FETCH_TRANSACTION_REQUEST,
  payload,
});
