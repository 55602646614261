import React, { useState } from "react";
import { Col, FormCheckbox } from "shards-react";
import { category, dark_logo, IS_CAPITALIZED } from "../config";

const Finish = ({ isSecond = false }) => {
  return (
    <>
      {isSecond ? (
        <>
          <h3 className="custom-lbl-size display-5 text-primary text-left text-shadow--large-1">
            Thank you for completing our application. Just click the button
            below and you’ll be taken to the funding source that you match with.
          </h3>
        </>
      ) : (
        <>
          <img
            className="navbar-brand__image"
            src={dark_logo}
            alt={category}
            style={{
              width: "250px",
              height: "auto",
              padding: "20px 20px 50px",
              marginRight: IS_CAPITALIZED ? 40 : 0,
            }}
          />
          <h1 className="display-4 text-primary text-shadow--large-1 mb-5">
            Great Job!
          </h1>
          <p>
            Our Funding Specialists are working on your funding terms and will
            touch base shortly.
          </p>
          {/*<Col className="ml-auto mr-auto custom-w-auto">*/}
          {/*    <div className="text-left mt-5 mb-2">*/}
          {/*        We offer more than just business financing. */}
          {/*    </div>*/}
          {/*    <div className="text-left mb-2">*/}
          {/*        What else would you like to hear about?*/}
          {/*    </div>*/}
          {/*    {Object.keys(otherServiceData).map((key, idx) => (*/}
          {/*        <div key={idx}>*/}
          {/*            <FormCheckbox*/}
          {/*                className="text-left"*/}
          {/*                checked={!!otherservice[key]}*/}
          {/*                onChange={() => setOtherService(key)}*/}
          {/*            >*/}
          {/*                {otherServiceData[key]}*/}
          {/*            </FormCheckbox>*/}
          {/*        </div>*/}
          {/*    ))}*/}
          {/*    /!*<div>*!/*/}
          {/*    /!*    <FormCheckbox*!/*/}
          {/*    /!*        className="text-left"*!/*/}
          {/*    /!*        checked={!!otherservice.creditcards}*!/*/}
          {/*    /!*        onChange={() => setOtherService('creditcards')}*!/*/}
          {/*    /!*        // onChange={e => setChecked({...checked, 'creditcards': !checked.creditcards})}*!/*/}
          {/*    /!*    >*!/*/}
          {/*    /!*        Business credit cards*!/*/}
          {/*    /!*    </FormCheckbox>*!/*/}
          {/*    /!*</div>*!/*/}
          {/*    /!*<div>*!/*/}
          {/*    /!*    <FormCheckbox*!/*/}
          {/*    /!*        className="text-left"*!/*/}
          {/*    /!*        checked={!!otherservice.loans}*!/*/}
          {/*    /!*        onChange={() => setOtherService('loans')}*!/*/}
          {/*    /!*        // onChange={e => setChecked({...checked, 'loans': !checked.loans})}*!/*/}
          {/*    /!*    >*!/*/}
          {/*    /!*        Personal loans*!/*/}
          {/*    /!*    </FormCheckbox>*!/*/}
          {/*    /!*</div>*!/*/}
          {/*    /!*<div>*!/*/}
          {/*    /!*    <FormCheckbox*!/*/}
          {/*    /!*        className="text-left"*!/*/}
          {/*    /!*        checked={!!otherservice.bookkeeping}*!/*/}
          {/*    /!*        onChange={() => setOtherService('bookkeeping')}*!/*/}
          {/*    /!*        // onChange={e => setChecked({...checked, 'bookkeeping': !checked.bookkeeping})}*!/*/}
          {/*    /!*    >*!/*/}
          {/*    /!*        Bookkeeping*!/*/}
          {/*    /!*    </FormCheckbox>*!/*/}
          {/*    /!*</div>*!/*/}
          {/*    /!*<div>*!/*/}
          {/*    /!*    <FormCheckbox*!/*/}
          {/*    /!*        className="text-left"*!/*/}
          {/*    /!*        checked={!!otherservice.support}*!/*/}
          {/*    /!*        onChange={() => setOtherService('support')}*!/*/}
          {/*    /!*        // onChange={e => setChecked({...checked, 'support': !checked.support})}*!/*/}
          {/*    /!*    >*!/*/}
          {/*    /!*        HR support *!/*/}
          {/*    /!*    </FormCheckbox>*!/*/}
          {/*    /!*</div>*!/*/}
          {/*    /!*<div>*!/*/}
          {/*    /!*    <FormCheckbox *!/*/}
          {/*    /!*        className="text-left"*!/*/}
          {/*    /!*        checked={!!checked.processing}*!/*/}
          {/*    /!*        onChange={e => setChecked({...checked, 'processing': !checked.processing})}*!/*/}
          {/*    /!*    >*!/*/}
          {/*    /!*        Credit card processing*!/*/}
          {/*    /!*    </FormCheckbox>*!/*/}
          {/*    /!*</div>*!/*/}
          {/*</Col>*/}
        </>
      )}
    </>
  );
};

export default Finish;
