import { API_ENDPOINT } from "../config";
import axios from "axios";

/**
 * user login
 * @param {{password: string, email: string}} data
 * @param {Function} next
 */
export const userLogin = (data, next) => {
  const url = API_ENDPOINT + "/login";

  axios
    .post(url, data)
    .then((res) => {
      console.log("[Login]", res.status, res.data);
      const { data } = res;
      if (data) {
        next(null, data);
      }
    })
    .catch((err) => {
      console.warn("[Login] error: ", err.response);
      next(err.response);
    });
};

/**
 * user register
 * @param {String} data
 * @param {Function} next
 */
export const userRegister = (data, next) => {
  const url = API_ENDPOINT + "/register";

  axios
    .post(url, data)
    .then((res) => {
      console.log("[Register]", res.status, res.data);
      const { data } = res;
      if (data) {
        next(null, data);
      }
    })
    .catch((err) => {
      console.warn("[Register] error: ", err.response);
      next(err.response);
    });
};
