import React from "react";
import { HashRouter, BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import "bootstrap/dist/css/bootstrap.min.css";
import "shards-ui/dist/css/shards.min.css";
import "./App.css";
import Router from "./routes";
import theme from "./containers/theme";
import "reactjs-popup/dist/index.css";
import Sockets from "./utils/Sockets";

import TagManager from "react-gtm-module";
import { getGTMId } from "./utils";

getGTMId() && TagManager.initialize({ gtmId: getGTMId() });

function App() {
  return (
    <ThemeProvider theme={theme}>
      <HashRouter>
        <Router />
      </HashRouter>
      <Sockets />
    </ThemeProvider>
  );
}

export default App;
