import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from "react";
import {
  Card,
  Col,
  Progress,
  FormRadio,
  Popover,
  PopoverBody,
  PopoverHeader,
  Alert,
} from "shards-react";
import { DropzoneArea } from "material-ui-dropzone";
import { asyncSaveBankStatement, asyncUploadFile } from "../api";
import Plaid from "./Plaid";
import { IS_CAPITALIZED, IS_NORTH } from "../config";
// import {connect} from 'react-redux'

const label = IS_NORTH ? "North" : IS_CAPITALIZED ? "Capitalized" : "Horizon";

const Bank = forwardRef(
  (
    {
      values,
      value,
      setValue,
      setValueFilePath,
      enableNext,
      setEnableNext,
      setUploadedFilePath,
      plaidState,
      setStep,
      request_months,
    },
    ref,
  ) => {
    const [, setSuccess] = useState(false);
    const [, handleError] = useState(false);
    const [uploaded, setUploaded] = useState({});
    const [progress, setProgress] = useState(0);
    const [mode, setMode] = useState("plaid");
    const [isOver, setIsOver] = useState(false);
    const [isManualOver, setIsManualOver] = useState(false);
    const [showAlert, setShowAlert] = useState(!!request_months);

    const handleChange = (files) => {
      if (typeof setValue === "function") setValue(files);
      setEnableNext(true);
    };

    useEffect(() => {
      if (mode === "plaid" && plaidState.uploadingStatus === 2) {
        setEnableNext(true);
        setStep((step) => step + 1);
      }
    }, [plaidState]);

    useEffect(() => {
      if (plaidState.isManual) {
        setMode("manual");
      }
    }, [plaidState.isManual]);

    useEffect(() => {
      setEnableNext(false);
      setUploaded({});
      setProgress(0);
    }, [mode]);

    const validate = async () => {
      let results = [];
      if (mode === "manual") {
        if (!!value && value.length > 0) {
          let enableToNext = true;

          setEnableNext(false);
          console.log("[Bank] uploaded", uploaded);

          for (let i in value) {
            const file_key = `${value[i].path}-${value[i].name}-${value[i].lastModified}`;
            if (!!uploaded[file_key]) {
              results.push(uploaded[file_key]);
              continue;
            }
            // enableToNext = false;
            setProgress(0);
            const result = await asyncUploadFile(values, value[i], (event) => {
              setProgress(Math.round((100 * event.loaded) / event.total));
            });
            if (!result) enableToNext = false;
            // results.push(result);
            results.push({
              name: result.name,
              sharedUrl: result.url,
              path: result.path_lower,
            });
            // if (typeof setValueFilePath === 'function') setValueFilePath(results.join(', '));
            console.log("[Bank] file_path", result.url);
            setUploaded({ ...uploaded, [file_key]: result.url });
          }

          await asyncSaveBankStatement(values._id, results);

          setEnableNext(true);

          if (enableToNext) {
            setSuccess(true);
            handleError(false);
            if (typeof setValueFilePath === "function")
              setValueFilePath(results.join(", "));
          } else {
            setSuccess(false);
            handleError(true);
            return false;
          }
        } else {
          setSuccess(false);
          handleError(true);
          return false;
        }
        return { mode: "manual", data: results.join(", ") };
      } else {
        return { mode: "plaid" };
        // return JSON.stringify(plaidState.uploadResult)
      }
    };

    useImperativeHandle(ref, () => ({
      validate,
    }));

    return (
      <>
        <h4 className="text-left text-shadow--large-1 mb-2 pl-2">
          {plaidState.isManual
            ? "We are sorry you are having trouble logging into your bank account, please try to upload your bank statements manually."
            : plaidState.uploadingStatus === 1 &&
              plaidState.currentAssetReportId.length
            ? ""
            : "Thank you for completing your application. You only have one step left."}
        </h4>
        {request_months && (
          <Alert
            className="mb-3"
            open={showAlert}
            dismissible={() => setShowAlert((showAlert) => !showAlert)}
            theme="info"
          >
            Request bank statements for recent {request_months}months
          </Alert>
        )}
        {!plaidState.isManual ? (
          plaidState.uploadingStatus === 1 &&
          plaidState.currentAssetReportId.length ? (
            <p className="text-left pl-2">
              Please stand by a moment while Plaid completes the electronic bank
              link. We greatly appreciate your patience!
            </p>
          ) : (
            <>
              <p className="text-left pl-2">
                In order to validate the funding approval, we need to review
                your most recent bank statements. Please complete the validation
                below and your bank statements will automatically be sent to us.
              </p>
              <p className="text-left pl-2">
                This is your primary business bank account that we will be
                depositing funds into.
              </p>
            </>
          )
        ) : (
          ""
        )}
        <div className="d-flex align-items-center my-3 px-3">
          <div
            id="secure-popover"
            onMouseOver={() => setIsOver(true)}
            onMouseLeave={() => setIsOver(false)}
          >
            <FormRadio
              name="mode"
              checked={mode === "plaid"}
              onChange={() => setMode("plaid")}
            >
              Secure Bank Validation
            </FormRadio>
          </div>

          <div
            id="manual-popover"
            onMouseOver={() => setIsManualOver(true)}
            onMouseLeave={() => setIsManualOver(false)}
            className="ml-3 pl-2"
          >
            <FormRadio
              name="mode"
              checked={mode === "manual"}
              onChange={() => setMode("manual")}
            >
              Secure Bank statement Upload
            </FormRadio>
          </div>

          <Popover
            placement="left"
            open={isOver}
            toggle={() => setIsOver((isOver) => !isOver)}
            target="#secure-popover"
          >
            <PopoverHeader>Secure Bank Validation</PopoverHeader>
            <PopoverBody>
              {label} uses Plaid to conveniently link your bank account in a
              secure end-to-end data encryption environment. Plaid uses the
              highest levels of security possible to keep your information safe.
              Plaid will also never share your login and password info with the
              linked financial application or {label}. Plaid is connected to
              over 10,000 banks worldwide. If your bank is not one of those,
              please use our drag and drop upload feature for your bank
              statements. We appreciate your business and privacy.
            </PopoverBody>
          </Popover>
          {plaidState.isManual && (
            <Popover
              placement="right"
              open={isManualOver}
              toggle={() => setIsManualOver((isManualOver) => !isManualOver)}
              target="#manual-popover"
            >
              <PopoverHeader>Secure Bank statement Upload</PopoverHeader>
              <PopoverBody>
                {label} uses drag-and-drop technology to conveniently upload
                your bank statements in a secure end-to-end data encrypted
                environment. {label} uses the highest levels of security
                possible to keep your information safe. Files and folders can be
                uploaded to our secure {label} underwriting department by
                placing the cursor over the icon of an item, pressing the left
                (or sometimes right) mouse button to highlight it, and while
                keeping the button depressed, moving the selected item across
                the screen to the upload field. We appreciate your business and
                privacy.
              </PopoverBody>
            </Popover>
          )}
        </div>
        {mode === "plaid" && <Plaid />}
        {mode === "manual" && (
          <div>
            <Col sm={12}>
              {/* <ul className="text-left">
                            <li>Not required if you connect your bank account</li>
                            <li>Last 3 months</li>
                        </ul> */}
              <p className="text-left">
                Upload your {request_months} most recent bank statements pdf
                files below to complete your application.
              </p>
            </Col>
            <Col>
              <DropzoneArea
                acceptedFiles={["application/pdf"]}
                dropzoneText={
                  "Drag and Drop your Bank Statement here or Click to upload"
                }
                // dropzoneText={"Upload your three most recent bank statements pdf or image files below to complete your application."}
                onChange={handleChange}
                initialFiles={value}
              />
            </Col>
            <Col>
              <Progress theme="primary" value={progress} className="mt-2">
                {progress}%
              </Progress>
            </Col>
          </div>
        )}
      </>
    );
  },
);

// function mapStateToProps(state) {
//     const {form, plaid} = state;
//
//     return {
//         formState: form, plaidState: plaid
//     }
// }
//
// function mapDispatchToProps(dispatch) {
//     return {}
// }
//
// export default connect(mapStateToProps, mapDispatchToProps)(Bank);
export default Bank;
