import React, { useEffect } from "react";
import {
  Container,
  Row,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "shards-react";
import { useLocation, useHistory } from "react-router-dom";
import { IS_NORTH, light_logo, link_logo } from "../config";
import "./shards-pro.css";
import "./layout.css";
import { userVerify } from "../utils";
import BG_IMAGE from "../images/bg-image.jpg";
import LightLogo from "../images/logo/logo-transparent-white.png";

const AdminMainLayout = ({ children }) => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (!userVerify()) {
      history.push("/admin/login");
    }
  }, []);

  return (
    <>
      <div
        className={`shards-hero shards-hero-s5--5 shards-overlay shards-overlay--black shards-overlay--opacity-7 
            custom-main-layout background-${process.env.REACT_APP_DOMAIN}`}
      >
        <div className="shards-hero__header">
          <div className="bg-container">
            <img src={BG_IMAGE} alt="background-green" />
          </div>
          <Container>
            <Row>
              <Navbar
                type="light"
                expand="md"
                className="navbar navbar-expand-lg shards-navbar shards-navbar--slide
                                navbar-dark shards-navbar--slide-visible"
              >
                <NavbarBrand
                  className={`navbar-brand header-logo-wrapper`}
                  href={link_logo}
                >
                  <img
                    className="navbar-brand__image"
                    style={{ maxHeight: IS_NORTH ? "4rem" : "2rem" }}
                    src={LightLogo}
                    alt="logo"
                  />
                </NavbarBrand>
                <Nav navbar>
                  <NavItem>
                    <NavLink
                      active={location.pathname === "/admin"}
                      href="#/admin"
                    >
                      Request Bank statement
                    </NavLink>
                  </NavItem>
                  {/*<NavItem>*/}
                  {/*  <NavLink active={location.pathname === '/admin/special'} href="#/admin/special">*/}
                  {/*    New Customers*/}
                  {/*  </NavLink>*/}
                  {/*</NavItem>*/}
                  {/*<NavItem>*/}
                  {/*  <NavLink active={location.pathname === '/admin/user'} href="#/admin/user">*/}
                  {/*    Approve User*/}
                  {/*  </NavLink>*/}
                  {/*</NavItem>*/}
                </Nav>
                <Nav navbar className="ml-auto">
                  <NavItem>
                    <NavLink href="#/admin/login">Log out</NavLink>
                  </NavItem>
                </Nav>
              </Navbar>
            </Row>
          </Container>
        </div>
        <div className="shards-hero__content mt-1">
          <Container>
            <Row>
              <div className="content-wrapper col-lg-12">{children}</div>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default AdminMainLayout;
