import { Months } from "../data/months";
import moment from "moment";
import {
  BIZBOOST_ENDPOINT,
  BIZBOOST_GTM,
  CAPITALIZED_GTM,
  HORIZON_GTM,
  IS_BIZBOOST,
  IS_CAPITALIZED,
  IS_HORIZON,
  IS_NORTH,
  NORTHEAST_GTM,
} from "../config";

const publicIp = require("public-ip");

/**
 * get money
 * e.g. "$ 12,345"
 * @param {*} val
 * @returns {String}
 */
export const getMoney = (val) => {
  const maxLength = 10;
  let result = "";
  try {
    if (!!val) {
      if (typeof val !== "string") val = val.toString();
      if (val[0] === "$") val = val.slice(1);
      val = val.replace(/\s/g, "").replace(/,/g, "");
      const num = parseInt(val);
      if (!isNaN(num) && num > 0) {
        let str = num.toString();
        if (str.length > maxLength) str = str.slice(0, maxLength);
        for (let i = str.length - 1, j = 0; i >= 0; i--, j++) {
          if (j > 0 && j % 3 === 0) result = "," + result;
          result = str[i] + result;
        }
        result = "$ " + result;
      }
    }
  } catch (e) {
    console.warn(e);
  }
  // console.log("[getMoney]", val, result);
  return result;
};

/**
 * check level to go next page
 * If business over 12 months, and monthly revenue over $20k, return true.
 * Else, return false.
 * @param {Object} val
 * @returns {Boolean}
 */
export const checkLevel = (val) => {
  const { startyear, startmonth, avarage, credit } = val;
  const monthInt = Months.findIndex((m) => m === startmonth);
  const startDate = new Date(parseInt(startyear), monthInt, 1);
  const diff = new Date().valueOf() - startDate.valueOf();
  const avarageInt = parseInt(avarage.replace(/\D/g, ""));

  // console.log("[checkLevel]", val, avarageInt, startDate, diff, diff >= (365 * 24 * 3600 * 1000));
  return avarageInt >= 10000;
};

/**
 * validate email address
 * @param {String} val
 * @returns {Boolean}
 */
export const validateEmail = (val) => {
  if (!val || typeof val !== "string") return false;
  const re =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return re.test(val);
};

/**
 * validate phone number
 * e.g. (999) 999-9999
 * @param {String} val
 * @returns {Boolean}
 */
export const validatePhone = (val) => {
  if (!val || typeof val !== "string") return false;
  const re = /\([\d]{3}\) [\d]{3}-[\d]{4}/;
  return re.test(val);
};

/**
 * validate SSN
 * e.g. 999-99-99999
 * @param {String} val
 * @returns {Boolean}
 */
export const validateSSN = (val) => {
  if (!val || typeof val !== "string") return false;
  const re = /[\d]{3}-[\d]{2}-[\d]{4}/;
  return re.test(val);
};

/**
 * validate Ownership
 * e.g. 99 %
 * @param {String} val
 * @returns {Boolean}
 */
export const validateOwnership = (val) => {
  if (!val || typeof val !== "string") return false;
  const re = /[\d]{2} %/;
  return re.test(val);
};

/**
 * validate Zip Code
 * e.g. 99999
 * @param {String} val
 * @returns {Boolean}
 */
export const validateZipCode = (val) => {
  if (!val || typeof val !== "string") return false;
  const re = /[\d]{5}/;
  return re.test(val);
};

/**
 * validate Tax ID
 * e.g. 99-9999999
 * @param {String} val
 * @returns {Boolean}
 */
export const validateTaxId = (val) => {
  if (!val || typeof val !== "string") return false;
  const re = /[\d]{2}-[\d]{7}/;
  return re.test(val);
};

/**
 * validate Tax ID
 * e.g. 0-1000
 * @param {String} val
 * @returns {Boolean}
 */
export const validCreditScore = (val) => {
  if (!val || typeof val !== "string") return false;
  const re = /\d+/;
  return re.test(val);
};

export const validYear = (val) => {
  if (!val || typeof val !== "string") return false;
  const currentYear = parseInt(moment().format("YYYY"));
  const re = /[\d]{4}/;
  const fVal = parseFloat(val);
  return re.test(val) && fVal < currentYear;
};

/**
 * get percent
 * e.g. "99 %"
 * @param {*} val
 * @returns {String}
 */
export const getPercent = (val) => {
  let result = "";
  try {
    if (!!val) {
      if (typeof val !== "string") val = val.toString();
      if (val[val.length - 1] === "%") val = val.slice(0, -1);
      val = val.replace(/\s/g, "");
      const num = parseFloat(val);
      if (!isNaN(num) && num > 0) {
        result = num.toString() + " %";
      }
    }
  } catch (e) {
    // console.warn(e);
  }
  return result;
};

/**
 * validate percent
 * e.g. 99.9
 * @param {String} val
 * @returns {Boolean}
 */
export const validatePercent = (val) => {
  const num = parseFloat(val);
  if (!val || isNaN(num)) return false;
  return val >= 0 && val <= 100;
};

/**
 * get address info with state, city, formatted address
 * e.g. {state: 'MD', city: 'Ballenger', address: ''}
 * @param {Object} val
 * @returns {Object}
 */
export const getAddressInfo = (val) => {
  let result = {
    state: "",
    city: "",
    address: "",
    country: "",
    address1: "",
    postcode: "",
    locality: "",
  };
  try {
    if (!!val && typeof val === "object") {
      if ("address_components" in val && val["address_components"].length > 2) {
        for (const component of val["address_components"]) {
          const componentType = component.types[0];

          switch (componentType) {
            case "street_number": {
              result.address1 = `${component.long_name} ${result.address1}`;
              break;
            }

            case "route": {
              result.address1 += component.short_name;
              break;
            }

            case "postal_code": {
              result.postcode = `${component.long_name}${result.postcode}`;
              break;
            }

            case "postal_code_suffix": {
              result.postcode = `${result.postcode}-${component.long_name}`;
              break;
            }
            case "locality":
              result.locality = component.long_name;
              break;

            case "administrative_area_level_1": {
              result.state = component.short_name;
              break;
            }
            case "country":
              result.country = component.long_name;
              break;
            default:
              break;
          }
        }
      } else if ("formatted_address" in val) {
        let temp = val["formatted_address"].split(",");
        if (temp.length > 2) {
          result.state = temp[temp.length - 2].trim();
          result.city = temp[temp.length - 3].trim();
        }
      }
      if ("formatted_address" in val) {
        result.address = val["formatted_address"];
      }
    }
  } catch (e) {
    // console.warn(e);
  }
  console.log("[getAddressInfo]", result);
  return result;
};

/**
 * get public ip address
 */
export const getPublicIP = async () => {
  return await publicIp.v4();
};

/**
 * get current time
 * e.g. Tursday, May 27 at 6:45PM
 * @returns {String}
 */
export const getCurrentTime = () => {
  const now = new Date();
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  let date = now.toLocaleDateString("en-US", options).slice(0);
  date = date.slice(0, date.length - 6);
  let time = now.toLocaleTimeString("en-US");
  const reg = /([\d]+:[\d]+)(:[\d]+)\s([\w]+)/;
  time = time.replace(reg, `$1$3`);
  return `${date} at ${time}`;
};

/**
 * go to bizboost with some parameters
 * @param {Object} values
 */
export const gotoBizboost = (values) => {
  const revenue = values.avarage
    ? parseInt(values.avarage.replace(/\D/g, ""))
    : 0;
  if (revenue > 0 && revenue < 60000) {
    // if (!!values.startup || (revenue > 0 && revenue < 60000)) {
    const path = "CAP100";
    // const path = "campaign";
    const bname = values.businessname;
    const fname = values.firstname;
    const lname = values.lastname;
    const email = values.email;
    const phone = values.phone;
    const monthInt = Months.findIndex((m) => m === values.startmonth);
    const startDate = new Date(parseInt(values.startyear), monthInt, 2);
    const start_date = startDate.getTime() / 1000;
    const url = `${BIZBOOST_ENDPOINT}?path=${path}&bname=${bname}&fname=${fname}
                    &lname=${lname}&email=${email}&phone=${phone}`;
    console.log("[gotoBizboost]", url);
    window.location.href = url;
  }
};

/**
 * Authentication verification
 * */
export const userVerify = () => {
  const user_token = localStorage.getItem("user-token");
  return user_token;
};

/**
 * get auth header
 * */
export const authHeader = () => {
  const user_token = localStorage.getItem("user-token");
  return {
    headers: {
      "x-access-token": user_token,
    },
  };
};

export const getGTMId = () => {
  return IS_CAPITALIZED
    ? CAPITALIZED_GTM
    : IS_BIZBOOST
    ? BIZBOOST_GTM
    : IS_NORTH
    ? NORTHEAST_GTM
    : IS_HORIZON
    ? HORIZON_GTM
    : null;
};


export const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
export const phoneRegex = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
export const ssnRegex =
  /^(?!0{3})(?!6{3})[0-8]\d{2}-(?!0{2})\d{2}-(?!0{4})\d{4}$/;
export const nameRegex =
  /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/;
export const addressRegex = /\d{1,5}\s\w.\s(\b\w*\b\s){1,2}\w*\./;
export const cityRegex = /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/;
export const stateRegex =
  /^(([Aa][EeLlKkSsZzRr])|([Cc][AaOoTt])|([Dd][EeCc])|([Ff][MmLl])|([Gg][AaUu])|([Hh][Ii])|([Ii][DdLlNnAa])|([Kk][SsYy])|([Ll][Aa])|([Mm][EeHhDdAaIiNnSsOoTt])|([Nn][EeVvHhJjMmYyCcDd])|([Mm][Pp])|([Oo][HhKkRr])|([Pp][WwAaRr])|([Rr][Ii])|([Ss][CcDd])|([Tt][NnXx])|([Uu][Tt])|([Vv][TtIiAa])|([Ww][AaVvIiYy]))$/;
