// Actions for Form
export const FETCH_APPLICATIONS_SUCCESS = "FETCH_APPLICATIONS_SUCCESS";
export const FETCH_APPLICATIONS_FAILURE = "FETCH_APPLICATIONS_FAILURE";
export const FETCH_SPECIAL_APPLICATIONS_SUCCESS =
  "FETCH_SPECIAL_APPLICATIONS_SUCCESS";
export const FETCH_SPECIAL_APPLICATIONS_FAILURE =
  "FETCH_SPECIAL_APPLICATIONS_FAILURE";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAILURE = "FETCH_USERS_FAILURE";
export const UPDATE_APPLICATION = "UPDATE_APPLICATION";
export const FETCH_LATEST_DOC_SUCCESS = "FETCH_LATEST_DOC_SUCCESS";
export const FETCH_LATEST_DOC_REQUEST = "FETCH_LATEST_DOC_REQUEST";
export const FETCH_LATEST_DOC_FAILURE = "FETCH_LATEST_DOC_FAILURE";
export const CLEAR_LATEST_DOC_STATUS = "CLEAR_LATEST_DOC_STATUS";

export const setApplications = (applications) => ({
  type: FETCH_APPLICATIONS_SUCCESS,
  applications,
});

export const clearApplications = () => ({
  type: FETCH_APPLICATIONS_FAILURE,
});

export const setSpecialApplications = (applications) => ({
  type: FETCH_SPECIAL_APPLICATIONS_SUCCESS,
  applications,
});

export const clearSpecialApplications = () => ({
  type: FETCH_SPECIAL_APPLICATIONS_FAILURE,
});

export const setUsers = (users) => ({
  type: FETCH_USERS_SUCCESS,
  users,
});

export const clearUsers = () => ({
  type: FETCH_USERS_FAILURE,
});

export const updateApplication = (data) => ({
  type: UPDATE_APPLICATION,
  data,
});

export const refreshBankstatementSuccess = () => ({
  type: FETCH_LATEST_DOC_SUCCESS,
});

export const refreshBankstatementRequest = () => ({
  type: FETCH_LATEST_DOC_REQUEST,
});

export const refreshBankstatementFailure = () => ({
  type: FETCH_LATEST_DOC_FAILURE,
});

export const clearRefreshBankstatementStatus = () => ({
  type: CLEAR_LATEST_DOC_STATUS,
});
