import React, { useState, forwardRef, useImperativeHandle } from "react";
import { FormGroup, Col, Row, FormInput } from "shards-react";
import { validCreditScore } from "../utils";
import "./components.css";
import useStyles from "./styles";

const CreditScore = forwardRef(({ value, setValue, btnRow }, ref) => {
  // console.log("[CreditScore]");

  const classes = useStyles();

  const [error, handleError] = useState(false);
  const [success, setSuccess] = useState(false);

  const scores = [
    "Excellent (720 +)",
    "Great (680 - 719)",
    "Average (650 - 679)",
    "Fair (600 - 649)",
    "Not so great (550 - 599)",
    "Poor (549 or less)",
  ];

  const handleChange = (e) => {
    const val = e.target.value;
    if (typeof setValue === "function") setValue(val);
  };

  const validate = () => {
    if (!!value && validCreditScore(value)) {
      setSuccess(true);
      handleError(false);
      return true;
    } else {
      setSuccess(false);
      handleError(true);
      return false;
    }
  };

  useImperativeHandle(ref, () => ({
    validate,
  }));

  return (
    <>
      <FormGroup className="form-group-center">
        <Col className="col-lg-6 offset-lg-3">
          <label
            htmlFor="#credit-score"
            className="custom-lbl-size display-5 text-primary text-left text-shadow--large-1"
          >
            What is your estimated credit score?
          </label>
          <Row>
            <Col>
              <FormInput
                id="#credit-score"
                placeholder="e.g. 250"
                value={value || ""}
                onChange={handleChange}
                valid={success}
                invalid={error}
              />
            </Col>
          </Row>
        </Col>
      </FormGroup>
      {btnRow}
    </>
  );
});

export default CreditScore;
