import {
  SET_PUBLIC_TOKEN,
  SET_LINK_TOKEN,
  FETCH_ASSET_REPORT_ID_REQUEST,
  FETCH_ASSET_REPORT_ID_SUCCESS,
  FETCH_ASSET_REPORT_ID_FAILURE,
  UPLOAD_ASSET_REPORT_REQUEST,
  UPLOAD_ASSET_REPORT_SUCCESS,
  UPLOAD_ASSET_REPORT_FAILURE,
  SET_MANUAL_OPTION,
  FETCH_TRANSACTION_SUCCESS,
  FETCH_TRANSACTION_FAILURE,
  FETCH_TRANSACTION_REQUEST,
} from "../actions/plaid";

const initialState = {
  linkToken: null,
  publicToken: null,
  currentAssetReportId: "",
  uploadingStatus: 0, // 1 - loading, 2- success, 3 - fail
  uploadResult: [],
  isManual: false,
  requestId: "",
};
// Reducers for Plaid
export const plaidReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LINK_TOKEN:
    case SET_PUBLIC_TOKEN:
      return { ...state, ...action.payload };
    case FETCH_ASSET_REPORT_ID_REQUEST:
      return {
        ...state,
        uploadingStatus: 1,
        currentAssetReportId: "",
        uploadResult: [],
        requestId: "",
      };
    case FETCH_ASSET_REPORT_ID_SUCCESS:
      return {
        ...state,
        uploadResult: action.payload.result,
        uploadingStatus: 2,
        isManual: false,
        currentAssetReportId: action.payload.assetReportId,
        requestId: action.payload.result.id,
      };
    case FETCH_ASSET_REPORT_ID_FAILURE:
      return {
        ...state,
        currentAssetReportId: "",
        requestId: "",
        uploadResult:
          "Sorry you are having trouble logging into plaid please try to upload bank statement.",
        uploadingStatus: 3,
        isManual: true,
      };

    case UPLOAD_ASSET_REPORT_REQUEST:
      return action.payload.id === state.currentAssetReportId
        ? { ...state, uploadingStatus: 1 }
        : { ...state };
    case UPLOAD_ASSET_REPORT_SUCCESS:
      return action.payload.id === state.currentAssetReportId
        ? {
            ...state,
            currentAssetReportId: "",
            uploadResult: action.payload.msg,
            uploadingStatus: 2,
            isManual: false,
          }
        : { ...state };
    case UPLOAD_ASSET_REPORT_FAILURE:
      return action.payload.id === state.currentAssetReportId
        ? {
            ...state,
            currentAssetReportId: "",
            uploadResult: action.payload.msg,
            uploadingStatus: 3,
            isManual: true,
          }
        : { ...state };
    case FETCH_TRANSACTION_REQUEST:
      return { ...state };
    case FETCH_TRANSACTION_SUCCESS:
      console.log(action.payload.id, " == ", state.requestId);
      console.log("******** fetched result: ", action.payload.result);
      return action.payload.id === state.requestId
        ? {
            ...state,
            requestId: "",
            uploadingStatus: 2,
            uploadResult: action.payload.result.data,
            isManual: false,
          }
        : { ...state };
    case FETCH_TRANSACTION_FAILURE:
      return action.payload.id === state.requestId
        ? {
            ...state,
            requestId: "",
            uploadResult: "Failed to fetch transaction data",
            uploadingStatus: 3,
            isManual: true,
          }
        : { ...state };
    case SET_MANUAL_OPTION:
      return { ...state, isManual: true };
    default:
      return { ...state };
  }
};
