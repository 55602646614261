import React from "react";
import "./components.css";
import { category, dark_logo, IS_CAPITALIZED } from "../config";
import Dark_logo from "../images/logo/logo-transparent-dark.png";

const Thankyou = ({ values }) => {
  return (
    <>
      <div className="d-flex justify-content-center">
        <img
          className="navbar-brand__image"
          // src="/logo/Bizboost Logo - dark.png"
          src={dark_logo}
          alt={category}
          style={{
            width: "250px",
            height: "100%",
            padding: "20px 20px 50px",
            marginRight: IS_CAPITALIZED ? 40 : 0,
          }}
        />
      </div>
      <h1 className="display-4 text-primary text-shadow--large-1 font-averta font-weight-700">
        Thank you
      </h1>
      <h5 className="mt-5">
        Thanks for your interest. We’ll send you more information on the topics
        you selected.
      </h5>
    </>
  );
};

export default Thankyou;
