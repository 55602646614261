import { SET_FORM_VALUE } from "../actions/form";

// Reducers for Form
export const formReducer = (state = {}, action) => {
  if (action.type === SET_FORM_VALUE) {
    return { ...state, ...action.payload };
  }

  return state;
};
