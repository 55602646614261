import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  FormInput,
  Alert,
  FormTextarea,
  Badge,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "shards-react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  fetchApplications,
  requestBankstatement,
  getRequestLink,
  refreshBankstatement,
} from "../../api/admin";
import {
  clearApplications,
  setApplications,
  updateApplication,
  refreshBankstatementRequest,
  refreshBankstatementSuccess,
  refreshBankstatementFailure,
  clearRefreshBankstatementStatus,
} from "../../actions/admin";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { Search, RefreshOutlined } from "@material-ui/icons";

import Popup from "reactjs-popup";
// import { getGTMId } from "../../utils";
// import TagManager from "react-gtm-module";

// getGTMId() && TagManager.initialize({ gtmId: getGTMId() });

const AdminDashboard = (props) => {
  const {
    setApplications,
    clearApplications,
    applications,
    refreshDocStatus,
    updateApplication,
    refreshBankstatementRequest,
    refreshBankstatementSuccess,
    refreshBankstatementFailure,
    clearRefreshBankstatementStatus,
  } = props;

  const [apps, setApps] = useState([]);
  const [filteredApp, setFilteredApp] = useState([]);

  const history = useHistory();
  const [requestModal, setRequestModal] = useState(false);
  const [linkModal, setLinkModal] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [requestMonths, setRequestMonths] = useState(3);
  const [mailText, setMailText] = useState("");

  const [searchText, setSearchText] = useState("");

  const [valid, setValid] = useState({});
  const [resAlert, setResAlert] = useState({ visible: false });

  const [disabledBtn, setDisabledBtn] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [requestLink, setRequestLink] = useState("");

  const [openPopup, setOpenPopup] = useState(false);
  const itemPerPage = 10;

  const requestStatus = ["sent request", "accepted", "finished"];

  useEffect(() => {
    const filteredData = applications
      .filter((app) => app.current_page === "third" && app.email)
      .sort((a, b) => (a.created_at > b.created_at ? -1 : 1));
    setApps([...filteredData]);
    setFilteredApp([...filteredData]);
  }, [applications]);

  useEffect(() => {
    console.log(refreshDocStatus, "==== refresh doc status");
    if (refreshDocStatus === "success" || refreshDocStatus === "failure") {
      setOpenPopup(true);
      // clearRefreshBankstatementStatus()
    }
  }, [refreshDocStatus]);

  useEffect(() => {
    fetchApplications(process.env.REACT_APP_DOMAIN, (err, data) => {
      if (err) {
        clearApplications();
        if (err.status === 401) {
          history.push("/admin/login");
        }
      }
      if (data) {
        setApplications(data);
      }
    });
    // if(getGTMId()) {
    //   console.log("**** Track Admin Page ******\n", `${document.location.origin}${document.location.pathname}${document.location.hash}${document.location.search}`)
    //   window.dataLayer.push({
    //     event: 'pageview',
    //     page: {
    //       url: `${document.location.origin}${document.location.pathname}${document.location.hash}${document.location.search}`,
    //       title: `${document.title} - Admin Page`
    //     }
    //   })
    // }
  }, []);

  useEffect(() => {
    let filteredData = [...apps];
    if (searchText && searchText.length) {
      filteredData = [
        ...filteredData.filter(
          (item) =>
            `${item.firstname} ${item.lastname}`
              .toLowerCase()
              .includes(searchText.toLowerCase()) ||
            item.email.toLowerCase().includes(searchText.toLowerCase()),
        ),
      ];
    }
    setFilteredApp(filteredData);
  }, [searchText, apps]);

  const validCheck = (mode = true) => {
    let valid = true;
    if (requestMonths > 12 || requestMonths < 1) {
      setValid((valid) => ({
        ...valid,
        requestMonths: {
          status: false,
          msg: "Invalid request months. Must be between 1 and 12",
          visible: true,
        },
      }));
      valid = false;
    } else {
      setValid((valid) => ({ ...valid, email: { status: true } }));
    }
    if (mode) {
      if (mailText.length === 0) {
        setValid((valid) => ({
          ...valid,
          mailText: {
            status: false,
            msg: "Please fill Email text",
            visible: true,
          },
        }));
        valid = false;
      } else {
        setValid((valid) => ({ ...valid, mailText: { status: true } }));
      }
    }

    return valid;
  };

  const handleRequest = () => {
    if (validCheck()) {
      const data = {
        request_months: requestMonths,
        app_id: selectedApplication.id,
        text: mailText,
      };
      setDisabledBtn(true);
      requestBankstatement(data, (err, data) => {
        setDisabledBtn(false);
        if (err) {
          setResAlert({ msg: err.data, visible: true, status: false });
        }
        if (data) {
          setResAlert({
            msg: "Successfully sent request",
            visible: true,
            status: true,
          });
          updateApplication({
            id: selectedApplication.id,
            request_months: requestMonths,
          });
          setRequestModal((requestModal) => !requestModal);
        }
      });
    }
  };

  const handleLatestDoc = (app_id) => {
    refreshBankstatementRequest();
    refreshBankstatement(app_id, (err, data) => {
      if (err) {
        refreshBankstatementFailure();
      }
      if (data) {
        refreshBankstatementSuccess();
      }
    });
  };

  const handleGetLink = () => {
    if (validCheck(false)) {
      const data = {
        request_months: requestMonths,
        app_id: selectedApplication.id,
      };
      getRequestLink(data, (err, data) => {
        if (err) {
          setResAlert({ msg: err.data, visible: true, status: false });
        }
        if (data) {
          updateApplication({
            id: selectedApplication.id,
            request_months: requestMonths,
          });
          setRequestLink(data.url);
          setLinkModal((linkModal) => !linkModal);
        }
      });
    }
  };

  const handleRefresh = () => {
    fetchApplications(process.env.REACT_APP_DOMAIN, (err, data) => {
      if (err) {
        clearApplications();
        if (err.status === 401) {
          history.push("/admin/login");
        }
      }
      if (data) {
        setApplications(data);
      }
    });
  };

  const handlePageChange = (e) => {
    setCurrentPage(e.selected + 1);
  };

  return (
    <Col>
      <Card>
        <CardHeader>
          <h5 className="text-dark mb-n1">Request Bank Statement</h5>
        </CardHeader>
        <CardBody className="pt-1">
          <div className="ml-auto d-flex justify-content-end my-3">
            <Button
              size="sm"
              theme="success"
              outline
              className="mr-3"
              onClick={handleRefresh}
            >
              <RefreshOutlined />
            </Button>
            <InputGroup size="md" className="w-25">
              <InputGroupAddon type="prepend">
                <InputGroupText>
                  <Search />
                </InputGroupText>
              </InputGroupAddon>
              <FormInput
                placeholder="Search..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </InputGroup>
          </div>
          <table className="table table-striped app-table">
            <thead>
              <tr>
                <th>Username</th>
                <th>Email</th>
                <th>Created At</th>
                <th>Signature / Bank statement</th>
                <th>LendSaas</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredApp.length ? (
                filteredApp
                  .filter(
                    (item, idx) =>
                      idx >= itemPerPage * (currentPage - 1) &&
                      idx < itemPerPage * currentPage,
                  )
                  .map((application, idx) => (
                    <tr key={idx}>
                      <td width="10%">
                        <div>{`${application.firstname} ${application.lastname}`}</div>
                        {!!application.partner && (
                          <div style={{ textTransform: "capitalize" }}>
                            <Badge theme="success">
                              On {application.domain.substr(0, 1).toUpperCase()}
                              {application.domain.substr(1)}
                            </Badge>
                          </div>
                        )}
                      </td>
                      <td width="15%">
                        <div
                          style={{ wordBreak: "break-word" }}
                        >{`${application.email}`}</div>
                      </td>
                      <td width="12%">{`${moment(application.created_at).format(
                        "MM/DD/YY",
                      )}`}</td>
                      <td width="25%">
                        {application.signature && (
                          <div>
                            <Badge theme="warning">
                              <a
                                href={application.signature}
                                target="_blank"
                                title="signature document"
                              >
                                Signature Doc
                              </a>
                            </Badge>
                          </div>
                        )}
                        {application.bankstatement.map((doc) => (
                          <div>
                            <a
                              href={doc.sharedUrl}
                              target="_blank"
                              title={doc.name}
                            >
                              {doc.name}
                            </a>
                          </div>
                        ))}
                      </td>
                      <td width="12%">
                        {application.lendsaas ? (
                          application.lendsaas.length && (
                            <Badge theme="success">
                              ID: {application.lendsaas}
                            </Badge>
                          )
                        ) : (
                          <Badge theme="danger">No Deal</Badge>
                        )}
                      </td>
                      <td width="10%">
                        {application.request_bank &&
                        application.request_bank.status ? (
                          <Badge
                            theme={
                              application.request_bank.status == 3
                                ? "success"
                                : "info"
                            }
                          >
                            {
                              requestStatus[
                                +application.request_bank.status - 1
                              ]
                            }
                          </Badge>
                        ) : (
                          <Badge theme={"warning"}>no request</Badge>
                        )}
                        <div>
                          <Badge pill>
                            {application.request_bank.months} months
                          </Badge>
                        </div>
                      </td>
                      <td width="16%">
                        {application.isRefresh && (
                          <Button
                            theme="danger"
                            onClick={() => {
                              handleLatestDoc(application.id);
                            }}
                            outline
                            size="sm"
                            className="mb-2"
                            disabled={refreshDocStatus === "request"}
                          >
                            {refreshDocStatus === "request" ? (
                              "Requested"
                            ) : (
                              <RefreshOutlined />
                            )}
                          </Button>
                        )}
                        <Button
                          theme="success"
                          onClick={() => {
                            setRequestModal((requestModal) => !requestModal);
                            setSelectedApplication(application);
                          }}
                          size="sm"
                        >
                          Request
                        </Button>
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colspan={7}>
                    <div className="d-flex justify-content-center py-5">
                      {apps.length === 0
                        ? "Loading applications..."
                        : "No application"}
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="d-flex justify-content-between mt-5">
            <div>
              {`Showing ${
                itemPerPage * (currentPage - 1) + 1 > filteredApp.length
                  ? filteredApp.length
                  : itemPerPage * (currentPage - 1) + 1
              } - ${
                itemPerPage * currentPage > filteredApp.length
                  ? filteredApp.length
                  : itemPerPage * currentPage
              } from ${filteredApp.length} Results`}
            </div>
            <ReactPaginate
              pageCount={(filteredApp?.length ?? 0) / itemPerPage}
              renderOnZeroPageCount={null}
              containerClassName="pagination"
              previousLabel="< Prev"
              nextLabel="Next >"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageChange}
              activeClassName="active"
              style={{ fontFamily: "Roboto" }}
            />
          </div>
        </CardBody>
      </Card>
      <Popup
        open={openPopup}
        closeOnDocumentClick
        onClose={() => {
          setOpenPopup(false);
          clearRefreshBankstatementStatus();
        }}
        position="right center"
      >
        <div className="mx-3 my-4">
          <a
            className="close"
            onClick={() => {
              setOpenPopup(false);
              clearRefreshBankstatementStatus();
            }}
          >
            &times;
          </a>
          <div className="text-center">
            {refreshDocStatus === "success"
              ? "Successfully requested to get latest bank statement document. It may take time to fetch."
              : "Failed to request to get latest bank statement document. Please retry."}
          </div>
        </div>
      </Popup>
      <Modal
        open={requestModal}
        toggle={() => setRequestModal((requestModal) => !requestModal)}
      >
        <ModalHeader>Request Bankstatement</ModalHeader>
        <ModalBody>
          {resAlert && resAlert.visible && (
            <Alert
              className="mb-3"
              dismissible={() =>
                setResAlert((resAlert) => ({
                  ...resAlert,
                  visible: false,
                }))
              }
              theme={resAlert.status ? "success" : "danger"}
            >
              {resAlert.msg}
            </Alert>
          )}
          {Object.keys(valid)
            .filter((key) => !valid[key].status && valid[key].visible)
            .filter((key, idx) => idx === 0)
            .map((key) => (
              <Alert
                className="mb-3"
                dismissible={() =>
                  setValid((valid) => ({
                    ...valid,
                    [key]: { ...valid[key], visible: false },
                  }))
                }
                theme="warning"
              >
                {valid[key].msg}
              </Alert>
            ))}
          <Form>
            <FormGroup className="mb-3">
              <label htmlFor="request_months">Request months</label>
              <FormInput
                id="request_months"
                placeholder="request months"
                type="number"
                value={requestMonths}
                onChange={(e) => setRequestMonths(e.target.value)}
                valid={valid.requestMonths && valid.requestMonths.status}
                invalid={valid.requestMonths && !valid.requestMonths.status}
              />
            </FormGroup>
            <FormGroup className="mb-4">
              <label htmlFor="mail_content">Email text</label>
              <FormTextarea
                id="mail_content"
                placeholder="Email text"
                value={mailText}
                onChange={(e) => setMailText(e.target.value)}
                valid={valid.mailText && valid.mailText.status}
                invalid={valid.mailText && !valid.mailText.status}
              />
            </FormGroup>
            <div className="d-flex justify-content-around">
              <Button theme="info" onClick={handleGetLink}>
                Get Request Link
              </Button>
              <Button
                theme="success"
                onClick={handleRequest}
                disabled={disabledBtn}
              >
                {disabledBtn ? "Sending..." : "Send Request"}
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
      <Modal
        open={linkModal}
        toggle={() => setLinkModal((linkModal) => !linkModal)}
      >
        <ModalHeader>Request Link</ModalHeader>
        <ModalBody>
          <div className="pb-5" style={{ wordBreak: "break-all" }}>
            {requestLink}
          </div>
          <div className="d-flex justify-content-center">
            <Button theme="success" onClick={() => setLinkModal(false)}>
              Ok
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </Col>
  );
};

function mapStateToProps(state) {
  const { applications, refreshDocStatus } = state.admin;

  return {
    applications,
    refreshDocStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setApplications: (applications) => dispatch(setApplications(applications)),
    clearApplications: () => dispatch(clearApplications()),
    updateApplication: (data) => dispatch(updateApplication(data)),
    refreshBankstatementRequest: () => dispatch(refreshBankstatementRequest()),
    refreshBankstatementSuccess: () => dispatch(refreshBankstatementSuccess()),
    refreshBankstatementFailure: () => dispatch(refreshBankstatementFailure()),
    clearRefreshBankstatementStatus: () =>
      dispatch(clearRefreshBankstatementStatus()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);
