import React, { useState, useEffect } from "react";
import { getState, dispatch } from "../store";
import { setLinkTokenAction } from "../actions/plaid";
import { fetchLinkToken } from "../api";
import PlaidLaunchLink from "./PlaidLaunchLink";

const Plaid = (props) => {
  const [linkToken, setLinkToken] = useState(getState().plaid.linkToken);
  // const _id = getState().form._id ? getState().form._id : '61ddb3ed1b85062cbc5749f6'
  const _id = getState().form._id
    ? getState().form._id
    : "61e648f322266d3177903ed9";

  useEffect(() => {
    if (!getState().plaid.linkToken) {
      fetchLinkToken(_id, (data) => {
        dispatch(setLinkTokenAction(data));
        setLinkToken(data);
      });
    }
  }, []);

  return (
    <div>
      {linkToken ? (
        <PlaidLaunchLink linkToken={linkToken} />
      ) : (
        <div>Loading Plaid Institutions...</div>
      )}
    </div>
  );
};

export default Plaid;
