import React from "react";
import { getState, dispatch } from "../store";
import { Button, FormRadio } from "shards-react";
import { connect } from "react-redux";
import {
  fetchAssetReportIdFailure,
  fetchAssetReportIdRequest,
  fetchAssetReportIdSuccess,
  setManualOption,
} from "../actions/plaid";
import { uploadAssetReport } from "../api";
import { usePlaidLink } from "react-plaid-link";
// import Button from "plaid-threads/Button";

const PlaidLaunchLink = (props) => {
  const { linkToken, formState, plaidState } = props;
  const _id = formState._id ? formState._id : "61e648f322266d3177903ed9";
  // const _id = formState._id ? formState._id : '61ddb3ed1b85062cbc5749f6'

  const onSuccess = (public_token) => {
    dispatch(fetchAssetReportIdRequest());
    uploadAssetReport(
      _id,
      public_token,
      (data) => {
        console.log(data, "===== data");
        dispatch(fetchAssetReportIdSuccess({ result: data }));
      },
      (err) => {
        dispatch(fetchAssetReportIdFailure({}));
      },
    );
  };

  const onExit = () => {
    dispatch(setManualOption());
    console.log("[setting manual option] ===== ");
  };

  const { open, ready } = usePlaidLink({
    token: linkToken,
    onSuccess,
    onExit,
  });

  return (
    <div>
      {plaidState.uploadingStatus === 1 ? (
        <p className="mt-4">Processing secure bank validation ...</p>
      ) : (
        <div className="mt-4">
          {plaidState.uploadingStatus === 2 && (
            <div className="mb-3">
              Successfully uploaded Secure Bank Validation
              {/*{plaidState.uploadResult.map((result) => (*/}
              {/*    <div>*/}
              {/*      <a href={result.sharedUrl} target="_blank">{result.name}</a>*/}
              {/*    </div>*/}
              {/*))}*/}
            </div>
          )}
          {plaidState.uploadingStatus === 3 && (
            <p className="ml-3">{plaidState.uploadResult}</p>
          )}
          <Button
            theme="success"
            onClick={() => open()}
            // className="custom-btn-back"
            disabled={!ready || plaidState.uploadingStatus === 2}
          >
            Secure Bank Validation
          </Button>
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const { form, plaid } = state;

  return {
    formState: form,
    plaidState: plaid,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(PlaidLaunchLink);
