import React from "react";
import "./components.css";
import { dark_logo, category, IS_CAPITALIZED, IS_BIZBOOST } from "../config";
import Dark_logo from "../images/logo/logo-transparent-dark.png";

const Congratulations = ({ values, isSecond = false }) => {
  return (
    <>
      <img
        className="navbar-brand__image"
        src={dark_logo}
        alt={category}
        style={{
          width: "250px",
          height: "100%",
          padding: "20px 20px 50px",
          marginRight: IS_CAPITALIZED ? 40 : 0,
        }}
      />
      <h1 className="display-4 text-success text-shadow--large-1 font-weight-700">
        {isSecond ? "Thank you" : "Confirmed"}
      </h1>
      <p
        className={`text-secondary opacity-7 ${
          isSecond && IS_BIZBOOST ? "" : "mt-3"
        }`}
      >
        {
          // isSecond ? IS_BIZBOOST ? "We have a function option for you" : ""
          isSecond ? "" : "We have funding options for you."
        }
      </p>
      <h5 className="mt-5">
        {
          isSecond
            ? ""
            : "Now provide us with your details and we’ll provide you with an official funding offer."
          // isSecond ? IS_BIZBOOST ? "Now provide us with a few of your details and we’ll reveal the funding source that you match with." : ""
          //     : "Now provide us with your details and we’ll provide you with an official funding offer."
        }
        {/*<br/>*/}
        {/*{*/}
        {/*  // isSecond && !IS_BIZBOOST ? "Currently we do not have any programs that are a match for you."*/}
        {/*  //     : "It only takes a few minutes so click the button below now."*/}
        {/*  "Currently we do not have any programs that are a match for you."*/}
        {/*}*/}
      </h5>
    </>
  );
};

export default Congratulations;
