import React, { useEffect } from "react";
import { Card } from "shards-react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchUsers } from "../../api/admin";
import { setUsers, clearUsers } from "../../actions/admin";

const UserDashboard = (props) => {
  const { setUsers, clearUsers, users } = props;

  const history = useHistory();

  useEffect(() => {
    fetchUsers((err, data) => {
      if (err) {
        clearUsers();
        if (err.status === 401) {
          history.push("/admin/login");
        }
      }
      if (data) {
        setUsers(data);
      }
    });
  }, []);

  return (
    <Card>
      <p>This is User Dashboard</p>
    </Card>
  );
};

function mapStateToProps(state) {
  const { users } = state.admin;

  return {
    users,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUsers: (users) => dispatch(setUsers(users)),
    clearUsers: () => dispatch(clearUsers()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDashboard);
