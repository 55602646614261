import React from "react";
import { Container, Row } from "shards-react";
import "./shards-pro.css";
import "./layout.css";
import BG_IMAGE from "../images/bg-image.jpg";

const AuthMainLayout = ({ children }) => (
  <>
    <div
      className={`shards-hero shards-hero-s5--5 shards-overlay shards-overlay--black shards-overlay--opacity-7 
            custom-main-layout background-${process.env.REACT_APP_DOMAIN} auth-container`}
    >
      <div className="shards-hero__content custom-main-content">
        <div className="bg-container">
          <img src={BG_IMAGE} alt="background-green" />
        </div>
        <Container>
          <Row className="justify-content-center">
            <div className="content-wrapper col-lg-6 text-center">
              {children}
            </div>
          </Row>
        </Container>
      </div>
    </div>
  </>
);

export default AuthMainLayout;
