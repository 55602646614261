import React, { useState, useRef, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Progress, Col, Button, Card, CardBody, Row } from "shards-react";
// import { useFormik } from 'formik';
// import * as yup from 'yup';
import { ArrowBack } from "@material-ui/icons";
import { getState, dispatch } from "../store";
import { formSetValue } from "../actions/form";
import HowMuch from "../components/HowMuch";
import BusinessStart from "../components/BusinessStart";
import AvarageMonthly from "../components/AvarageMonthly";
import CreditScore from "../components/CreditScore";
import IndustryType from "../components/IndustryType";
import { checkLevel, getGTMId, getPublicIP } from "../utils";
import { updateValues } from "../api";
import { loadInit } from "./handle";
import "./pages.css";
import TagManager from "react-gtm-module";
import Plaid from "../components/Plaid";

const subtitles = [
  "How much money...",
  "Business start day",
  "Average monthly revenue",
  "Credit score",
  "Industry type",
];

export default function FirstPage({ location: full_location }) {
  const history = useHistory();
  const location = useLocation();

  const [step, setStep] = useState(0);
  const [values, setValues] = useState({
    ...getState().form,
    current_page: "first",
  });
  const [clientPartnerId, setClientPartnerId] = useState("");

  const isShowProgress = true;

  const howmuchRef = useRef(null);
  const businessRef = useRef(null);
  const avarageRef = useRef(null);
  const creditRef = useRef(null);
  const typeRef = useRef(null);

  // const formik = useFormik({
  //     initialValues: {
  //         howmuch: '0'
  //     },
  //     validationSchema: validationSchema,
  //     onSubmit: (values) => {
  //        alert(JSON.stringify(values, null, 2));
  //     },
  // });

  useEffect(() => {
    if (location.state) {
      setStep(location.state.step);
    }
  }, [location]);

  const progress = () => {
    return Math.round((step * 100) / 5);
  };

  const handleBack = (e) => {
    e.target.blur();
    if (step > 0) setStep(step - 1);
  };

  const handleContinue = async (e) => {
    e.target.blur();
    let validationSuccess = false;
    if (step === 0)
      validationSuccess = howmuchRef.current && howmuchRef.current.validate();
    else if (step === 1)
      validationSuccess = businessRef.current && businessRef.current.validate();
    else if (step === 2)
      validationSuccess = avarageRef.current && avarageRef.current.validate();
    else if (step === 3)
      validationSuccess = creditRef.current && creditRef.current.validate();
    else if (step === 4)
      validationSuccess = typeRef.current && typeRef.current.validate();

    if (validationSuccess) {
      if (step === 4) {
        const ipaddress = await getPublicIP();
        // check if go to third page or second page
        const nextPage = checkLevel(values) ? "third" : "second";
        // upload to server
        updateValues(
          { ...values, ipaddress, current_page: nextPage, current_step: -1 },
          (id) => {
            dispatch(formSetValue({ ...values, ipaddress, _id: id }));
            history.push("/" + nextPage);
          },
        );
      } else {
        // upload to server
        updateValues({ ...values, current_step: step + 1 }, (id) => {
          setStep(step + 1);
          dispatch(formSetValue({ ...values, _id: id }));
          setValues({ ...values, _id: id });
        });
      }
    }
  };

  const handleChangeValue = (key, val) => {
    setValues({ ...values, [key]: val });
  };

  useEffect(() => {
    const partnerId = loadInit({
      location: full_location,
      history,
      setStep,
      page: "first",
      setValues,
      values,
    });
    if (partnerId) {
      setClientPartnerId(partnerId);
    }
    if (!values.type) {
      setStep(0);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  return (
    <Col>
      {isShowProgress ? (
        <Progress
          theme="primary"
          value={progress()}
          className={`mb-5 custom-progress ${step === 0 ? "opercity-0" : ""}`}
        >
          {progress()}%
        </Progress>
      ) : null}
      <Card>
        {/*<Plaid />*/}
        <CardBody>
          {step === 0 && (
            <HowMuch
              ref={howmuchRef}
              value={values}
              setValue={handleChangeValue}
              partnerId={clientPartnerId}
              btnRow={
                <BtnRow
                  step={step}
                  handleBack={handleBack}
                  handleContinue={handleContinue}
                />
              }
            />
          )}
          {step === 1 && (
            <BusinessStart
              ref={businessRef}
              valueMonth={values.startmonth}
              setValueMonth={(v) => handleChangeValue("startmonth", v)}
              valueYear={values.startyear}
              setValueYear={(v) => handleChangeValue("startyear", v)}
              // valueDay={values.startday}
              // setValueDay={(v) => handleChangeValue('startday', v)}
              valueStartUp={values.startup}
              setValueStartUp={(v) => handleChangeValue("startup", v)}
              btnRow={
                <BtnRow
                  step={step}
                  handleBack={handleBack}
                  handleContinue={handleContinue}
                />
              }
            />
          )}
          {step === 2 && (
            <AvarageMonthly
              ref={avarageRef}
              value={values.avarage}
              setValue={(v) => handleChangeValue("avarage", v)}
              btnRow={
                <BtnRow
                  step={step}
                  handleBack={handleBack}
                  handleContinue={handleContinue}
                />
              }
            />
          )}
          {step === 3 && (
            <CreditScore
              ref={creditRef}
              value={values.credit}
              setValue={(v) => handleChangeValue("credit", v)}
              btnRow={
                <BtnRow
                  step={step}
                  handleBack={handleBack}
                  handleContinue={handleContinue}
                />
              }
            />
          )}
          {step >= 4 && (
            <IndustryType
              ref={typeRef}
              value={values.type}
              setValue={(v) => handleChangeValue("type", v)}
              valueFirst={values.firstname}
              setValueFirst={(v) => handleChangeValue("firstname", v)}
              valueLast={values.lastname}
              setValueLast={(v) => handleChangeValue("lastname", v)}
              valueEmail={values.email}
              setValueEmail={(v) => handleChangeValue("email", v)}
              btnRow={
                <BtnRow
                  step={step}
                  handleBack={handleBack}
                  handleContinue={handleContinue}
                />
              }
            />
          )}
        </CardBody>
      </Card>
    </Col>
  );
}

function BtnRow({ step, handleBack, handleContinue, enableNext = true }) {
  return (
    <Row className="mt-4">
      <Col
        sm={{ size: step === 0 ? 7 : 6 }}
        md={{ size: step === 0 ? 6 : 4 }}
        xs={{ size: step === 0 ? 11 : 8 }}
        className="mx-auto mb-3"
      >
        <Button
          theme="success"
          onClick={handleContinue}
          className={`w-100 `}
          disabled={!enableNext}
        >
          {step === 0 ? "Start Here" : "Continue"}
        </Button>
      </Col>
      {/*<Col sm={12} md={4} xs={12}/>*/}
      {step > 0 && (
        <Col
          sm={{ size: 4 }}
          md={{ size: 4 }}
          xs={{ size: 8 }}
          className="back-button-wrapper mb-3"
        >
          <Button
            outline
            theme="success"
            onClick={handleBack}
            className="custom-btn-back"
          >
            <ArrowBack />
            Back
          </Button>
        </Col>
      )}
    </Row>
  );
}
