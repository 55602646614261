import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Col,
  Card,
  CardBody,
  Button,
  FormGroup,
  Form,
  FormInput,
  Row,
  Alert,
} from "shards-react";
import { dark_logo, IS_CAPITALIZED } from "../../config";
import Dark_logo from "../../images/logo/logo-transparent-dark.png";
import "./auth.css";

import { userLogin } from "../../api/auth";

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");

  const [valid, setValid] = useState({});
  const [resAlert, setResAlert] = useState({ visible: false });

  const history = useHistory();

  useEffect(() => {
    localStorage.removeItem("user-token");
  }, []);

  const validCheck = () => {
    const re =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    let valid = true;
    if (!re.test(email) || email.length === 0) {
      setValid((valid) => ({
        ...valid,
        email: { status: false, msg: "Invalid Email format", visible: true },
      }));
      valid = false;
    } else {
      setValid((valid) => ({ ...valid, email: { status: true } }));
    }
    if (pwd.length === 0) {
      setValid((valid) => ({
        ...valid,
        pwd: { status: false, msg: "Please fill password", visible: true },
      }));
      valid = false;
    } else {
      setValid((valid) => ({ ...valid, pwd: { status: true } }));
    }

    return valid;
  };

  const handleSubmit = () => {
    if (validCheck()) {
      userLogin({ email, password: pwd }, (err, data) => {
        if (err) {
          setResAlert({ msg: err.data, visible: true, status: false });
        }
        if (data) {
          setResAlert({
            msg: "Successfully logged in.",
            visible: true,
            status: true,
          });
          window.localStorage.setItem("user-token", data.token);
          history.push("/admin");
        }
      });
    }
  };

  return (
    <Col>
      <Card>
        <div className="d-flex justify-content-center pt-5">
          <img
            className="navbar-brand__image logo-image-wrapper"
            src={Dark_logo}
            alt="logo image"
            style={{ height: "100%", marginRight: IS_CAPITALIZED && 40 }}
          />
        </div>
        <CardBody className="pt-1">
          <Row className="justify-content-center pb-5">
            <div className="col-lg-10 col-10 text-left">
              <h3 className="text-success text-center mb-5">
                Sign in admin panel
              </h3>
              {resAlert && resAlert.visible && (
                <Alert
                  className="mb-3"
                  dismissible={() =>
                    setResAlert((resAlert) => ({
                      ...resAlert,
                      visible: false,
                    }))
                  }
                  theme={resAlert.status ? "success" : "danger"}
                >
                  {resAlert.msg}
                </Alert>
              )}
              {Object.keys(valid)
                .filter((key) => !valid[key].status && valid[key].visible)
                .filter((key, idx) => idx === 0)
                .map((key) => (
                  <Alert
                    className="mb-3"
                    dismissible={() =>
                      setValid((valid) => ({
                        ...valid,
                        [key]: { ...valid[key], visible: false },
                      }))
                    }
                    theme="warning"
                  >
                    {valid[key].msg}
                  </Alert>
                ))}
              <Form>
                <FormGroup className="mb-4">
                  <label htmlFor="email">Email</label>
                  <FormInput
                    id="email"
                    placeholder="user@example.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    valid={valid.email && valid.email.status}
                    invalid={valid.email && !valid.email.status}
                  />
                </FormGroup>
                <FormGroup className="mb-5">
                  <label htmlFor="password">Password</label>
                  <FormInput
                    id="password"
                    placeholder="password"
                    type="password"
                    value={pwd}
                    onChange={(e) => setPwd(e.target.value)}
                    valid={valid.pwd && valid.pwd.status}
                    invalid={valid.pwd && !valid.pwd.status}
                  />
                </FormGroup>
                <Button block theme="success" onClick={handleSubmit}>
                  Sign In
                </Button>
              </Form>
              {/*<div className="mt-4">*/}
              {/*  <span className="pr-2">Do you want to create new account?</span>*/}
              {/*  <a className="cursor-pointer" onClick={() => history.push('/admin/register')}>Sign Up</a>*/}
              {/*</div>*/}
            </div>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default Login;
