import React, { useState, forwardRef, useImperativeHandle } from "react";
import { FormInput, FormGroup, Col, Row } from "shards-react";
import { Select, FormControl, MenuItem } from "@material-ui/core";
import { validateSSN, validatePercent, getAddressInfo } from "../utils";
import {
  InputSSN,
  InputZipCode,
  InputAutoAdress,
  InputDateComp,
} from "./CustomInputs";
import "./components.css";
import useStyles from "./styles";

const Address = forwardRef(
  (
    {
      valueDOB,
      setValueDOB,
      valueSSN,
      setValueSSN,
      valueHomeAddress,
      setValueHomeAddress,
      valueHomeCity,
      setValueHomeCity,
      valueHomeState,
      setValueHomeState,
      setValueHomeCityState,
      valueHomeZipCode,
      setValueHomeZipCode,
      valueOwnership,
      setValueOwnership,
      valueJobTitle,
      setValueJobTitle,
    },
    ref,
  ) => {
    const classes = useStyles();

    const [successDOB, setSuccessDOB] = useState(false);
    const [errorDOB, handleErrorDOB] = useState(false);
    const [successSSN, setSuccessSSN] = useState(false);
    const [errorSSN, handleErrorSSN] = useState(false);
    const [successHomeAddress, setSuccessHomeAddress] = useState(false);
    const [errorHomeAddress, handleErrorHomeAddress] = useState(false);
    const [successHomeCity, setSuccessHomeCity] = useState(false);
    const [errorHomeCity, handleErrorHomeCity] = useState(false);
    const [successHomeState, setSuccessHomeState] = useState(false);
    const [errorHomeState, handleErrorHomeState] = useState(false);
    const [successHomeZipCode, setSuccessHomeZipCode] = useState(false);
    const [errorHomeZipCode, handleErrorHomeZipCode] = useState(false);
    const [successOwnership, setSuccessOwnership] = useState(false);
    const [errorOwnership, handleErrorOwnership] = useState(false);
    const [, setSuccessJobTitle] = useState(false);
    const [errorJobTitle, handleErrorJobTitle] = useState(false);
    const [addresses, setAddr] = useState({
      city: valueHomeCity,
      state: valueHomeState,
      address: valueHomeAddress,
      postcode: valueHomeZipCode,
    });

    const jobTitles = ["Owner", "CEO", "President", "Member", "Other"];

    const handleChangeDOB = (e) => {
      const val = e.target.value;
      if (typeof setValueDOB === "function") setValueDOB(val);
    };

    const handleChangeSSN = (e) => {
      const val = e.target.value;
      // console.log("[Address] handleChangeSSN", val, valueDOB);
      if (typeof setValueSSN === "function") setValueSSN(val);
    };

    const handleGetCityState = (place) => {
      const {
        state,
        locality: city,
        address1: address,
        postcode,
      } = getAddressInfo(place);
      const modifiedPostCode = postcode.split("-")[0];
      console.log(
        "[Home Address] handleGetCityState",
        state,
        city,
        address,
        modifiedPostCode,
      );
      setAddr({ city, state, address, postcode: modifiedPostCode });
      if (typeof setValueHomeAddress === "function")
        setValueHomeAddress(address);
      if (typeof setValueHomeCity === "function") setValueHomeCity(city);
      if (typeof setValueHomeState === "function") setValueHomeState(state);
      if (typeof setValueHomeZipCode === "function")
        setValueHomeZipCode(modifiedPostCode);
    };

    const handleChangeHomeAddress = (e) => {
      const val = e.target.value;
      setAddr({ ...addresses, address: val });
      if (typeof setValueHomeAddress === "function") setValueHomeAddress(val);
    };

    const handleChangeHomeCity = (e) => {
      const val = e.target.value;
      setAddr({ ...addresses, city: val });
      if (typeof setValueHomeCity === "function") setValueHomeCity(val);
    };

    const handleChangeHomeState = (e) => {
      const val = e.target.value;
      setAddr({ ...addresses, state: val });
      if (typeof setValueHomeState === "function") setValueHomeState(val);
    };

    const handleChangeHomeZipCode = (e) => {
      const val = e.target.value;
      setAddr({ ...addresses, postcode: val });
      if (typeof setValueHomeZipCode === "function") setValueHomeZipCode(val);
    };

    const handleChangeOwnership = (e) => {
      const val = e.target.value;
      if (typeof setValueOwnership === "function") setValueOwnership(val);
    };

    const handleChangeJobTitle = (e) => {
      const val = e.target.value;
      if (typeof setValueJobTitle === "function") setValueJobTitle(val);
    };

    const validate = () => {
      const { city, state, address, postcode } = addresses;
      // if (typeof setValueHomeCityState === 'function') {
      //     setValueHomeCityState(city, state, address, postcode);
      // }

      if (!!valueDOB && valueDOB !== "") {
        setSuccessDOB(true);
        handleErrorDOB(false);
      } else {
        setSuccessDOB(false);
        handleErrorDOB(true);
        return false;
      }
      if (validateSSN(valueSSN)) {
        setSuccessSSN(true);
        handleErrorSSN(false);
      } else {
        setSuccessSSN(false);
        handleErrorSSN(true);
        return false;
      }
      // if (!!valueHomeAddress && valueHomeAddress !== '') {
      if (!!address && address !== "") {
        setSuccessHomeAddress(true);
        handleErrorHomeAddress(false);
      } else {
        setSuccessHomeAddress(false);
        handleErrorHomeAddress(true);
        return false;
      }
      // if (!!valueHomeCity && valueHomeCity !== '') {
      if (!!city && city !== "") {
        setSuccessHomeCity(true);
        handleErrorHomeCity(false);
      } else {
        setSuccessHomeCity(false);
        handleErrorHomeCity(true);
        return false;
      }
      // if (!!valueHomeState && valueHomeState !== '') {
      if (!!state && state !== "") {
        setSuccessHomeState(true);
        handleErrorHomeState(false);
      } else {
        setSuccessHomeState(false);
        handleErrorHomeState(true);
        return false;
      }
      // if (!!valueHomeZipCode && valueHomeZipCode !== '') {
      if (!!postcode && postcode !== "") {
        setSuccessHomeZipCode(true);
        handleErrorHomeZipCode(false);
      } else {
        setSuccessHomeZipCode(false);
        handleErrorHomeZipCode(true);
        return false;
      }
      if (validatePercent(valueOwnership)) {
        setSuccessOwnership(true);
        handleErrorOwnership(false);
      } else {
        setSuccessOwnership(false);
        handleErrorOwnership(true);
        return false;
      }
      if (!!valueJobTitle && jobTitles.find((t) => t === valueJobTitle)) {
        setSuccessJobTitle(true);
        handleErrorJobTitle(false);
      } else {
        setSuccessJobTitle(false);
        handleErrorJobTitle(true);
        return false;
      }
      return true;
    };

    useImperativeHandle(ref, () => ({
      validate,
    }));

    return (
      <>
        <Row>
          <Col sm={6}>
            <FormGroup className="form-group-center">
              <Col>
                <label
                  htmlFor="#dob"
                  className="custom-lbl-size display-5 text-primary text-left text-shadow--large-1"
                >
                  Date of Birth
                </label>
              </Col>
              <Col>
                <InputDateComp
                  id="#dob"
                  value={valueDOB || ""}
                  onChange={handleChangeDOB}
                  placeholder="MM/DD/YYYY"
                  valid={successDOB}
                  invalid={errorDOB}
                />
                {/*<FormInput */}
                {/*    type="date"*/}
                {/*    id="#dob"*/}
                {/*    // placeholder="MM/DD/YYYY"  */}
                {/*    value={valueDOB || ""}*/}
                {/*    onChange={handleChangeDOB}*/}
                {/*    valid={successDOB}*/}
                {/*    invalid={errorDOB}*/}
                {/*/>*/}
              </Col>
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup className="form-group-center">
              <Col>
                <label
                  htmlFor="#ssn"
                  className="custom-lbl-size display-5 text-primary text-left text-shadow--large-1"
                >
                  SSN
                </label>
              </Col>
              <Col>
                <InputSSN
                  id="#ssn"
                  value={valueSSN || ""}
                  placeholder="555-88-7777"
                  onChange={handleChangeSSN}
                  valid={successSSN}
                  invalid={errorSSN}
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <FormGroup className="form-group-center">
              <Col>
                <label
                  htmlFor="#home-address"
                  className="custom-lbl-size display-5 text-primary text-left text-shadow--large-1"
                >
                  Home Address
                </label>
              </Col>
              <Col>
                <InputAutoAdress
                  id="#home-address"
                  value={addresses.address || ""}
                  onChange={handleChangeHomeAddress}
                  valid={successHomeAddress}
                  invalid={errorHomeAddress}
                  onPlaceSelected={handleGetCityState}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup className="form-group-center">
              <Col>
                <label
                  htmlFor="#home-city"
                  className="custom-lbl-size display-5 text-primary text-left text-shadow--large-1"
                >
                  City
                </label>
              </Col>
              <Col>
                <FormInput
                  id="#home-city"
                  placeholder=""
                  value={addresses.city || ""}
                  onChange={handleChangeHomeCity}
                  valid={successHomeCity}
                  invalid={errorHomeCity}
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <FormGroup className="form-group-center">
              <Col>
                <label
                  htmlFor="#home-state"
                  className="custom-lbl-size display-5 text-primary text-left text-shadow--large-1"
                >
                  State
                </label>
              </Col>
              <Col>
                <FormInput
                  id="#home-state"
                  placeholder=""
                  value={addresses.state || ""}
                  onChange={handleChangeHomeState}
                  valid={successHomeState}
                  invalid={errorHomeState}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup className="form-group-center">
              <Col>
                <label
                  htmlFor="#home-zip-code"
                  className="custom-lbl-size display-5 text-primary text-left text-shadow--large-1"
                >
                  Zip Code
                </label>
              </Col>
              <Col>
                <InputZipCode
                  id="home-zip-code"
                  value={addresses.postcode || ""}
                  onChange={handleChangeHomeZipCode}
                  placeholder="10001"
                  valid={successHomeZipCode}
                  invalid={errorHomeZipCode}
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <FormGroup className="form-group-center">
              <Col>
                <label
                  htmlFor="#ownership"
                  className="custom-lbl-size display-5 text-primary text-left text-shadow--large-1"
                >
                  Ownership %
                </label>
              </Col>
              <Col>
                <FormInput
                  // type="number"
                  id="ownership"
                  value={valueOwnership || ""}
                  onChange={handleChangeOwnership}
                  placeholder="100 %"
                  valid={successOwnership}
                  invalid={errorOwnership}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup className="form-group-center">
              <Col>
                <label
                  htmlFor="#job-title"
                  className="custom-lbl-size display-5 text-primary text-left text-shadow--large-1"
                >
                  Job Title
                </label>
              </Col>
              <Col>
                <FormControl variant="outlined" className={classes.formControl}>
                  <Select
                    displayEmpty
                    className={classes.customFullWidth}
                    value={valueJobTitle || ""}
                    onChange={handleChangeJobTitle}
                    error={errorJobTitle}
                  >
                    <MenuItem value="" disabled>
                      Select One
                    </MenuItem>
                    {jobTitles.map((t, i) => (
                      <MenuItem key={i} value={t}>
                        {t}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>
            </FormGroup>
          </Col>
        </Row>
      </>
    );
  },
);

export default Address;
